import React from 'react';
import './direct.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';
import { useHistory, useParams, Link } from "react-router-dom";

export default function Testintro() {



    return (
        <>

            <div className='allbody'>
                <div className='t-intro'>

                    <Container>

                        <div className="intro_header">
                            <h2> Direct Course </h2>
                        
                        </div>


                        <div>

                            <div className="intro_content ck_default_style">
                                <div className='testline dede'>
                                    <p>Course Intro</p>
                                </div>

                                <p style={{ marginTop: "12px" }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>


                            <div className="intro_content ck_default_style">
                                <div className='testline dede'>
                                    <p>Coaching Center Details</p>
                                </div>

                                <p style={{ marginTop: "12px" }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <ul>
                                    <li>Address : 23G, Sathyabama guru, Railway staion near, Chennai-28</li>
                                    <li>Phone Numer : 9952318578</li>
                                </ul>

                            </div>

                        </div>

                        <Link to='/schedule'><Button className='viewdetailzz' variant="primary">View Schedule</Button>{' '}</Link>


                    </Container>


                </div>

            </div>
        </>

    );
}
import React, { useState, useEffect } from "react";
import config from "../../lib/config";
import './testattempt.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';
import { testset } from "../../actions/users";
import { questioncount } from "../../actions/users";
import { useNavigate, useParams,Link } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';





export default function Testattempt() {

    const [testsets, settestset] = useState([]);
    const [questioncounts, setquestioncounts] = useState([]);
    const [testcontent, settestcontent] = useState("");
    const { id } = useParams();
    const history = useNavigate();
    

    const getUserListdata = async () => {
        var test = await testset(id);
        console.log(test,"sf")
        if(test.testset.length){
        
        settestset(test.testset);
        }
        else{
            settestcontent("Test is not available for this course")
        }

        var test1 = await questioncount();
        console.log(test1,"ghhgj")
        
        setquestioncounts(test1.questioncount);
        
    }

   

    useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);



    return (
        <>
            <div className='allbody'>
            <div className='testattempts'>
                <Container>

                        <div style={{ marginBottom: "10px" }}>
                            <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                        </div>
                    
                    <div>
                        
                        <Row>
                            {testcontent}
                        {testsets.map(testsets =>
                            <Col lg={3} md={6}>
                            <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..."/>
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">{testsets.setname} </h5>
                                        {/* <p className="card-text c-text">(200 Questions | 800 Marks | 180 Minuts)</p> */}
                                        <p className="card-text c-text">({testsets.qus} Questions | {testsets.qus*testsets.mark} Marks | {testsets.time} hour)</p>
                                        {/* <div className="text-center bbbt">
                                          {testsets.checktest.length ?  <a href={"/testresult/"+testsets._id}><Button variant="primary" className='btattempt' >View Result</Button>{' '}</a> :   <a href={"/teststart/"+testsets._id}><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>}
                                        </div> */}
                                        <div className="text-center bbbt">
                                               <Link to={"/teststart/"+testsets._id}><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>)}

                    
                            {/* <Col lg={3} md={6}>
                                <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..." />
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">NEET 2020 </h5>
                                        <p className="card-text c-text">(170 Questions | 800 Marks | 180 Minuts)</p>
                                        <div className="text-center bbbt">
                                            <a href="/teststart"><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={3} md={6}>
                                <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..." />
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">NEET 2021 </h5>
                                        <p className="card-text c-text">(190 Questions | 800 Marks | 160 Minuts)</p>
                                        <div className="text-center bbbt">
                                            <a href="/teststart"><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3} md={6}>
                                <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..." />
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">NEET 2019 </h5>
                                        <p className="card-text c-text">(150 Questions | 600 Marks | 140 Minuts)</p>
                                        <div className="text-center bbbt">
                                            <a href="/teststart"><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3} md={6}>
                                <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..." />
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">NEET 2019 </h5>
                                        <p className="card-text c-text">(150 Questions | 600 Marks | 140 Minuts)</p>
                                        <div className="text-center bbbt">
                                            <a href="/teststart"><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={3} md={6}>
                                <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..." />
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">NEET 2019 </h5>
                                        <p className="card-text c-text">(150 Questions | 600 Marks | 140 Minuts)</p>
                                        <div className="text-center bbbt">
                                            <a href="/teststart"><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={3} md={6}>
                                <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..." />
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">NEET 2021 </h5>
                                        <p className="card-text c-text">(190 Questions | 800 Marks | 160 Minuts)</p>
                                        <div className="text-center bbbt">
                                            <a href="/teststart"><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={3} md={6}>
                                <div className="card card_courses">
                                    <div className="card_icon_box">
                                        <img src={require('../../img/testing.png')} className="" alt="..." />
                                    </div>
                                    <div className="card-body p-2">
                                        <h5 className="card-title c-neet">NEET 2020 </h5>
                                        <p className="card-text c-text">(170 Questions | 800 Marks | 180 Minuts)</p>
                                        <div className="text-center bbbt">
                                            <a href="/teststart"><Button variant="primary" className='btattempt' >Attempt Now</Button>{' '}</a>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}

                        </Row>

                     </div>




               </Container>

</div>

            </div>
        
        
        </>


    );
}
import React, { useState, useEffect } from "react";
import './solution.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';

import Correct from '../Correct/correct';
import Incorrect from '../Incorrect/incorrect';
import Unattempt from '../Unattempt/unattempt';
import { testtopic,getsolution,previousanswersave,checkattempt,testsetname  } from "./../../actions/users";
export default function Solution({topics=[],id}) {
    const [currenttopic, setcurrenttopic] = useState(0);
    const [questions, setquestions] = useState([]);
    const [cquscount, setcquscount] = useState(0);
    const [wquscount, setwquscount] = useState(0);
    const [uquscount, setuquscount] = useState(0);
    const [attempt, setattempt] = useState(0);
    const [attemptcount, setattemptcount] = useState(0);
    // const [incorrect, setincorrect] = useState([]);
    // const [unattempt, setunattempt] = useState([]);

    const fetchQuestion=async()=>{


        var test1 = await getsolution(id,topics[currenttopic]._id);
        console.log(test1,"vina")

        setquestions(test1.questions);
        setcquscount(0);
        setwquscount(0);
        setuquscount(0);
        // console.log(test1,"kkkkk")
        

       
    }

   

    const previousanswer = async() =>{
       
        
        
       
        
        var test1 = await previousanswersave(id);
        console.log(test1,"bb")
        if(attempt<attemptcount)
       {
        window.location = '/teststart/'+id;
        }
        else{
            setattempt( <span style={{ color: "red", fontSize: "15px" }}>"You have exceeded the test attempt"</span>)
        }
                     
    }
    const nofeattempt = async() => {
        var test2 = await checkattempt(id);
        console.log(test2.userValue.length,"ccc")
        setattempt(test2.userValue.length);

        var test2 = await testsetname(id);
        console.log(test2,"smm")
        setattemptcount(test2.testsetname.noofattempt);


    }
    useEffect(() => {
  
        if(topics.length){
        fetchQuestion();
 
        }
        nofeattempt()
     },[currenttopic,topics]);

    return (
        <>


            <div className='result-solution'>
        
                <Container>
                    
                    <div class="choose-section">
                        <h5 class="result_heading">Choose Section :</h5>
                        <div class="padding-left10 padding-right10 margin-left10">
                            <select class="form-control col-12" onChange={(e)=>setcurrenttopic(e.target.value)}>
                               { topics.map((e,i)=><option class="qua-apti1 padding10 ng-binding ng-scope"  value={i}>{e.content}</option>)}
                            </select>
                        </div>
                        <a class="nav-link " style={{ fontWeight: 700 }} onClick={previousanswer} >Re-Attempt({attempt})</a>
                    </div>


                    <div className='corrections'>

                        <div id="exTab1" >
                            <ul class="nav nav-pills ssss">
                                <li >
                                    <a href="#correct" data-toggle="tab" className='correct'> Correct ({cquscount}) </a>
                                </li>
                                <li ><a href="#incorrect" data-toggle="tab" className='incorrect'> Incorrect({wquscount})  </a>
                                </li>
                                <li><a href="#unattempt" data-toggle="tab" className='unattempt'> Unattempt({uquscount})  </a>
                                </li>

                            </ul>

                            <div class="tab-content clearfix">
                                <div class="tab-pane" id="correct">
                                    <Correct questions={questions} corresctquscount={setcquscount}/>
                                </div>
                                <div class="tab-pane" id="incorrect">
                                    <Incorrect questions={questions} incorresctquscount={setwquscount}/>
                                </div>
                                <div class="tab-pane" id="unattempt">
                                    <Unattempt questions={questions} unaquscount={setuquscount}/>
                                </div>

                            </div>
                        </div>

                    </div>




            </Container>




            </div>






        </>

    );
}
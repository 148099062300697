import React,{useState,useEffect} from 'react';
import './testintro.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate, useParams,Link } from "react-router-dom";
import { verifycourse,getcoursedata  } from '../../actions/users';

export default function Courseintro() {

    const[coursedetail,setcoursedetail]=useState({})
    const { ic_id } = useParams();
    const history = useNavigate();

    const verifyuser =async()=>{

        const verify = await verifycourse(ic_id);
        if("error" in verify){
           alert("Internal server error");
        }else{
   if(!verify.purchased){
    history("/my-profile-courses");
   }
        //    setpurchasedstatus(verify.purchased)
        }
       
      }
      const userData =async()=>{
        const cousrsed = await getcoursedata(ic_id);
        console.log(cousrsed,"sasa")
        if("error" in cousrsed){
           alert("Internal server error");
        }else{
   
           setcoursedetail(cousrsed.userValue)
        }
      }
       
   
       useEffect(()=>{
        userData();
           verifyuser();

       },[])
    return (
        <>
            
            <div className='allbody'>
            <div className='t-intro'>
          
                <Container>

                    <div className="intro_header">
                        <h2>{coursedetail.title} </h2>
                        <nav className="nav_breadcrumb mb-5" aria-label="breadcrumb">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item ">
                                    <Button onClick={()=>history(-1)} className="breadcrumb_back"> <BsFillArrowLeftCircleFill style={{ fontSize:"22px", marginTop:"2px" }}/>  </Button>
                                </li>
                                {/* <li className="breadcrumb-item active" aria-current="page">Intro</li> */}
                            </ul>
                        </nav>
                    </div>


                    <div>

                        <div className="intro_content ck_default_style">
                            <div className='testline'><span ><span ><strong>{coursedetail.title}<br></br></strong></span></span></div>

                            <ul>
                            <div dangerouslySetInnerHTML={{ __html:coursedetail.introduction }} />
                            </ul>
                        </div>

                    </div>


                </Container>


                </div>
                
                </div>
        </>

    );
}
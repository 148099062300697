import React, { useState, useEffect } from "react";
import './incorrect.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';



export default function Incorrect({questions=[],incorresctquscount}) {
    // const [quscount, setquscount] = useState(0);
let quscount=0;
    const showanswer =(element) =>{
        if(element.answers.length){
            if(element.answers[0].evaluation==2){
                // setquscount(quscount+1);
                quscount=quscount+1;
                incorresctquscount(quscount);
       return ( <div className='qanda'>
                    <div className='beans'>
                    <div>
                   <h5><span className='q1s'>Q {element.questionnumber} : </span> <span className='qno'>{element.question}</span></h5>
                        {/* <p className="que_bold"> {element.question}</p> */}
                    </div>
                    <div>
                    <h5></h5>
                   <p className="jerk"><span className='q1s'>Ans :</span> {element.solutions}</p>
                    </div>
                   

                  

                    <div className='right'>
                        <ul>
                            <li>Your Answer : {element.answers[0].answer}</li>
                            <li style={{ display:"flex" }}> <p style={{ color:"red", fontWeight:"500", marginRight:"4px" }}> INCORRECT </p> || Question Type: Single choice </li>
                        </ul>
                        </div>
                        </div>
                </div>)
            }
        }
    }
    let correctqus = ()=>{
        return questions.map(element=>showanswer(element))
    }

    


    return (

        <>


            <div className='allincorrections'>
            {questions.length ? questions.map(element=>showanswer(element)):<h1>No questions in Wrong answer</h1>}
{/* 
                <div className='qanda'>
                    <div className='beans'>
                        <div>
                            <h5>Q.22</h5>
                            <p>A dipole is placed in an electric field as shown. In which direction will it move?</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer A</li>
                                <li style={{ display:"flex" }}> <p style={{ color:"red", fontWeight:"500" }}> INCORRECT</p> || On Screen: 00:03|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='qanda'>
                    <div className='beans'>
                        <div>
                            <h5>Q.15</h5>
                            <p>A body is executing simple harmonic motion with frequency ' n ', the frequency of its potential energy is</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer D</li>
                                <li style={{ display: "flex" }}> <p style={{ color: "red", fontWeight: "500" }}> INCORRECT</p> || On Screen: 00:03|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className='qanda'>

                    <div className='beans'>
                        <div>
                            <h5>Q.31</h5>
                            <p>A particle is released from height S from the surface of the Earth. At a certain height its kinetic energy is three times its potential energy. The height from the surface of earth and the speed of the particle at that instant are respectively</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer C</li>
                                <li style={{ display: "flex" }}> <p style={{ color: "red", fontWeight: "500" }}> INCORRECT</p> || On Screen: 00:03|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>

                </div>



                <div className='qanda'>
                    <div className='beans'>
                        <div>
                            <h5>Q.22</h5>
                            <p>A body is executing simple harmonic motion with frequency ' n ', the frequency of its potential energy is</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer A</li>
                                <li style={{ display: "flex" }}> <p style={{ color: "red", fontWeight: "500" }}> INCORRECT</p> || On Screen: 00:03|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>
                </div> */}


                






            </div>








        </>

    );


}
import React,{useEffect} from 'react';
import './upcoming.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';

import { useHistory, useParams, Link,useNavigate } from "react-router-dom";
import Moment from 'react-moment';
import { useTimer } from 'react-timer-hook';
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
export default function Upcoming({schedules = [],onTimeExpire}) {
    let navigate = useNavigate();
    const {
        seconds,
        minutes,
        hours,
        days,
        start,
        pause,
        resume,
        restart,
      } = useTimer({ expiryTimestamp:1000, onExpire: () =>  {

        // if(schedules.length)
        // navigate('/zoomvideo', { state: { meetid: schedules[0].meetingid,password:schedules[0].password } });

    } });

//       const secondsbwdates = (date) =>{
//         var t1 = new Date();
// var t2 = new Date(date);
// var dif = t1.getTime() - t2.getTime();

// var Seconds_from_T1_to_T2 = dif / 1000;
// var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
// return Seconds_Between_Dates
//       }

// console.log((secondsbwdates(schedules[0].start_time)),"(secondsbwdates(schedules[0].start_time))")
      
    useEffect(() => {
        

        if(schedules.length){
            //timer
            const time = new Date(schedules[0].start_time);
            // time.setSeconds(time.getSeconds() + 5000);
            restart(time);
            // console.log(Math.round(secondsbwdates(schedules[0].start_time)),"(secondsbwdates(schedules[0].start_time))")
          }


    },[schedules.length]);

    const sheduleRenderer = (e) =>{
        if((new Date() > new Date(e.start_time)) && (new Date() < new Date(e.end_time))){
return <Link to="/zoomvideo" state={{ meetid: e.meetingid,password:e.password }}><div className="shadow bg-white p-3 m-t">
                     
<div className="heading-discription kiii">
            {/* <span className="footerSocialLink1"> </span> */}
            <ul>
            <li>
                <div className='roundshape'>
                    <h6>Date</h6>
                    <p>
                    <Moment format="D MMM" withTitle>
                        {e.start_time}
                    </Moment>
                    </p>

                   
                </div>
                
                <Button  className="join_meet"
            variant="contained"
            color="#008db8">Join Meet</Button>
            </li>
            <li>
                <div>
                    <h4 className="m-0">{e.topic}</h4>
                    <p className='ti-man'>Time :  <Moment format="hh:mm A">
                        {e.start_time}
                    </Moment></p>
                </div>
            </li>

            </ul>
            
  
    </div>
    
    

</div></Link>
        }else{
            return <div className="shadow bg-white p-3 m-t">
                     
<div className="heading-discription kiii">
            {/* <span className="footerSocialLink1"> </span> */}
            <ul>
            <li>
                <div className='roundshape'>
                    <h6>Date</h6>
                    <p>
                    <Moment format="D MMM" withTitle>
                        {e.start_time}
                    </Moment>
                    </p>
                </div>
            </li>
            <li>
                <div>
                    <h4 className="m-0">{e.topic}</h4>
                    <p className='ti-man'>Time :  <Moment format="hh:mm">
                        {e.start_time}
                    </Moment></p>
                </div>
            </li>

            </ul>
            
  
    </div>
    
    

</div>
        }
    }

    return (
        <>
            <div >

                <Container>
                    {schedules.length? <Row>
                        <Col md={12}>
                        {schedules[0] && <Card className='sa_timer'>
                            <section>
        <h2>-- Next Meet --</h2>
        <h1>Topic: <span style={{color:"red"}}>{schedules[0].topic}</span></h1>
        <ul>
            <li><h1 id="day-box">{days}</h1><p>days</p></li>
            <li><h1 id="hr-box">{hours}</h1><p>hours</p></li>
            <li><h1 id="min-box">{minutes}</h1><p>minutes</p></li>
            <li><h1 id="sec-box">{seconds}</h1><p>seconds</p></li>
        </ul>
    </section>
    </Card>}
                          </Col>
                        <Col md={6}>
                        {
                        schedules.length? schedules.map(e=>sheduleRenderer(e)):null}
                            

       
              

            
                        </Col>

                        <Col md={6}>
                          
                        </Col>
                    </Row>:<div> <img width="200" height="150" src={require('../../img/no_schedule.jpg')} /></div>
                 }
                </Container>

            </div>


        </>


    );
}
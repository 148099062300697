import React,{useState,useEffect} from 'react';
import './notification.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import Sidebar from './sidbar';
import { getnotification,checkuser } from "../../actions/users";
import { NavLink,useNavigate,useLocation,NavNavLink } from "react-router-dom";
import Moment from 'react-moment';

export default function MyNotification() {
    const [notify, setnotify] = useState([]);
    const history = useNavigate();

    const getUserListdata = async () => {
        var test = await getnotification();
        if("error" in test){
            history("/");
         }
        console.log(test,"ghhgj")
        
        setnotify(test.batchdetail.reverse());
        

    }

    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);

    useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);


    return (

        <>
            
            <div className='allbody'>
        
            <div className='profilenotification'>

                <Container>
                    <Row>

                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>

                            {notify.length ?
                                <div className='col-lg-8'>

                                    <div className="box shadow-sm rounded bg-white mb-3 not-scroll">
                                        <div className="box-title border-bottom p-3">
                                            <h4 className="m-0 scu">Your Schedules</h4>
                                        </div>
                                        <div className="box-body p-0">
                                            {notify.map(notify =>
                                                <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                                    {/* <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src={require('../../img/anime.png')} alt="" />
                                        </div> */}
                                                      <div className="font-weight-bold mr-3">
                                        <div className="text-truncate"><span>Course name</span> <span style={{ marginLeft:"10px" }}>:</span> <span>{notify.coursedetail.title}</span></div>
                                            <div className="text-truncate"><span>Meeting-topic</span> <span>:</span> <span>{notify.batchdetail.topic}</span></div>
                                            <div className="small"><span style={{ fontWeight: '600', width: "40%" }}>Start-time </span> <span style={{ fontWeight: '600', width: "10%" }}> : </span>  <span style={{ fontWeight: '600', width: "50%" }}><Moment format="YYYY/MM/DD hh:mm A">
                                                            {notify.batchdetail.start_time}
                                                        </Moment></span> </div>
                                                        <div className="small"><span style={{ fontWeight: '600', width: "40%" }}>End-time </span>  <span style={{ fontWeight: '600', width: "10%", marginLeft:"6px" }}> : </span>  <span style={{ fontWeight: '600', width: "50%" }}><Moment format="YYYY/MM/DD hh:mm A">
                                                            {notify.batchdetail.end_time}
                                                        </Moment></span></div>
                                        </div>
                                                    {/* <span className="ml-auto mb-auto">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <BsThreeDotsVertical /> </button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <button className="dropdown-item" type="button"><MdDelete/> Delete</button>
                                                 
                                                </div>
                                            </div>
                                            <div className="text-right text-muted pt-1">3d</div>
                                        </span> */}
                                                </div>)}
                                   
                                    
                                  
                                    
                                  

                                    
                                        </div></div>
                            

                                    {/* <div className="box shadow-sm rounded bg-white mb-3">
                                <div className="box-title border-bottom p-3">
                                    <h4 className="m-0">Earlier</h4>
                                </div>
                                <div className="box-body p-0">
                                    <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src={require('../../img/anime.png')} alt="" />
                                        </div>
                                        <div className="font-weight-bold mr-3">
                                            <div className="text-truncate">DAILY RUNDOWN: WEDNESDAY</div>
                                            <div className="small">Income tax sops on the cards, The bias in VC funding, and other top news for you</div>
                                        </div> <span className="ml-auto mb-auto">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <BsThreeDotsVertical /> </button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <button className="dropdown-item" type="button"><MdDelete/> Delete</button>
                                                 
                                                </div>
                                            </div>
                                            <div className="text-right text-muted pt-1">3d</div>
                                        </span></div>
                                    <div className="p-3 d-flex align-items-center osahan-post-header">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src={require('../../img/anime.png')} alt="" />
                                        </div>
                                        <div className="font-weight-bold mr-3">
                                            <div className="mb-2">We found a job at askbootstrap Ltd that you may be interested in Vivamus imperdiet venenatis est...</div>
                                           
                                        </div>
                                        <span className="ml-auto mb-auto"><div className="btn-group"> <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <BsThreeDotsVertical /> </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><MdDelete /> Delete</button>
                                             
                                            </div></div> <div className="text-right text-muted pt-1">4d</div> </span>
                                    </div></div></div> */}
                            



                                </div>
                                : <img style={{ margin: "auto" }} src={require("../../img/notifications.png")} />
                            }
                                {/* <span style={{ color: "#008db8", fontSize: "16px" }}>Notification Not Found</span>} */}


                    </Row>

                </Container>



            </div>
            
        </div>
        </>

    );

}

import React, { Component,useRef,useState,useEffect } from 'react';
import { Container, Row, Col, Card, Button, Tab, Nav, SignUp } from 'react-bootstrap'
import './learningvideo.css'

import ReactPlayer from 'react-player'


import { getsubvideo} from '../../actions/users';
import { ReactVideoPlayer } from 'video-player-for-react'
import 'video-player-for-react/dist/index.css'
import config from '../../lib/config';
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
export default function Learningvideo({topic_id}) {
    const dplayerRef = useRef();
    const [canplay, setCanplay] = useState(false);
    const [play, setPaly] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const[selectedvideo,setselectedvideo]=useState(0);
    const[videos,setvideos]=useState([]);

    const userData =async()=>{

        const coursetopic = await getsubvideo(topic_id);
        console.log(coursetopic,"hello")
        if("error" in coursetopic){
           alert("Internal server error");
        }else{
            setvideos(coursetopic.userValue)

        }

      }
    useEffect(()=>{
        userData();
    },[])

    return (

           <>

            <div>

             <div className='soon'>
             
                    <Container>
                        <Row>
                        {selectedvideo? <Col lg={12} md={12}>
                        <div style={{width:"50%",height:"auto"}}>
                            {/* <ReactVideoPlayer
                                        width='100%'
                                        height='100%'
                                        url={`${config.API}/images/user/${selectedvideo.file}`}
                                        type='video/mp4'
                                        poster={`${config.API}/images/user/${selectedvideo.image}`}
                                    /> */}
                         <Plyr source={  {type: "video",
                                 sources: [
                                   {
                                     src: `${config.API}/images/user/${selectedvideo.file}`,

                                   }
                                 ]}} />
          
                            </div>
                            </Col> : null}
                            {videos.length ? videos.map(e=><Col lg={3} md={6}>
                                <Card className='filter-course' onClick={()=>setselectedvideo({file:e.file,image:e.image})}>
                                <Card.Img className='course-img' variant="top" src={`${config.API}/images/cover/${e.image}`} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>{e.title}</Card.Title>
                                        {/* <Card.Text className='filter-para'>
                                          <p>NEET 2023 | Electrochemistry | Lecture 1 | Physical Chemistry </p>
                                        </Card.Text> */}
                                      
                                    </Card.Body>
                                </Card>
                            </Col>) : <img style={{ margin: "auto" }} src={require("../../img/no video.png")} />
                            }
                                {/* <h1>No videos Available</h1>} */}

                        </Row>
                    </Container>
                    
             </div>




                

            </div>
           </>

    );
}
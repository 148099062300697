import React, { useState, useEffect } from "react";
import config from "../../lib/config";
import { Container, Row, Col, Card, Button, Tab, Nav, SignUp } from 'react-bootstrap'
import './tabpara.css'
import Carousel from 'react-bootstrap/Carousel'
import { useNavigate,useParams,Link } from "react-router-dom";

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { courses } from "../../actions/users";

import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

export default function Tappara({coursecontents}) {


  console.log(coursecontents,"coursecontentscoursecontentscoursecontentscoursecontentscoursecontents")

    const img = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        appendDots: (dots) => {
            return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />
        },
        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,

            }
        }

        ]
    };
    const img1 = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        appendDots: (dots) => {
            return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />
        },
        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,

            }
        }

        ]
    };

    const img2 = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        appendDots: (dots) => {
            return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />
        },
        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,

            }
        }

        ]
    };

    const img3 = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        appendDots: (dots) => {
            return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />
        },
        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,

            }
        }

        ]
    };
    if(coursecontents.length==3){
        return (
            <div class="train">
                {/* <div>
                    <p>qsjhgdgafewrgwuegrwegrwgurwegu</p>
                </div> */}
    
                <Slider {...img1}>
                {coursecontents.map(courses =>
                    <div key={courses._id}>
                        <Card >
                            {/* <iframe src="https://www.youtube.com/embed/pWahNIMRxR0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            <Link to={"/buycourse/"+courses._id}><Card.Img className='costbanner' variant="top" src={config.API +"/images/user/"+courses.image} /></Link>
                            <Card.Body style={{ padding:"4px 10px"}}>
                                <Link to='#'><Card.Title>{ courses.title} </Card.Title></Link>
                                {/* <Card.Text>
                                    Online Test Series
                                </Card.Text>
                               <p className='rating'>Rs. 4,999</p> */}
                            </Card.Body>
                        </Card>
                    </div>)}
                   
    
                </Slider>
    
            </div>
        );

    }

    if(coursecontents.length==2){
        return (
            <div class="train">
                {/* <div>
                    <p>qsjhgdgafewrgwuegrwegrwgurwegu</p>
                </div> */}
    
                <Slider {...img2}>
                {coursecontents.map(courses =>
                    <div key={courses._id}>
                        <Card className="jmain">
                            {/* <iframe src="https://www.youtube.com/embed/pWahNIMRxR0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            <Link to={"/buycourse/"+courses._id}><Card.Img className='costbanner' variant="top" src={config.API +"/images/user/"+courses.image} /></Link>
                            <Card.Body style={{ padding:"4px 10px"}}>
                                <Link to='#'><Card.Title>{ courses.title} </Card.Title></Link>
                                {/* <Card.Text>
                                    Online Test Series
                                </Card.Text>
                               <p className='rating'>Rs. 4,999</p> */}
                            </Card.Body>
                        </Card>
                    </div>)}
                   
    
                </Slider>
    
            </div>
        );

    }

    if(coursecontents.length==1){
        return (
            <div class="train">
                {/* <div>
                    <p>qsjhgdgafewrgwuegrwegrwgurwegu</p>
                </div> */}
    
                <Slider {...img3}>
                {coursecontents.map(courses =>
                    <div key={courses._id}>
                        <Card className="jeee">
                            {/* <iframe src="https://www.youtube.com/embed/pWahNIMRxR0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            <Link to={"/buycourse/"+courses._id}><Card.Img className='costbanner' variant="top" src={config.API +"/images/user/"+courses.image} /></Link>
                            <Card.Body style={{ padding:"4px 10px"}}>
                                <Link to='#'><Card.Title>{ courses.title} </Card.Title></Link>
                                {/* <Card.Text>
                                    Online Test Series
                                </Card.Text>
                               <p className='rating'>Rs. 4,999</p> */}
                            </Card.Body>
                        </Card>
                    </div>)}
                   
    
                </Slider>
    
            </div>
        );

    }


 

    return (
        <div class="train">
            {/* <div>
                <p>qsjhgdgafewrgwuegrwegrwgurwegu</p>
            </div> */}

            <Slider {...img}>
            {coursecontents.map(courses =>
                <div key={courses._id}>
                    <Card >
                        {/* <iframe src="https://www.youtube.com/embed/pWahNIMRxR0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <Link to={"/buycourse/"+courses._id}><Card.Img className='costbanner' variant="top" src={config.API +"/images/user/"+courses.image} /></Link>
                        <Card.Body style={{ padding:"4px 10px"}}>
                            <Link to='#'><Card.Title>{ courses.title} </Card.Title></Link>
                            {/* <Card.Text>
                                Online Test Series
                            </Card.Text>
                           <p className='rating'>Rs. 4,999</p> */}
                        </Card.Body>
                    </Card>
                </div>)}
               

            </Slider>

        </div>
    );
}

 
import React, {useEffect,useState} from 'react';
import './schedule.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';

import Upcoming from '../../Components/Upcoming/upcoming'
import Completded from '../../Components/Completed/completed'

import { upcomingSchedules,completedSchedules } from "../../actions/users";

import { useHistory, useParams, Link, useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

export default function Schedule() {
    
    const { ic_id } = useParams();
    const [userdet, setUserDet] = useState([]);
    const [userdet1, setUserDet1] = useState([]);
    const history = useNavigate();

    


    const getUserListdata = async () => {
        // alert("dsdsdsd")
        var test = await upcomingSchedules(ic_id);
        setUserDet(test.userValue);
        console.log(test.userValue,"test.userValuetest.userValue")
    
        var test = await completedSchedules(ic_id);
        setUserDet1(test.userValue);
      };
    
      useEffect(() => {
        getUserListdata();
      }, []);

    return (
        <>
            <div className='allbody'>
                <div className='fearless'>

                    <Container> 

                    <div style={{ marginBottom: "10px" }}>
                            <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                        </div>

                    <div className='ags'>    
                            <div id="exTab1" >
                                <ul class="nav nav-pills">
                                    <li class="active">
                                        <Link to="#aaa" data-toggle="tab">Upcoming</Link>
                                    </li>
                                    <li><Link to="#bbb" data-toggle="tab">Completed</Link>
                                    </li>

                                </ul>

                                <div class="tab-content clearfix">
                                    <div class="tab-pane active" id="aaa">
                                        <Upcoming onTimeExpire={()=>getUserListdata()} schedules={userdet} />
                                    </div>
                                    <div class="tab-pane" id="bbb">
                                        <Completded schedules={userdet1} />
                                    </div>

                                </div>
                         
                        </div>

                  
                        </div>

                    </Container>
                </div>

            </div>


        </>


    );
}
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  NavItem,
} from "react-bootstrap";
import config from "../../lib/config";
import "./home.css";

import Carouselss from "../../Components/Carousel/carousel";
import isEmpty from "../../lib/isEmpty";
import { getclass } from "../../actions/users";
import { getexam } from "../../actions/users";

import Tabpara from "../../Components/Tabpara/tappara";
import Courses from "../../Components/Courses/courses";
import Coach from "../../Components/Coach/courses";

import CountUp, { useCountUp } from "react-countup";
import Form from "react-bootstrap/Form";
import { FaUserGraduate } from "react-icons/fa";
import { BsPersonFill } from "react-icons/bs";

import Carousel from "react-bootstrap/Carousel";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReactPlayer from "react-player";
import { useNavigate, useParams, Link } from "react-router-dom";

import { ReactVideoPlayer } from "video-player-for-react";
import "video-player-for-react/dist/index.css";

import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { homepage } from "../../actions/users";
import { ourexams } from "../../actions/users";
import { getlogo } from "../../actions/users";
import { getservice } from "../../actions/users";
import { getstudy } from "../../actions/users";
import { getrecent } from "../../actions/users";
import { getmission } from "../../actions/users";
import { gettestimonial } from "../../actions/users";
import { gettrust } from "../../actions/users";
import { getfame } from "../../actions/users";
import { getmarvel } from "../../actions/users";
import { getoffer,getcouponcode } from "../../actions/users";
import { addenquiry } from "../../actions/users";
import { toast } from "react-toastify";
import { coursesp } from "../../actions/users";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

// toast.configure();
// let toasterOption = {
//   position: "top-right",
//   autoClose: 2000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   progress: undefined,
// };

const img = {
  dots: true,
  arrows: true,
  infinite: true,  
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  appendDots: (dots) => {
    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const img1 = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  appendDots: (dots) => {
    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const img3 = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  appendDots: (dots) => {
    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const initialFormValue = {
  name: "",
  email: "",
  phone: "",
  mclass: "",
  exam: "",
};

export default function Home() {
  const [formValue, setFormValue] = useState(initialFormValue);
  const [banner, setbanner] = useState([]);
  const [coursecontent, setcoursecontent] = useState([]);
  const [course, setcourse] = useState("");
  const [study, setstudy] = useState([]);
  const [ourexam, setourexam] = useState([]);
  const [recent, setrecent] = useState([]);
  const [logo, setlogo] = useState([]);
  const [trust, settrust] = useState({});
  const [fame, setfame] = useState([]);
  const [service, setservice] = useState({});
  const [couponcode, setcouponcode] = useState({});
  const [mission, setmission] = useState({});
  const [testimonial, settestimonial] = useState([]);
  const [marvel, setmarvel] = useState({});
  const [offer, setoffer] = useState({});
  const history = useNavigate();
  const [validateError, setValidateError] = useState({});
  const [classopt, setclassopt] = useState([]);
  const [examopt, setexamopt] = useState([]);

  const[captchavalue,setcaptchavalue] = useState("");

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = String(str);

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  function coursechange(id) {
    const course = id;
    setcourse(course);
  }

  const coursechanges = async (id) => {
    var test5 = await coursesp(id);
    console.log(test5, "sai");
    setcoursecontent(test5.coursep);
  };

  const getUserListdata = async () => {
    var test = await homepage();
    console.log(test, "1");

    setbanner(test.banner);
    var test = await getrecent();
    setrecent(test.recent);
    var test = await getlogo();
    console.log(test, "2");
    setlogo(test.logo);
    var test = await getservice();
    setservice(test.service);
    var test = await getcouponcode();
    setcouponcode(test.couponcode);
    var test = await getstudy();
    setstudy(test.study);
    var test = await getmission();
    setmission(test.mission);
    var test = await gettestimonial();
    console.log(test, "3");
    settestimonial(test.testimonial);
    var test = await gettrust();
    console.log(test, "4");
    settrust(test.trust);
    var test = await getmarvel();
    console.log(test, "aaa");

    setmarvel(test.marvel);
    var test = await getoffer();
    console.log(test, "6");

    setoffer(test.offer);

    var test = await getfame();
    console.log(test, "7");
    setfame(test.fame);

    var test1 = await ourexams();
    console.log(test1, "8");
    setourexam(test1.exam);

    if (test1.exam.length) {
      coursechanges(test1.exam[0]._id);
      setcourse(test1.exam[0]._id);
    }
    var test = await getclass();

    // formdata["Photofile"] = test.userValue.image;
    setclassopt(test.mclass);

    var test = await getexam();

    // formdata["Photofile"] = test.userValue.image;
    setexamopt(test.exam);
  };

  const onChange = (e) => {
    e.preventDefault();
    // console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
    //setValidateError(formData)
  };

//for recaptcha 
function onChangerecaptcha(value) {
    // console.log("Captcha value:", value);
    setcaptchavalue(value);
  }





  const { name, email, phone, mclass, exam } = formValue;
  const handleFormSubmit = async (e) => {
    console.log("saran");
    e.preventDefault();
    console.log(formValue);
    let reqData = {
      name,
      email,
      phone,
      mclass,
      exam,
    };
    if(captchavalue){
    let { error } = await addenquiry(reqData);

    if (!error) {
      history("/");
      swal("Thank you for your enquiry,We will get back to you");
      setFormValue(initialFormValue);
      setValidateError({});
    } else {
      setValidateError(error);
      // swal("Please try again");
    }
}else{
    swal("Please fill the required details and verify the captcha",);
}
  };

  useEffect(() => {
    if (course) {
      coursechanges(course);
    }
  }, [course]);

  useEffect(() => {
    // or simply just AOS.init();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getUserListdata();

    // $(window).on('load', function (event) {
    //     $('.jQueryEqualHeight').jQueryEqualHeight();
    // });
  }, []);

  return (
    <div className="allbody allbodyzz" style={{ overflow: "hidden" }}>
      <div>
        <Carouselss banners={banner} />
      </div>

      {/* <div>
                <img  src={require('../../img/marveel.png')}  alt="cookie" />
             </div>    */}

      <div className="academic">
        <div>
          <h2 className="marvel-program">20+ Years Of Academic Excellence</h2>
          <div className="container">
          {study.map(study =>
            <div className="col-lg-3 col-sm-6 col-xs-6">
              <div className="academic-content">
                <div className="counterz">
                  <CountUp end={study.heading} duration={5} className="countzz" />{" "}
                  <h3>+</h3>
                </div>
                <p> {removeTags(study.description)}</p>
              </div>
            </div>)}
            
         
          </div>
        </div>
      </div>

      {/* <div className='container'>
           <div class="hu-users">
                    <div class="row g-0">
                    {study.map(study =>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="hu_col hu_col_first">
                                <div class="hu_icon dean3">
                                    <img src={config.API +"/images/user/"+study.image} className="icon_img" alt="" />
                                </div>
                                <div class="hu_content">
                                    <p> {removeTags(study.description)}</p>
                                    <h6 class="count"> {removeTags(study.heading)} 
                                    </h6></div>

                            </div>
                        </div>)}

               



                </div>
                
                </div>
                
            </div> */}

      {/* Marvel leaning app */}

      {/* <div className='program_section'>

                <div className='container'>
                    <div class="section-hedding text-center pb-3">
                        <h3 class="section-title">India’s best learning app</h3>
                        <p className="learnppp"> {removeTags(recent.description)}</p>
                    </div>

                    <div class="row justify-content-between ">
                        <div class="col-xs-12 col-sm-12 col-md-6 ">

                            <div class="card program_col">
                                <a href="#">
                                    <h5 class="program_title"> NEET Live Classes </h5>
                                </a>
                                <div class="row content-center">
                                    <div class="col-xs-6 col-sm-6 col-md-6 border-right">
                                        <a href="#"> <img class="img-fluid program___img" src={config.API +"/images/user/"+recent.image} /></a>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6">
                                        <div class="card-body">

                                            <p class="card-text"> {removeTags(recent.description2)}</p>
                                            <Link to="/exam/62c3f00bbcef2f47c860da7c" class="btn ma-uto">Explore</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 ">
                            <a href="#">
                            </a><div class="card program_col"><a href="#">
                                <h5 class="program_title"> JEE Live Classes</h5>
                            </a><div class="row content-center"><a href="#">
                            </a><div class="col-xs-6 col-sm-6 col-md-6 border-right"><a href="#">
                            </a><a href="#"><img class="img-fluid program___img" src={config.API +"/images/user/"+recent.image2}/></a>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6">
                                        <div class="card-body">

                                            <p class="card-text"> <div  dangerouslySetInnerHTML={{ __html: recent.description3 }} /></p>
                                            <Link to="exam/62d651644e084faff324756d" class="btn ma-uto">Explore</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

            </div> */}

      {/* Marvel learning app end */}

      <div className="digital">
        <div>
          <h2 className="marvel-program">Marvel Digital Program</h2>

          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-xs-6">
                <div class="wrapper">
                
                  <img
                    src={config.API + "/images/user/" + service.image}
                    className="allmed"
                    alt="cookie"
                  />
                  <div class="content">
                    <header>{trust.content1}</header>
                    <Link to="/courses/62cd0ca31538ec3c90ab3c73">
                      {" "}
                      <Button variant="success" className="digi-pro">
                        Know More
                      </Button>{" "}
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-6">
                <div class="wrapper">
                 
                  <img
                    src={config.API + "/images/user/" + service.image2}
                    className="allmed"
                    alt="cookie"
                  />
                  <div class="content">
                    <header>{trust.content2}</header>
                    <Link to="/courses/62cd0caf1538ec3c90ab3c74">
                      <Button variant="success" className="digi-pro">
                        Know More
                      </Button>{" "}
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-6">
                <div class="wrapper">
                 
                  <img
                    src={config.API + "/images/user/" + service.image3}
                    className="allmed"
                    alt="cookie"
                  />

                  <div class="content">
                    <header>{trust.content3}</header>
                    <Link to="/courses/62ce6480e201276788868b3d">
                      {" "}
                      <Button variant="success" className="digi-pro">
                        Know More
                      </Button>{" "}
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-6">
                <div class="wrapper">
                 
                  <img
                    src={config.API + "/images/user/" + service.image4}
                    className="allmed"
                    alt="cookie"
                  />
                  <div class="content">
                    <header>{trust.content4}</header>
                    <Link to="/courses/62d651644e084faff324756d">
                      {" "}
                      <Button variant="success" className="digi-pro">
                        Know More
                      </Button>{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <img
          style={{ width: "100%", marginBottom:"30px" }}
          src={config.API + "/images/user/" + marvel.image}
          alt="cookie"
        />
      </div>

      {/* Our Programs */}

      <div className="tabsss">
        <div className="container">
          <h2 className="Our-Programs"> OUR PROGRAMS</h2>
          <div id="exTab1">
            <ul class="nav nav-pills">
              {/* <li><a href="#2a" data-toggle="tab">All course</a>
                            </li> */}
              {ourexam.map((ourexam, i) => (
                <li
                  class={course == ourexam._id && "active"}
                  onClick={() => coursechange(ourexam._id)}
                >
                  <Link to="#1a" data-toggle="tab">
                    {ourexam.content}
                  </Link>
                </li>
              ))}
            </ul>

            <div class="tab-content clearfix">
              <div class="tab-pane active" id="1a">
             { coursecontent.length&&<Tabpara coursecontents={coursecontent} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="mar">
        <Container>
          <Row>
            <Col md={8}>
              <h3 className="shortterm">Short Term Courses</h3>
              <Row className="shorttermrows">
               
                <Col md={6} lg={6} xs={6}>
                  <img className="shorted" src={config.API + "/images/user/" + service.image5} alt="cookie" style={{ marginTop:"10px" }} />
                </Col>
                <Col md={6} lg={6} xs={6}>
                  <img className="shorted" src={config.API + "/images/user/" + service.image6} alt="cookie" style={{ marginTop: "10px" }} />
                </Col>
              </Row>
            </Col>

            <Col md={4}>
              <div className="mar-vel">
                <div>
                  <h2 className="ymarvel1">For Enquiry</h2>
                  <p className="ymarvel2">
                    Please fill the details for personalised counselling by our
                    experts
                  </p>
                </div>
                <div>
                  <Form onSubmit={handleFormSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="name"
                        id="name"
                        value={name}
                        onChange={onChange}
                        placeholder="Name*"
                      />
                      {validateError.name && (
                        <span style={{ color: "#ffff", fontSize: "15px" }}>
                          {validateError.name}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        id="email"
                        value={email}
                        onChange={onChange}
                        placeholder="Enter email"
                      />
                      {validateError.email && (
                        <span style={{ color: "#ffff", fontSize: "15px" }}>
                          {validateError.email}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="name"
                        id="phone"
                        value={phone}
                        onChange={onChange}
                        placeholder="Phone*"
                      />
                      {validateError.phone && (
                        <span style={{ color: "#ffff", fontSize: "15px" }}>
                          {validateError.phone}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Select
                      value={mclass}
                      onChange={onChange}
                      id="mclass"
                    >
                        <option value="">
                         Select Class
                        </option>
                      {classopt.map((classopt) => (
                        <option value={classopt.content}>
                          {classopt.content}{" "}
                        </option>
                      ))}
                    </Form.Select>
                    {validateError.mclass && (
                      <span style={{ color: "#ffff", fontSize: "15px" }}>
                        {validateError.mclass}
                      </span>
                    )}
                    <Form.Select
                      value={exam}
                      onChange={onChange}
                      id="exam"
                    >
                  <option value="">
                         Select Exam
                        </option>
                      {examopt.map((examopt) => (
                        <option value={examopt.content}>
                          {examopt.content}{" "}
                        </option>
                      ))}
                    </Form.Select>
                    {validateError.exam && (
                      <span style={{ color: "#ffff", fontSize: "10px" }}>
                        {validateError.exam}
                      </span>
                    )}

                    <ReCAPTCHA
                      sitekey="6LfYIK8jAAAAACCKtYZDsAFVpnBcVAoaxlNHJWQs"
                      onChange={onChangerecaptcha}
                    style={{ margin:"10px 0px", width:"100%" }} />
                    <Button className="btttn" variant="primary" type="submit">
                      Send
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* why marvel end */}

      {/* Marvel Offers */}

      {/* <div class="education-area-two ">
                <div class="container">
                    <div class="row content-center">
                        <div class="col-lg-7">


                            <div class=" section-hedding text-left">
                                <h3 class="section-title">What Marvel Classes Offers? </h3>
                                <p> <div  dangerouslySetInnerHTML={{ __html: offer.content }} /></p>

                                <div class="row">


                                    <ul class="list-group col-md-6">


                                        <li class="list-group-item"> <i class="fas fa-check"></i>
                                            <p>{offer.description} </p>
                                        </li>

                                        <li class="list-group-item"> <i class="fas fa-check"></i>
                                            <p> {offer.description2} </p>
                                        </li>
                                        <li class="list-group-item"><i class="fas fa-check"></i>
                                            <p> {offer.description3} </p>
                                        </li>
                                        <li class="list-group-item"> <i class="fas fa-check"></i>
                                            <p> {offer.description4} </p>
                                        </li>

                                    </ul>

                                    <ul class="list-group col-md-6">
                                        <li class="list-group-item"> <i class="fas fa-check"></i>
                                            <p> {offer.description5} </p>
                                        </li>
                                        <li class="list-group-item"> <i class="fas fa-check"></i>
                                            <p> {offer.description6} </p>
                                        </li>
                                        <li class="list-group-item"> <i class="fas fa-check"></i>
                                            <p> {offer.description7}</p>
                                        </li>
                                        <li class="list-group-item"> <i class="fas fa-check"></i>
                                            <p>{offer.description8} </p>
                                        </li>

                                    </ul>


                                </div>

                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="education-img-wrap">
                                <img src={config.API +"/images/user/"+offer.image} class="offerimg" alt="Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* Marvel Offers end */}

      {/* Course Category */}

      {/* <div className='course-category'>
                <div>
                    <h2 className='Course1'> COURSE CATEGORIES</h2>
                    <p className='course-para'>Become a life long learners with indias best teachers.engaging video lessons and personalised learning journeys.</p>
                </div>
                <Container style={{ padding:"30px 0px" }}>
                    <Row>
                        <Col md={3}>
                            <Row>
                                <div className='round'>
                                <Col md={4}>
                                        <Link to="/exam/62c3f00bbcef2f47c860da7c" style={{ textDecoration: "none" }}><img className="doc" src={require('../../img/doc.png')} /></Link>
                                    </Col>
                                <Col md={8}>
                                        <Link to="/exam/62c3f00bbcef2f47c860da7c" style={{ textDecoration: "none" }}><p className='categories'>MEDICAL</p></Link>
                                    </Col>
                                </div>
                            </Row>
                              
                        </Col> 
                        <Col md={3}>
                            <Row>
                             
                                <div className='round'>
                                   
                                <Col md={4}>
                                        <Link to="/exam/62d651644e084faff324756d"><img className="doc" src={require('../../img/eng.png')} />  </Link>
                                </Col>
                                <Col md={8}>
                                        <Link to="/exam/62d651644e084faff324756d" style={{textDecoration:"none" }}><p className='categories'>ENGINEERING</p></Link>
                                    </Col>
                                    </div>
                              
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Row>
                                <div className='round'>
                                <Col md={4}>
                                        <Link to="/exam/62c54f67c09bc942a05c0cc7" style={{ textDecoration: "none" }}><img className="doc" src={require('../../img/school.png')} /></Link>
                                </Col>
                                <Col md={8}>
                                        <Link to="/exam/62c54f67c09bc942a05c0cc7" style={{ textDecoration: "none" }}><p className='categories'>CBSE</p></Link>
                                    </Col>
                                </div>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Row>
                                <div className='round'>
                                <Col md={4}>
                                        <Link to="/exam/62c54f67c09bc942a05c0cc7" style={{ textDecoration: "none" }}><img className="doc" src={require('../../img/school.png')} /></Link>
                                </Col>
                                <Col md={8}>
                                        <Link to="/exam/62c54f67c09bc942a05c0cc7" style={{ textDecoration: "none" }}><p className='categories'>FOUNDATION</p></Link>
                                    </Col>
                                </div>
                            </Row>
                        </Col>
                    </Row>
               </Container>
            </div> */}

      <div className="wallframe">
        <Container>
          <div>
            <h3>Marvel Classes Wall of Fame</h3>
            <p className="para">
              Become lifelong learners with India's best teachers, engaging
              video lessons and personalised earning jourlneys
            </p>
          </div>

          <div style={{ padding: "30px 20px" }}>
            <Slider {...img3}>
              {fame &&
                fame.map((fame) => (
                  <div>
                    <div
                      className="faculty__member_col card"
                      style={{ borderRadius: "8px" }}
                    >
                      <div className="faculty__member_img">
                        <img
                          src={config.API + "/images/user/" + fame.image}
                          className="img-fluid faculty__member_shap"
                          alt="author"
                        />
                      </div>
                      <div className="card-body text-center">
                        <h4 className="victro">
                          {/* <a href="/#"> */}
                            {fame.name}
                          {/* </a> */}
                        </h4>
                        <p className="faculty_followers">{fame.exam}</p>
                      </div>
                    </div>
                  </div>
                ))}


            </Slider>
          </div>
        </Container>
      </div>

      {/* wall end */}

      {/* student say */}

      <div className="studentsay">
        <div className="container">
          <div>
            <h3>What students say</h3>
            <p className="para">
             We learnt from excellent expert and now we are expect.
            </p>
          </div>
          <div style={{ marginTop: "40px" }}>
            <Slider {...img1}>
              {testimonial.map((testimonial) => (
                <div>
                  <Card>
                    <Card.Img
                      className="studentimg"
                      style={{ width:"30% !important", height:"140px !important" }}
                      variant="top"
                      src={config.API + "/images/user/" + testimonial.image}
                    />
                    <Card.Body>
                      <Card.Text className="studenttext">
                        {removeTags(testimonial.team)}
                      </Card.Text>
                      <Card.Title className="studenttitle">
                        <div
                          dangerouslySetInnerHTML={{ __html: testimonial.name }}
                        />
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </div>
              ))}

              {/* <div>
                                <Card>
                                    <Card.Img className='studentimg' variant="top" src={require('../../img/dell.png')} />
                                    <Card.Body>
                                        <Card.Text className='studenttext'>
                                            In this hard time of COVID 19 pandemic lockdown, the support of whole Career Point Team is wonderful. Here on eCareerPoint app and website I can attend live classes, quizzes, DPPS and lot more
                                        </Card.Text>
                                        <Card.Title className='studenttitle'>Rohit Sharma</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div>
                                <Card>
                                    <Card.Img className='studentimg' variant="top" src={require('../../img/dell.png')} />
                                    <Card.Body>
                                        <Card.Text className='studenttext'>
                                            In this hard time of COVID 19 pandemic lockdown, the support of whole Career Point Team is wonderful. Here on eCareerPoint app and website I can attend live classes, quizzes, DPPS and lot more
                                        </Card.Text>
                                        <Card.Title className='studenttitle'>Rohit Sharma</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div>
                                <Card>
                                    <Card.Img className='studentimg' variant="top" src={require('../../img/dell.png')} />
                                    <Card.Body>
                                        <Card.Text className='studenttext'>
                                            In this hard time of COVID 19 pandemic lockdown, the support of whole Career Point Team is wonderful. Here on eCareerPoint app and website I can attend live classes, quizzes, DPPS and lot more
                                        </Card.Text>
                                        <Card.Title className='studenttitle'>Rohit Sharma</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div>
                                <Card>
                                    <Card.Img className='studentimg' variant="top" src={require('../../img/dell.png')} />
                                    <Card.Body>
                                        <Card.Text className='studenttext'>
                                            In this hard time of COVID 19 pandemic lockdown, the support of whole Career Point Team is wonderful. Here on eCareerPoint app and website I can attend live classes, quizzes, DPPS and lot more
                                        </Card.Text>
                                        <Card.Title className='studenttitle'>Rohit Sharma</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div>
                                <Card>
                                    <Card.Img className='studentimg' variant="top" src={require('../../img/dell.png')} />
                                    <Card.Body>
                                        <Card.Text className='studenttext'>
                                            In this hard time of COVID 19 pandemic lockdown, the support of whole Career Point Team is wonderful. Here on eCareerPoint app and website I can attend live classes, quizzes, DPPS and lot more
                                        </Card.Text>
                                        <Card.Title className='studenttitle'>Rohit Sharma</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div>
                                <Card>
                                    <Card.Img className='studentimg' variant="top" src={require('../../img/dell.png')} />
                                    <Card.Body>
                                        <Card.Text className='studenttext'>
                                            In this hard time of COVID 19 pandemic lockdown, the support of whole Career Point Team is wonderful. Here on eCareerPoint app and website I can attend live classes, quizzes, DPPS and lot more
                                        </Card.Text>
                                        <Card.Title className='studenttitle'>Rohit Sharma</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div> */}
            </Slider>
          </div>
        </div>
      </div>

      {/* student say end */}

      {/* app side */}

      <div className="our-app">
        <div className="container">
          <div className="app-wrap">
<div className="row">
<div className="col-md-5">
            <div className="app-wrap-content" style={{ marginTop:"10px" }}>
              <h3>
                {" "}
                Start your learning <br></br> journey now!{" "}
              </h3>
              <nav>
                <ul className="appcrumb">
                  <li className="breadcrumb-item">Learn </li>
                  <li className="breadcrumb-item">Practice </li>
                  <li className="breadcrumb-item">Improve </li>
                  <li className="breadcrumb-item">Succeed </li>
                </ul>
              </nav>


              <h3 className="couponcode">
                {" "}
                Use a coupon code {couponcode.code} and get a discount of<br></br> {couponcode.offerpercent}% for a purchase of above{couponcode.minamount}{" "}
              </h3>

              <div className="button-group">
                {/* <Button variant="success" className="getstarted">
                  {" "}
                  Get Started
                </Button>{" "} */}
                
                <Button variant="primary" className="dapp">
                  <i class="fab fa-google-play"></i>{" "}
                  <p className="downapp"> Download App </p>
                </Button>{" "}
              </div>
            </div>
            </div>

            <div className="col-md-7">
            <div class="app-wrap-img" style={{ marginTop:"10px" }}>
              <img
                src={require("../../img/appsimg.png")}
                class="img-fluid"
                alt="img"
              />
            </div>
            </div>

            </div>
          </div>
        </div>
      </div>

      {/* appside end */}

      {/* login tab */}
      {/* <div className='bailwan'>
            <div class="container mt-5 pt-5">
                <div class="card mx-auto border-0">
                    <div class="card-header border-bottom-0 bg-transparent">
                        <ul class="nav nav-tabs justify-content-center pt-4" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active text-primary" id="pills-login-tab" data-toggle="pill" href="#pills-login" role="tab" aria-controls="pills-login"
                                    aria-selected="true">Login</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-primary" id="pills-register-tab" data-toggle="pill" href="#pills-register" role="tab" aria-controls="pills-register"
                                    aria-selected="false">Register</a>
                            </li>
                        </ul>
                    </div>

                    <div class="card-body pb-4">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">
                                <form>
                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control" id="email" placeholder="Email" required autofocus />
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password" class="form-control" id="password" placeholder="Password" required />
                                    </div>

                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" id="customCheck1" checked="" type="checkbox" />
                                            <label class="custom-control-label" for="customCheck1">Check me out</label>
                                    </div>

                                    <div class="text-center pt-4">
                                        <button type="submit" class="btn btn-primary">Login</button>
                                    </div>

                                    <div class="text-center pt-3">
                                        <a class="btn btn-link text-primary" href="#">Forgot Your Password?</a>
                                    </div>
                                </form>
                            </div>

                            <div class="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="pills-register-tab">
                                <form>
                                    <div class="form-group">
                                        <input type="text" name="username" id="name" class="form-control" placeholder="Username" required autofocus />
                                    </div>

                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Email" required />
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password" id="password" class="form-control" placeholder="Set a password" required />
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password_confirmation" id="password-confirm" class="form-control" placeholder="Confirm password" required />
                                    </div>

                                    <div class="text-center pt-2 pb-1">
                                        <button type="submit" class="btn btn-primary">Register</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </div> */}
      {/* login tab end */}
    </div>
  );
}

import React,{useState,useEffect} from 'react';
import './myexamselection.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'

import { Toast } from 'react-bootstrap'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './sidbar'

export default function Myexam() {

    const notify = () => toast("Your preference is successfully added!");
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])
    return (

          <>
        
            <div className='allbody'>
            <div className='judge'>

                <Container>
                    <Row>

                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>


                        
                        <div className="col-lg-8">
                            <h4> My Exam Selection</h4>
                            <div className="notification__list card examcheck">

                                <ul className="list-group ">
                                    <li className="list-group-item content-center">
                                       
                                        <div className="form-check">
                                            <input className="form-check-input product_master_filter cf_class" type="checkbox" name="cf_class" id="cf_class_55" data-title="Class-12" value="55" />
                                            <label className="form-check-label" for="cf_class_55">
                                                NEET                             </label>
                                        </div>
                                        
                                    </li>
                                    
                                    <li className="list-group-item content-center">

                                        <div className="form-check">
                                            <input className="form-check-input product_master_filter cf_class" type="checkbox" name="cf_class" id="cf_class_79" data-title="Class-12/12 Pass" value="79" />
                                            <label className="form-check-label" for="cf_class_79">
                                                JEE Main                        </label>
                                        </div>

                                    </li>

                                    <li className="list-group-item content-center">

                                        <div className="form-check">
                                            <input className="form-check-input product_master_filter cf_class" type="checkbox" name="cf_class" id="cf_class_79" data-title="Class-12/12 Pass" value="79" />
                                            <label className="form-check-label" for="cf_class_79">
                                                JEE Advance                       </label>
                                        </div>

                                    </li>

                                    <li className="list-group-item content-center">

                                        <div className="form-check">
                                            <input className="form-check-input product_master_filter cf_class" type="checkbox" name="cf_class" id="cf_class_79" data-title="Class-12/12 Pass" value="79" />
                                            <label className="form-check-label" for="cf_class_79">
                                                CBSE                         </label>
                                        </div>

                                    </li>

                                    <li className="list-group-item content-center">

                                        <div className="form-check">
                                            <input className="form-check-input product_master_filter cf_class" type="checkbox" name="cf_class" id="cf_class_79" data-title="Class-12/12 Pass" value="79" />
                                            <label className="form-check-label" for="cf_class_79">
                                                Foundation                          </label>
                                        </div>

                                    </li>

                                    <li className="list-group-item content-center">

                                        <div className="form-check">
                                            <input className="form-check-input product_master_filter cf_class" type="checkbox" name="cf_class" id="cf_class_79" data-title="Class-12/12 Pass" value="79" />
                                            <label className="form-check-label" for="cf_class_79">
                                                TN Board                        </label>
                                        </div>

                                    </li>

                                </ul>

                                {/* done button */}

                                <div className='done'>
                                    <button className='donebtn' onClick={notify}>Done</button>
                                    <ToastContainer />
                                </div>
                                
                                {/* done button end */}
                                
                            </div>
                            
                        </div>



                    </Row>

                </Container>

            </div>
        
        </div>
        
        
        </>
    );

}
import React,{useState,useEffect} from 'react';
import ReactDOM from "react-dom";
import { getfaq } from "../../actions/users";

import Sidebar from './sidbar'
import {Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
// import 'bootstrap/dist/css/bootstrap.min.css';

import './faq.css'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

export default function Faq() {
    const [faq, setfaq] = useState([]);

    const getUserListdata = async () => {
        var test = await getfaq();
        console.log(test,"ghhgj")
        
        setfaq(test.faq);
       

    }

    useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);

    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])

    return (

        <>
 
            
            <div className='allbody'>
                <Container>
                    
                    {/* <div style={{ marginBottom: "15px" }}>
                        <Link to="/" ><Button variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button></Link>
                    </div> */}

                <Row>

                    <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                    <Sidebar/>
                    </div>


                    
                    <div className='col-lg-8'>
                        <div className='faq'>
                        <h2 className='faq-heading'>FAQ</h2>
                        <div className='faq-accordian'>

                            <Accordion defaultActiveKey="0">
                            {faq.map((faq,index)=>
                                <Accordion.Item eventKey={index+1}>
                                    <Accordion.Header className='accor-heading'><div dangerouslySetInnerHTML={{ __html: faq.question }} /></Accordion.Header>
                                    <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                    </Accordion.Body>
                                </Accordion.Item>)}
                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header className='accor-heading'>What is the process of online learning?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                        velit esse cillum dolore eu fugiat nulla pariatur.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className='accor-heading'>what is the Quality of Marvel Classes?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua.Duis aute irure dolor in reprehenderit in voluptate
                                        velit esse cillum dolore eu fugiat nulla pariatur.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className='accor-heading'>What are details that are mentioned in the NEET exam pattern?</Accordion.Header>
                                    <Accordion.Body>
                                        Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                        velit esse cillum dolore eu fugiat nulla pariatur.
                                    </Accordion.Body>
                                </Accordion.Item> */}
                            </Accordion>

                        </div>



             </div>

                    </div>





                </Row>

            </Container>

        </div>
        
        </>

  
          );
}
import React from 'react';
import './completed.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';

import { useHistory, useParams, Link } from "react-router-dom";
import Moment from 'react-moment';
export default function Completed({schedules = []}) {



    return (
        <>
            <div >

                <Container>
                    <Row>
                        <Col md={6}>
                            {schedules.length ? schedules.map(e=><div className="shadow bg-white p-3 m-t">

                                <div className="heading-discription kiii">
                                    {/* <span className="footerSocialLink1"> </span> */}
                                    <ul>
                                        <li>
                                            <div className='roundshape'>
                                                <h6>Date</h6>
                                                <p>
                                                <Moment format="D MMM" withTitle>
                                                    {e.start_time}
                                                </Moment></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <h4 className="m-0">{e.topic}</h4>
                                                <p className='ti-man'>Time :  <Moment format="hh:mm">
                                                    {e.start_time}
                                                </Moment></p>
                                                <p className='com-color'>completed</p>
                                            </div>
                                        </li>

                                    </ul>


                                </div>



                            </div>):<div> <img width="200" height="150" src={require('../../img/no_schedule.jpg')} /></div>}

                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                </Container>

            </div>


        </>


    );
}
import React,{useState,useEffect} from 'react';
import './testresult.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';

import Performance from '../../Components/Performance/performance';
import Solution from '../../Components/Solution/solution';

import { FiClock } from 'react-icons/fi';
import { BsCheckCircle } from 'react-icons/bs';
import { AiOutlineMinusCircle } from 'react-icons/ai'; 
import { BsFillStarFill } from 'react-icons/bs';
import { submitanswer  } from "./../../actions/users";
import { testtopic  } from "./../../actions/users";
import { useNavigate, useParams } from "react-router-dom";

export default function Testresult() {
    const { id } = useParams();
    const [questionanswered, setquestionanswered] = useState(0);
    const [reviewanswer, setreviewanswer] = useState(0);
    const [notvisited, setnotvisited] = useState(0);
    const [notquestionanswered, setnotquestionanswered] = useState(0);
    const [testtopics, settesttopic] = useState([]);

    const fetchSubmitpart = async() =>{
        var test1 = await submitanswer();
        console.log(test1,"bb")
        
        setquestionanswered(test1.answers);


        setnotquestionanswered(test1.notanswers);

        setnotvisited(test1.notvisited);

        setreviewanswer(test1.reviewanswer);
        var test = await testtopic(id);
        settesttopic(test.testtopic);
    }
    useEffect(()=>{
        fetchSubmitpart();
    },[])

    return (
        <>
            <div className='allbody'>
            <div className='testresults'>
        
                <Container>
                    
                    <div id="exTab1" >
                        <ul className="nav nav-pills ssss">
                            <li className="active">
                                <a href="#1a" data-toggle="tab"> Performance </a>
                            </li>
                            <li><a href="#2a" data-toggle="tab"> Solution  </a>
                            </li>

                        </ul>

                        <div className="tab-content clearfix">
                            <div className="tab-pane active" id="1a">
                            <div className='submitprocess'>
                           
                                                <div>
                                                <Performance  topics={testtopics} />
                                                  
                                                </div>
        
                                               
                                            </div>
                                {/* <Performance /> */}
                            </div>
                            <div className="tab-pane" id="2a">
                                <Solution  topics={testtopics} id={id} />
                            </div>

                        </div>
                    </div>

</Container>


            </div>
        </div>

        </>

    );
}
import React from 'react';
import './teacherprofile.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import Teacherfollowers from '../../Components/Teacherfollowers/teacherfollowers'
import Teacherfollowing from '../../Components/Teacherfollowing/teacherfollowing'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AiOutlineHeart } from 'react-icons/ai';
import { BsFillPersonPlusFill } from 'react-icons/bs';

export default function Teacherprofile() {

    const img = {
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }


        },

        ]
    };

    return (

        <>
        
            <div className='allbody'>
            <div className='teacherprofile'>

                <Container>

                    <div className="col-lg-3 col-md-6 col-sm-6  col_profile__sidebar d-block">
                        <div className="profile__sidebar card">
                            <div className="profile__header">

                                <div className="profile__img">

                                    <img src={require('../../img/professor1.jpg')} className="img-fluid profile___shap" alt=""/>


                                </div>
                                <div className="text-center">
                                    <h4 className="profile__name"><a href="#" style={{ textDecoration:"none" }}>Narayanan</a></h4>
                                    <p className="profile__mail"> narayanan@gmail.com</p>
                                </div>

                            </div>

                            {/* <div class="profile__body">
                                <div class="list-group p-3">
                                  
                                    <a  href="#" class="u_following list-group-item list-group-item-action"> <i class="far fa-heart">  </i> <span> Following </span> <span >0</span></a>
                                    <a  href="#" class="u_follower list-group-item list-group-item-action"> <i class="far fas fa-user-plus"> </i> <span> Followers </span> <span>145</span></a>

                                </div>
                            </div> */}




                        </div>

                    </div>


                    
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="post-item write_box_your">
                            <span className='brief'>
                                <i class="fa fa-briefcase" aria-hidden="true"></i>
                            </span>
                            <div className="active-since">
                                <p style={{ fontSize:"15px", fontWeight:"600"}}>Active Since</p>
                                <p>17-Mar-2020</p>
                            </div>


                        </div>


                        <div id="exTab1" >
                            <ul className="nav nav-pills ssss">
                                <li className="active">
                                    <a href="#1a" data-toggle="tab"><AiOutlineHeart /> Following</a>
                                </li>
                                <li><a href="#2a" data-toggle="tab"><BsFillPersonPlusFill/> Followers</a>
                                </li>
                               
                            </ul>

                            <div className="tab-content clearfix">
                                <div className="tab-pane active" id="1a">
                                    <Teacherfollowers />
                                </div>
                                <div className="tab-pane" id="2a">
                                    <Teacherfollowing />
                                </div>
                                
                            </div>
                        </div>



                    </div>



                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='experts'>
                            <div className="card mb-4">
                                <div className="card-header content-center ">
                                    <h6 className="mb-0 ourexpert"> Our Experts </h6> <a href="/ourexpert" className="btn btn-primary"> View All </a>
                                </div>
                                <div className="card-body">
                                    <ul className="list-group list-group-flush">

                                        <li className="list-group-item px-0 content-center">

                                            <div className="d-flex align-items-start">
                                                <a className="author__img" href="#">
                                                    <img src={require('../../img/professor1.jpg')} className="img-fluid" alt="" />

                                                </a>
                                                <div className="author-date">
                                                    <a href="/userprofile" className="author_name">Narayanan</a>
                                                    <div className="post__date">97 Followers</div>
                                                </div>

                                            </div>
                                            <span>
                                                <a className="btn Following-btn" >Follow</a>
                                            </span>

                                        </li>
                                        <li className="list-group-item px-0 content-center">

                                            <div className="d-flex align-items-start">
                                                <a className="author__img mb-0" href="#">
                                                    <img src={require('../../img/professor3.jpeg')} className="img-fluid" alt="" />

                                                </a>
                                                <div className="author-date">
                                                    <a href="#" className="author_name">Vidya</a>
                                                    <div className="post__date">200 Followers</div>
                                                </div>

                                            </div>
                                            <span >
                                                <a className="btn Following-btn" >Follow</a>
                                            </span>

                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div classNameName='biii'>
                                <div className="card-body">

                                    <Slider {...img} >
                                        <div>
                                            <div className="advertisement">
                                                <h6 className="widget-title">NEET / JEE Mock Test Series</h6>
                                                <img src={require('../../img/study2.jpeg')} className="img-fluid" alt="banner" />
                                                <p className="bannerdescription">Boost your preparation with free online mock test series</p>

                                                <a href="/buycourse" target="_blank" className="btn readmore">Read More</a>

                                            </div>
                                        </div>

                                        <div>
                                            <div className="advertisement">
                                                <h6 className="widget-title">NEET / JEE Mock Test Series</h6>
                                                <img src={require('../../img/study2.jpeg')} className="img-fluid" alt="banner" />
                                                <p className="bannerdescription">Boost your preparation with free online mock test series</p>

                                                <a href="/buycourse" target="_blank" className="btn readmore">Read More</a>

                                            </div>
                                        </div>

                                        <div>
                                            <div className="advertisement">
                                                <h6 className="widget-title">NEET / JEE Mock Test Series</h6>
                                                <img src={require('../../img/study1.jpg')} className="img-fluid" alt="banner" />
                                                <p className="bannerdescription">Boost your preparation with free online mock test series</p>

                                                <a href="/buycourse" target="_blank" className="btn readmore">Read More</a>

                                            </div>
                                        </div>


                                        <div>
                                            <div className="advertisement">
                                                <h6 className="widget-title">NEET / JEE Mock Test Series</h6>
                                                <img src={require('../../img/study1.jpg')} className="img-fluid" alt="banner" />
                                                <p className="bannerdescription">Boost your preparation with free online mock test series</p>

                                                <a href="/buycourse" target="_blank" className="btn readmore">Read More</a>

                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>

                        </div>
                    </div> 






                  

                </Container>

            </div>
</div>
        </>


    );
}
import React,{useState,useEffect} from 'react';
import './freestuff.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { freestufflist } from '../../actions/users';
import config from '../../lib/config'
import { useNavigate, useParams,Link } from "react-router-dom";
export default function Freestuff() {
    const[courselist,setcourselist]=useState([]);


    const userData =async()=>{
     const apicourselist = await freestufflist();
     if("error" in apicourselist){
        alert("Internal server error");
     }else{

     setcourselist(apicourselist.userValue)
     }

    }

    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        userData();
    },[])
    return (
        <>
            <div className='allbody'>

         {/* filter free stuff */}
                {/* <div>
                <Container>
                <div class="form-group content-left">
                    <label for="">  <strong> Filter By:  </strong> </label>
                    <select class="form-select" name="main_cat" id="main_cat" aria-label="Default select example">


                        <option selected="" value="1">Engineering</option>

                        <option value="2">Medical</option>

                        <option value="3">CBSE</option>
                                
                                <option value="4">Foundation</option>
                                
                                <option value="5">TN Board</option>

                       

                        
                    </select>
                    </div>
                    </Container>
                </div> */}

                {/* learning card */}
                <div>
                    <Container>
                        <Row>
                        { courselist.length && courselist.map(e =>
                            <Col key={e._id} md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={`${config.API}/images/user/${e.image}`} />
                                    <Card.Body style={{ padding:"8px 8px" }}>
                                        <Card.Title className='filter-title'>{e.title.slice(0,27)}</Card.Title>
                                        {/* <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text> */}
                                        <div style={{ textAlign:"center" }}>
                                        <Link to={'/freelearning/'+e._id}><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}


                        </Row>
                  </Container>

                    
               </div>
               




        </div>
        
        </>


          );
}
import React,{useState,useEffect} from 'react';
import './mywallet.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { agentwallet,agentwalletcoin,agenttransactions,getagentdetails,updateagentdetails } from '../../actions/users';
import Moment from 'react-moment';
import { useHistory, useParams,Link,useNavigate, } from "react-router-dom";
import swal from "sweetalert";


const initialFormValue = {
    name: "",
    email:"",
    phonenumber:"",
    bankname:"",
    accountnumber:"",
    ifsccode:""

    
    
  };





  


// import Sidebar from './sidbar';
export default function Mywallet() {
    const[wallet,setwallet]=useState(0)
    const[info,setinfo]=useState({})
    const[walletvalue,setwalletvalue]=useState(0)
    const[transactions,settransactions]=useState([])
    const[reflected,setreflected]=useState(0)
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const { id } = useParams();
    const history = useNavigate();

    document.addEventListener(
        "click",
        function (event) {
            var target = event.target;
            var replyForm;
            if (target.matches("[data-toggle='reply-form']")) {
                replyForm = document.getElementById(target.getAttribute("data-target"));
                replyForm.classList.toggle("d-none");
            }
        },
        false
    );
    const onChange1 = (e) => {
        e.preventDefault();
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        console.log(formValue);
        setreflected(1)
        //setValidateError(formData)
      };

    const {
        name,
        email,
        phonenumber,
        bankname,
        accountnumber,
        ifsccode,
        
      } = formValue;
    
    
      const handleFormSubmit = async () => {
        console.log("saran");
        
        console.log(formValue);
        let reqData = {
            name,
            email,
        phonenumber,
        bankname,
        accountnumber,
        ifsccode,
        
           
        };
        console.log(reqData.dob,"ssshhh")
        // let result = await additionaldetails(reqData);
       
        // if (result) {
        //     history("/my-profile-edit");
        //     swal("Profile updated successfully");
        //   } else {
        //     swal("Please try again");
        //   }
    
        let { error } = await updateagentdetails(reqData);
        console.log("error", error);
        if(reflected==0){
            swal("No changes made ");
        }
       else if (!error) {
        //   history("/");
          swal("Profile updated successfully");
          window.location.reload();
         
        } 
       
    
        
        else {
          setValidateError(error);
        }
    
       
      };

    const getUserListdata = async () => {
        var test = await getagentdetails();
    console.log(test,"1234")
    let data = {};
 
    data["name"] = test.detail.name;
    data["email"] = test.detail.email;
    data["phonenumber"] = test.detail.phonenumber;
    data["accountnumber"]=test.detail.accountnumber;
    data["bankname"]=test.detail.bankname;
    data["ifsccode"]=test.detail.ifsccode;
   
  


    
  
    // formdata["Photofile"] = test.userValue.image;
    setFormValue(data);


  
    
    
    
  
    // formdata["Photofile"] = test.userValue.image;
    
        
    }

    const userData =async()=>{
        const userd = await agentwallet();
        console.log(userd,"sssaaa")
        if("error" in userd){
            alert("Internal server error");
         }else{
    
            setwallet(userd.userWallet)
            setinfo(userd.user)
            // settransactions(userd.userTransaction)
         }

         const transactions = await agenttransactions();
         
        
     
             settransactions(transactions.userValue)
            
          
        
       }

       function datealignment(startdate){
   
        var now = new Date(startdate);
    
    var startdate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear() + " " ;
    
       
          
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return startdate;
    }


       

       
   
       useEffect(()=>{
           userData();
           getUserListdata();
           window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
       },[])
    return (

        <>
            
            <div className='allbody'>
            <div className='judge'>

                <Container>
                    <Row>

                        {/* <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div> */}
                         


                        <div className="col-md-6">
                            <div className='wallet'>
                                <div className="shadow card p-4">
                                    <div className="my-balance">
                                     
                                        <Link to={"/userviaagent/"+info._id} className="studentvia">   Student Registration Via Agent</Link>
                                    <h5 className='basicinfo dfg'>Share the above agent link of your's with students and earn a commission on every purchase of that student</h5>
                                    <h1 className='my_balancee'>My Balance</h1>
                                    {/* <p>Total Earning<span><i class="fa fa-rupee-sign"></i><span id="total_bal">{wallet.totalearnings}</span></span>
                                    </p> */}
                                    <p>Total Wallet Balance<span><i class="fa fa-rupee-sign"></i><span id="total_ern">{wallet}</span></span>
                                    </p>
                                </div>
                                <div className="my-balance">
                                    {/* <h3>My Coins</h3>
                                    <p>My Coin Balance<span><span id="coin_bal">{1}</span></span>
                                    </p>
                                    <p>Total Earning Coins<span><span id="reward_pts">{wallet.totalcoinsearning}</span></span>
                                    </p> */}
                                    <div className="col-md-12 text-right">
                                    {transactions.length?     <button className="btn  float-right" data-toggle="reply-form" data-target="comment-24-reply-form">View Transaction</button>:null}
                                          
                                    </div>

                                    <form method="POST" class="comment-form inline-items reply-form d-none" id="comment-24-reply-form">
                                        <div className=" card-body">
                                            <div>
                                                <ul>


                                                    {transactions.map(trans =>(
                                                        <li className="list-group-item content-center transaction__list" style={{ alignItems:"center" }}>
                                                        <div className="transaction__list_cont">
                                                            <h5>{trans.comments} </h5>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> {datealignment(trans.createdAt)}</p>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> <Moment toNow>{trans.createdAt}</Moment></p>
                                                            <p id="load_rward_button"></p>
                                                        </div>
                                                        <div className="follow_no">
                                                            <p>₹ {trans.amount}</p>
                                                            <div><span style={{ fontWeight:"500", color:"black" }}>Payment Status :</span> <span style={{ fontWeight:"500", color:"black" }}>{trans.paystatus}</span> </div>
                                                            </div>
  
                                                    </li>


                                                    )

                                                    )}
                                                    
                                                 

                                                   
                                                </ul>

                                              
                                              
                                            </div>
                                            </div>
                                        
                                         </form>

                                </div>

                                {/* <div class="collapse" id="transaction">
                                    <div class=" card-body">
                                        <div class="notification__list" id="txn_data">











                                        </div>
                                    </div>
                                </div> */}

                            </div>





                            {/* <div class="calculation card mt-4">
                                <div class="calculation-method row text-center p-4">
                                    <div class="col-6">
                                        <p class="conversion">Conversion Calculation</p>
                                        <p class=" mb-0" id="conversion_calc">1 coins = <i class="fa fa-rupee-sign"></i>1</p>
                                    </div>
                                    <div class="col-6">
                                        <p class="conversion">Conversion Eligibility</p>
                                        <p class=" mb-0" id="conversion_eleg">On Earning 1 Coins</p>
                                    </div>
                                </div>
                                </div> */}
                            </div>
                        </div>


                        <div className="col-md-6">
                        <div className='wallet agentdetails'>
                                <div className="shadow card p-4">

<h1>Agent Details</h1>

<form >

<div class="row">
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput"> Name <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="previous_name"    id="name"  onChange={onChange1}  value={name}  placeholder="Name" />
                                                {validateError.name && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.name}
                                                          </span>
                                                        )}
                                                
                                              
                                            </div>
                                            
                                           
                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            {/* <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Email</label>
                                                <input type="text" class="form-control" name="email" id="email"  onChange={onChange1}  value={email}   placeholder="name@example.com" />
                                                
                                            </div> */}
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Mobile <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="mobile" id="phonenumber"  onChange={onChange1}  value={phonenumber}  placeholder="Mobile" />
                                                {validateError.phonenumber && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.phonenumber}
                                                          </span>
                                                        )}
                                               
                                            </div>
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput"> Bank Name <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="previous_name"    id="bankname"  onChange={onChange1}  value={bankname}  placeholder="Bank Name" />
                                                {validateError.bankname && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.bankname}
                                                          </span>
                                                        )}
                                              
                                            </div>
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput"> Bank Account Number <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="previous_name"    id="accountnumber"  onChange={onChange1}  value={accountnumber}  placeholder="Bank Account Number" />
                                                {validateError.accountnumber && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.accountnumber}
                                                          </span>
                                                        )}
                                              
                                            </div>
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput"> IFSC Number <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="previous_name"    id="ifsccode"  onChange={onChange1}  value={ifsccode}  placeholder="IFSC Number" />
                                                
                                              
                                            </div>
                                        </div>
                                        
                                        <div style={{ textAlign:"center" }}>
                                        <button type="button" class="btn btn-primary" onClick={handleFormSubmit}> Submit </button>
                                        </div>
                                        </form>

                                </div>
 </div>
                        </div>





                    </Row>

                </Container>



            </div>
</div>
        </>


    );
}
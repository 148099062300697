import React, { useState, useEffect } from "react";
import './forget.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import isEmpty from "../../lib/isEmpty";
import { agentlogin } from "../../actions/users";
import { useNavigate, useParams, Link } from "react-router-dom";
import swal from "sweetalert";

const initialFormValue = {   
    email: "",
    password:"",    
  };
export default function Forget() {
    const [formValue, setFormValue] = useState(initialFormValue);
    const history = useNavigate();
    const [validateError, setValidateError] = useState({});


    

    const onChange = (e) => {
        e.preventDefault();
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        console.log(formValue);
    
        
        //setValidateError(formData)
      };


      const {
      
      email,
      password,
      
    
      } = formValue;



      const handleFormSubmit = async () => {
        console.log("saran");
        
        console.log(formValue);
        let reqData = {
           
            email,
            password
           
        };
        console.log(reqData,"sss")
        // let result = await forgotPassword(reqData);

        // if (result) {
        //     history("/");
        //     swal("Password reset link has been sent to your mail");
        //   } else {
        //     swal("Please try again");
        //   }
        let { error } = await agentlogin(reqData);
        console.log("error", error);
        if (!error) {
          history("/agentdashboard");
          // swal("Password reset link has been sent to your mail");
         
        } 
       else {
          setValidateError(error);
        }
      
       
        
       
      };
    
      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
},[])


    return (
        <>

         <div className='allbody'>

          <div className='agent-signin'>

<Container>
 
 <div className="trip">
 <div className="forgetzzz">
    
    <h5 className="pass">Referrer Sign-in</h5>
    <form id="sbform" method="post">
                                        <div class="row">
                                           
                                           
                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Email <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="email" id="email" onChange={onChange} value={email}  />
                                                {validateError.email && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.email}
                                                          </span>
                                                        )}
                                                
                                            </div>
                                          
                                        </div>

                                        <div class="row">
                                           
                                           
                                           <input type="hidden" name="user_id" id="user_id" value="105918" />
                                           <div class="form-floating col-md-6 mb-3">
                                               <label for="floatingInput">Password <span style={{ color:"red" }}> *</span></label>
                                               <input type="password" class="form-control" name="password" id="password" onChange={onChange} value={password}  />
                                               {validateError.password && (
                                                         <span style={{color:"red",fontSize:"14px"}}>
                                                           {validateError.password}
                                                         </span>
                                                       )}
                                               
                                           </div>
                                         
                                       </div>


                                    

                                       




                                        <button type="button" class="btn btn-primary mb-3  mt-3 m-auto" id="save_profile" onClick={handleFormSubmit}> Login </button>
                                        <Link to="/forgetpassword" style={{ marginLeft:"10px" }}>
                <Button className="btn btn-primary mb-3  mt-3 m-auto">
                Forget password
                </Button>
                </Link>
                                        

                                    </form>

  </div>
  </div>

</Container>

</div>
         </div>



</>

);
}
import React from 'react';
import './neet.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'



export default function Freeresource() {



    return (
           <>

            <div className='allbody'>
         
                <div>
                  <Container> 
                        <h4>Free Resources / Foundation</h4>
                        <Row>
                        <Col md={3}>
                            <Card className='filter-course'>
                                <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter1.png')} />
                                <Card.Body>
                                    <Card.Title className='filter-title'>Physics for NEET 2022-23</Card.Title>
                                    <Card.Text className='filter-para'>
                                        For NEET 2022-23 Aspirants
                                    </Card.Text>
                                    <div style={{ textAlign: "center" }}>
                                        <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                    </div>
                                </Card.Body>
                            </Card>
                            </Col>
                            
                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter2.png')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>Biology for NEET 2022-23</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            
                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter6.png')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>Chemistry Revision Package for NEET 2022</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter2.png')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>Physics Revision Package for NEET 2022</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter2.png')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>60 Days Crash Course for NEET 2022</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter3.png')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>Chemistry for NEET 2022-23</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter4.png')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>Chemistry for NEET 2022-23</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter5.jpg')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>Chemistry for NEET 2022-23</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                          

                            <Col md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={require('../../img/filter2.png')} />
                                    <Card.Body>
                                        <Card.Title className='filter-title'>Chemistry for NEET 2022-23</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign: "center" }}>
                                            <a href='/freelearning'><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container> 
                </div>


            </div>
        </>

    );
}
import React, { useState, useEffect } from "react";
import './correct.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';



export default function Correct({questions=[],corresctquscount}) {

    // const [quscount, setquscount] = useState(0);
    let quscount=0;
    const showanswer =(element) =>{
        if(element.answers.length){
            if(element.answers[0].evaluation==1){
                quscount=quscount+1;
                corresctquscount(quscount);
       return ( <div className='qanda'>
                    <div className='beans'>
                    <div>
                   <h5>   <span className='q1s'>Q {element.questionnumber} : </span> <span className='qno'>{element.question}</span> </h5>
                        {/* <p className="que_bold">{element.question}</p> */}
                    </div>
                    <div>
                    <h5></h5>
                   <p className="jerk"><span className='q1s'>Ans :</span> {element.solutions}</p>
                    </div>
                   

                    <div className='right'>
                        <ul>
                            <li>Your Answer : {element.answers[0].answer} </li>
                            <li> CORRECT || Question Type: Single choice </li>
                        </ul>
                        </div>
                        </div>
                </div>)
            }
        }
    }
    let correctqus = ()=>{
        return questions.map(element=>showanswer(element))
    }

 

    return (

        <>
        
            
        <div className='allcorrections'>

          
                {questions.length ? questions.map(element=>showanswer(element)):<h1>No questions in correct answer</h1>}

                {/* <div className='qanda'>
                    <div className='beans'>
                    <div>
                        <h5>Q.2</h5>
                            <p>A body is executing simple harmonic motion with frequency ' n ', the frequency of its potential energy is</p>
                    </div>

                    <div className='right'>
                        <ul>
                            <li>Your Answer c</li>
                            <li> CORRECT|| On Screen: 00:08|| Question Type: Single choice </li>
                        </ul>
                        </div>
                        </div>
                </div>


                <div className='qanda'>

                    <div className='beans'>
                    <div>
                        <h5>Q.3</h5>
                            <p>A particle is released from height S from the surface of the Earth. At a certain height its kinetic energy is three times its potential energy. The height from the surface of earth and the speed of the particle at that instant are respectively</p>
                    </div>

                    <div className='right'>
                        <ul>
                            <li>Your Answer b</li>
                            <li> CORRECT|| On Screen: 00:02|| Question Type: Single choice </li>
                        </ul>
                        </div>
                    </div>

                </div>



                <div className='qanda'>
                    <div className='beans'>
                        <div>
                            <h5>Q.4</h5>
                            <p>A body is executing simple harmonic motion with frequency ' n ', the frequency of its potential energy is</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer c</li>
                                <li> CORRECT|| On Screen: 00:08|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>
                </div>

                
                <div className='qanda'>

                    <div className='beans'>
                        <div>
                            <h5>Q.5</h5>
                            <p>A particle is released from height S from the surface of the Earth. At a certain height its kinetic energy is three times its potential energy. The height from the surface of earth and the speed of the particle at that instant are respectively</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer b</li>
                                <li> CORRECT|| On Screen: 00:02|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>

                </div> */}





                
                                </div>
                                
                                
        
        
        
        
        
        
        </>

    );


}
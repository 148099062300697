import React, { useState, useEffect } from "react";
import './ourexperts.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { getteacher } from "../../actions/users";
import config from "../../lib/config";

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

export default function Ourexpert() {
    const [teacher, setteacher] = useState([]);
    const getUserListdata = async () => {
        var test = await getteacher();
        console.log(test,"ghhgj")
        
        setteacher(test.teacherlist);
      

    }

    useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);




    return (

        <>
            <div className='allbody'>
                <div className='our-expert'>
                    <Container>

                        <div style={{ marginBottom: "15px" }}>
                            <a href="/feed" ><Button variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button></a>
                        </div>

                        <h2 className='expert-h'>Our Experts</h2>
                    </Container>

                    <Container>
                        
                      
                   
                    <Row>

                    {teacher.map(teacher =>
                        <Col lg={3} md={4} sm={6}>
                           <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={config.API +"/images/user/"+teacher.image} className="img-fluid faculty__member_shap" alt="author"/>
                                </div>
                                <div className="card-body text-center">
                                    <h4>{teacher.name}</h4>
                                    {/* <p className="faculty_followers">97 Followers</p> */}
                                    <div className="faculty__member_view text-center">
                                        {/* <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span> */}

                                    </div>
                                </div>
                            </div>
                           
                        </Col>)}
                        
                        {/* <Col lg={3} md={4} sm={6}>
                            <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={require('../../img/professor2.jpg')} className="img-fluid faculty__member_shap" alt="author" />
                                </div>
                                <div className="card-body text-center">
                                    <h4><a href="#">Scarlett</a></h4>
                                    <p className="faculty_followers">111 Followers</p>
                                    <div className="faculty__member_view text-center">
                                        <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg={3} md={4} sm={6}>
                            <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={require('../../img/professor3.jpeg')} className="img-fluid faculty__member_shap" alt="author" />
                                </div>
                                <div className="card-body text-center">
                                    <h4><a href="#">Vidya</a></h4>
                                    <p className="faculty_followers">56 Followers</p>
                                    <div className="faculty__member_view text-center">
                                        <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg={3} md={4} sm={6}>
                            <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={require('../../img/professor4.jpg')} className="img-fluid faculty__member_shap" alt="author" />
                                </div>
                                <div className="card-body text-center">
                                    <h4><a href="#">Vidya</a></h4>
                                    <p className="faculty_followers">201 Followers</p>
                                    <div className="faculty__member_view text-center">
                                        <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg={3} md={4} sm={6}>
                            <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={require('../../img/professor6.jpg')} className="img-fluid faculty__member_shap" alt="author" />
                                </div>
                                <div className="card-body text-center">
                                    <h4><a href="#">Varsha</a></h4>
                                    <p className="faculty_followers">189 Followers</p>
                                    <div className="faculty__member_view text-center">
                                        <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col lg={3} md={4} sm={6}>
                            <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={require('../../img/professor7.jpg')} className="img-fluid faculty__member_shap" alt="author" />
                                </div>
                                <div className="card-body text-center">
                                    <h4><a href="#">Benny</a></h4>
                                    <p className="faculty_followers">300 Followers</p>
                                    <div className="faculty__member_view text-center">
                                        <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg={3} md={4} sm={6}>
                            <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={require('../../img/professor1.jpg')} className="img-fluid faculty__member_shap" alt="author" />
                                </div>
                                <div className="card-body text-center">
                                    <h4><a href="#">Narayanan</a></h4>
                                    <p className="faculty_followers">97 Followers</p>
                                    <div className="faculty__member_view text-center">
                                        <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg={3} md={4} sm={6}>
                            <div className="faculty__member_col card">
                                <div className="faculty__member_img">
                                    <img src={require('../../img/professor6.jpg')} className="img-fluid faculty__member_shap" alt="author" />
                                </div>
                                <div className="card-body text-center">
                                    <h4><a href="#">Varsha</a></h4>
                                    <p className="faculty_followers">189 Followers</p>
                                    <div className="faculty__member_view text-center">
                                        <span id="followunfollow_1093">
                                            <a value="16" className="btn m-auto read">Follow</a>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </Col>

                       */}

                    </Row>

               </Container>
        
            </div>
            </div>
        </>

    );
}
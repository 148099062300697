import React, { useState, useEffect } from "react";
import config from "../../lib/config";
import './feed.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { getteacher } from "../../actions/users";
import { getcomments } from "../../actions/users";
import { getcourse } from "../../actions/users";
import { getdetails } from "../../actions/users";
import { getfeed } from "../../actions/users";

import { BsThreeDotsVertical } from 'react-icons/bs';
import { BsFillEraserFill } from 'react-icons/bs';
import { BiHide } from 'react-icons/bi';
import { BsQuestionCircle } from 'react-icons/bs';

import { BsFillReplyFill } from 'react-icons/bs';

import { MdDelete } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';

import { FaComments } from 'react-icons/fa';
import { postcomment } from "../../actions/users";
import { useNavigate, useParams } from "react-router-dom";

import ReactPlayer from 'react-player'
import swal from "sweetalert";

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from 'react-moment';

const initialFormValue = {
    comment: "",
    name:"",
    title:"",
    link:"",
    profileimage:"",
   
    
  };

  document.addEventListener(
    "click",
    function (event) {
        var target = event.target;
        var replyForm;
        if (target.matches("[data-toggle='reply-form']")) {
            replyForm = document.getElementById(target.getAttribute("data-target"));
            replyForm.classList.toggle("d-none");
        }
    },
    false
);

function handleClick(){
    const textarea = document.getElementById('comment');
    textarea.value = '';
}


function datealignment(startdate){
   
    var now = new Date(startdate);

var startdate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear() + " " ;

   
      
// Regular expression to identify HTML tags in 
// the input string. Replacing the identified 
// HTML tag with a null string.
return startdate;
}

export default function Feed() {


    const [formValue, setFormValue] = useState(initialFormValue);
    const [lastteacher, setlastteacher] = useState({});
    const [lastbeforeteacher, setlastbeforeteacher] = useState({});
    const [lastbeforecourse, setlastbeforecourse] = useState({});
    const [lastcourse, setlastcourse] = useState({});
    const [feed, setfeed] = useState([]);
    const [comments, setcomments] = useState([]);
    const [value, setvalue] = useState(0);
 
    const [detail, setdetail] = useState({});
    const history = useNavigate();
    
    const getUserListdata = async () => {
        var test = await getteacher();
        console.log(test,"ghhgj")
        
        setlastteacher(test.lastteacher);

        var test1 = await getteacher();
        console.log(test,"ghhgj")
        
        setlastbeforeteacher(test1.lastbeforeteacher);

        var test2 = await getcourse();
        console.log(test,"ghhgj")
        
        setlastcourse(test2.lastcourse);

        var test3 = await getcourse();
        console.log(test,"ghhgj")
        
        setlastbeforecourse(test2.lastbeforecourse);

        // var test2 = await getcomments();
        // console.log(test,"comments")
        
        // setcomments(test2.comments);

        if(localStorage.user_token){

        var test = await getdetails();
        console.log(test,"1234")
        let data = {};
     
        data["name"] = test.detail.name;
        data["profileimage"] = test.detail.profileimage;
       
    
        
      
        // formdata["Photofile"] = test.userValue.image;
        setFormValue(data);

        }


        var test3 = await getfeed();
        console.log(test,"ghhgj")
        
        setfeed(test3.feed);
      
      

    }
    const onChange = (e) => {
        e.preventDefault();
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        console.log(formValue);
        //setValidateError(formData)
      };

      const particularcomments =async (id,index) => {
      
        setvalue(index)
        var test2 = await getcomments(id);
        console.log(test2,"comments")
        
        setcomments(test2.comments);
       
  
       
        //setValidateError(formData)
      };

    //   const commentfunction = (id) => {
       
    //     setindividualcomments(id);
       
    //   };


    const {
        comment,
        name,
        title,
        link,
        profileimage
       
      } = formValue;

    const handleFormSubmit = async (id) => {
        console.log(feed._id,"saran");
        
        console.log(formValue);
        let reqData = {
            comment,
            name,
            title,
            link,
            profileimage,
            id
           
        };
        let result = await postcomment(reqData);
       
        if (result&&localStorage.user_token){
            history("/feed");
            window.location.reload();
            swal("Your comment has been posted successfully");
          } else {
            swal("login to post a comment");
          }
       
      };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        // or simply just AOS.init();
       
        getUserListdata();
        particularcomments();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);

    


    const img = {
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }


        },

        ]
    };

    return (

         <>
        
            <div className='allbody'>
            <div className='noted tom'>


                <Container>
                    <Row>

                     <div className='col-lg-8'>
{/* 
                            <div class="form-group content-left">
                                <label for="">  <strong> Filter By:  </strong> </label>
                                <select id="feed" class="form-select">
                                    <option value="">All</option>
                                    <option value="2">Quiz</option>
                                    <option value="1">MCQ's</option>
                                    <option value="3">Videos</option>
                                    <option value="4">Articles</option>
                                    <option value="5">Concepts</option>
                                    <option value="7">Questions</option>
                                </select>
                            </div> */}

                            <div class="notification__list card" style={{ borderRadius:"20px" }}>

                                <ul class="list-group ">
                                    <li class="list-group-item content-center">
                                        <a href="#" class="notification-event content-center">
                                            <div class="author-thumb">
                                                <img src={config.API + "/images/user/" + 'IMAGE-1667630329349.png'} alt="" />
                                            </div>
                                            <div class="notification-event-content">
                                                <h5> <span class="hnotification-friend">Welcome to our Marvel Feeds</span>
                                                </h5>
                                                <p></p>
                                            </div>
                                        </a>
                                        {/* <span id="followunfollow_1093">
                                            <div class="dropdown dropdown__right">

                                                <label class="exp_men pr-2" for=""><strong>
                                                    Expert
                                                </strong>  </label>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-light btn-sm rounded threedots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <BsThreeDotsVertical /> </button>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <button class="dropdown-item" type="button"><BsFillEraserFill /> Save to my notes</button>
                                                        <button class="dropdown-item" type="button"><BiHide /> Hide Post</button>
                                                        <button class="dropdown-item" type="button"><BsQuestionCircle /> Ask a Query</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </span> */}

                                    </li>
                                </ul>



                                
                                {feed.map((feed,index) =><div>


                                <p style={{ padding: "8px 16px",fontWeight:"600", color: "black" }}>{feed.title}</p>
                                
                                <div class="post-thumb postHeight post1">
                                    <a href="#">
                                            <ReactPlayer url={feed.link}className='neetvideo' />
                                    </a>

                                    <div style={{ padding: "8px 16px" }}>
                                    <button type="button" className='ddd' data-toggle="reply-form" data-target={feed._id}   onClick={()=>particularcomments(feed._id,index)} ><FaComments /> Comments</button>
                                </div>
                                {index == value  &&
                                <form method="POST" class="comment-form inline-items reply-form d-none" id={feed._id}>
                                    <div class="card card-body">
                                        <div class="load_comment82104">
                                            <div class="comments__box ">
                                                <div class="comment-temp">
                                                    <ul class="nav">

                                                        <li>   {comments&&comments.map(comments =>
                                                            <div class="comment-item mb-30">
                                                                <div class="comt-user">
                                                                <a>
                                                                        {comments.image?
                                                                        <img src={config.API + "/images/user/" + comments.image} alt="" />: <img src={config.API + "/images/user/" + 'IMAGE-1674666998165.png'} alt="" />}
                                                                    </a>
                                                                </div>
                                                             
                                                                <div class="comt-detail">
                                                                    <div class="content-center">
                                                                        <div class="comtuser-name">
                                                                            <h5 className="comment_name">{comments.name}</h5>
                                                                            <time className="comment_timee"> <Moment format="DD/MM/YYYY hh:mm:ss A">
     {comments.createdate}
  </Moment>,</time>
                                                                           
                                                                        </div>

                                                                    </div>
                                                                    <p >{comments.comment}</p>
                                                                  



                                                                </div>
                                                            </div>)}


                                                        </li>


                                                       




                                                    </ul>
                                                </div>


                                                <form class="comment-form inline-items comments">
                                                    <p className="replyyyy">Post your comments</p>
                                                    <div class="post__author author vcard inline-items">
                                                    <div class="commentImg">
                                                            {profileimage?
                                                        <img src={config.API + "/images/user/" + profileimage} alt="" />:<img src={config.API + "/images/user/" + 'IMAGE-1674666998165.png'} alt="" />}
                                                        </div>
                                                        <div class="form-group with-icon-right "><textarea class="form-control"  data-gramm="false" wt-ignore-input="true"  onChange={onChange}   id="comment"  value={comment}></textarea></div>
                                                    </div>
                                                    <div class="d-flex justify-content-end">
                                                        <button type="button" class="btn btn__comment reply"  onClick={()=>handleFormSubmit(feed._id)} >Comment</button> 
                                                        <button type="button" class="btn reply" onClick={handleClick} >Cancel</button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>

                                </form>}


                                </div>
                                </div>
                                
                                
                                )}


















                          







                                {/* <nav class="nav nav-pills nav-fill post-additional-info-mini">
                                    <a href="#" id="comment_count82104"> 6 Comments <span>  </span> </a>
                                </nav> */}

                               

                            </div>


              
                              
                                


                        </div>


                      


                    </Row>

                </Container>



            </div>

</div>

        </>

    );


}   
import React, { Component,useState,useEffect } from 'react';
import './footer.css'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { headermenusaddon,sitesettings } from '../../actions/users';
import { Link } from "react-router-dom";
import config from "../../lib/config";


export default function Footer () {

    const[coursetypes,setCoursetypes]=useState([])
  
  
  const[exams,setExams]=useState([])
  const [sitesetting, setsitesetting] = useState({});






  const getUserListdata = async () => {
    var test = await sitesettings();
    console.log(test, "saam");

    setsitesetting(test.sitesettings);
   
  };




    const userData =async()=>{

        const headers = await headermenusaddon();
        setCoursetypes(headers.typefilter)
        setExams(headers.examfilter.slice(0,10))
       }

       useEffect(()=>{
           window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getUserListdata();
       
             userData();
       
            
         },[])
     

    return (

        <div>

            <div className='footer-all'>
           
            <div className='footer'>
                <Container>
                        <Row className='frow'>
                            
                            <Col md={3}>
                                <h2 className='foot-head'>CONTACT US</h2>
                                <div style={{ marginTop:"10px" }}>
                                    {/* <h5>MARVEL CLASSES</h5> */}
                                    <img className="f-logo" src={require('../../img/download.png')} />
                                    <p className='ad-p'>{sitesetting.address}</p>
                                </div>
                                <div>
                                    <h6>{sitesetting.phone1}</h6>
                                    <h6>{sitesetting.phone2}</h6>
                                    <h6>{sitesetting.mail2}</h6>
                                </div>
                            </Col>

                        {/* <Col md={3} >
                            <div>
                                    <h2 className='foot-head'>QUICK LINKS</h2>
                            <ul>
                                <a href='/'><li>Home</li></a>
                                <li>About Us</li>
                                <li>Free Live Classes</li>
                                <li>Free Study Material</li>
                                <a href='/my-profile-faq'><li>FAQ</li></a>
                                </ul>
                            </div>

                            <div>
                              <h2 className='foot-head'>NCERT SOLUTION</h2>
                                <ul>
                                        <a href='#'><li>class 12</li></a>
                                        <a href='#'> <li>class 11</li></a>
                                        <a href='#'> <li>class 10</li></a>
                                        <a href='#'> <li>class 9</li></a>
                                        <a href='#'> <li>class 8</li></a>
                                </ul>
                            </div>
                        </Col> */}
                        {/* <Col md={3}>
                            <div>
                            <h2 className='foot-head'>CLASSES</h2>
                                <ul>
                                        <a href='#'><li>NEET for class 11th</li></a>
                                        <a href='#'><li>NEET for class 12th</li></a>
                                        <a href='#'><li>NEET for class 12th Pass</li></a>
                                        <a href='#'> <li>JEE for class 11th</li></a>
                                        <a href='#'> <li>JEE for class 12th</li></a>
                                        <a href='#'> <li>JEE for class 12th Pass</li></a>
                                     <a href='#'><li>CBSE 9th Class</li></a>
                                    <a href='#'> <li>CBSE 10th Class</li></a>
                                    <a href='#'> <li>CBSE 11th Class</li></a>
                                    <a href='#'> <li>CBSE 12th Class</li></a>
                                    <a href='#'><li>ICSE 10th Class</li></a>
                                        <a href='#'><li>Pre-Foundation for class 6th to 10th</li></a>
                                </ul>
                            </div>
                        </Col> */}
                            
                            <Col md={2} >
                                <div>
                                    <h2 className='foot-head'>QUICK LINKS</h2>
                                    <ul>
                                        <Link to='/'><li>Home</li></Link>
                                        <Link to='/about-us'><li>About Us</li></Link>
                                       <Link to='/feed'> <li>Our Feeds</li> </Link>
                                        <Link to='/freestuff'><li>Free Study Material</li></Link>
                                        <Link to='/faq'><li>FAQ</li></Link>
                                    </ul>
                                </div>
                                </Col>
                            
                            <Col md={2}>
                                <div>
                                    <h2 className='foot-head'>COURSES</h2>
                                    <ul>
                                    {coursetypes.map(e =>
                                <Link className="dropdown-item dropsfooter" to={"/courses/"+e._id}>{e.content}</Link>
                              )}
                                    </ul>
                                </div>
                            </Col>
{/*                             
                        <Col md={3}>
                            <div>
                            <h2 className='foot-head'>ONLINE COURSES</h2>
                                <ul>
                                        <a href='#'><li>Live</li></a>
                                        <a href='#'><li>Recorded video</li></a>
                                        <a href='#'><li>Test series</li></a>
                                        <a href='#'><li>DLP</li></a>
                                        <a href='#'><li>Crash Course</li></a>
                                        <a href='#'><li>1-to 1 live</li></a>
                                        <a href='#'><li>MSAT Scholrship</li></a>
                                </ul>
                            </div>

                            <div>
                              <h2 className='foot-head'>SUPPORT</h2>
                                <ul>
                                    <li>Privacy Policy</li>
                                    <li>Terms&Condition</li>
                                    <li>Refund Policy</li>
                                </ul>
                            </div>
                        </Col> */}
                            
                            <Col md={2}>
                                <div>
                                    <h2 className='foot-head'>EXAMS</h2>
                                    <ul>
                                    {exams.map((e)=>
                                <Link className="dropdown-item dropsfooter" to={"/exam/"+e._id}>{e.content}</Link>
                            )                              
                            }
                                    </ul>
                                </div>
                            </Col>

                            <Col md={2}>
                                <div>
                                    <h2 className='foot-head'>SUPPORT</h2>
                                    <ul>
                                    <Link to='/privacy-policy'><li>Privacy Policy</li></Link>
                                    <Link to='/terms-condition'><li>Terms & Conditions</li></Link>
                                        {/* <li>Refund Policy</li> */}
                                    </ul>
                                </div>
                            </Col>

                            <Col md={1}>
                                <div>
                                    <h2 className='foot-head' style={{fontSize:"13px"}}>OUR APP</h2>
                                    <ul>
                                        <li>
                                            <img className="build" src={config.API +"/images/user/"+sitesetting.image2} />   
                                            <p className='appzz'>Marvel App</p>
                                            <p className='appzzz'>Download Now</p>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </Col>

                            

                            
                      
                    </Row>

                    <p className='agents'><Link to="/agentregistration" style={{ textDecoration:"underline" }}>Are You Willing To Join As Referrer For Marvel?(Signup)</Link></p>
                    <p className='agents'><Link to="/agentsignin" style={{ textDecoration:"underline" }}>Referrer Sign-in</Link></p>

                        <div className='copyright'>
                       
                        <p>Copyright © 2023 All Rights Reserved by Marvel Classes.</p>
                           
                        </div>
                </Container>
            </div>  
           
            </div>


        </div>

    )

}
import React,{useState,useEffect} from 'react';
import './onlinecourse.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { coursefilter,typesfromexam,subjectlist } from '../../actions/users';
import config from '../../lib/config'
import { useHistory, useParams,Link } from "react-router-dom";

export default function Onlinecourse() {
    
const[coursetypes,setCoursetypes]=useState([])
const[exams,setExams]=useState([])
const[subjects,setSubjects]=useState([])
const[classes,setClasses]=useState([])
const[courseindex,setcourseindex]=useState(null)
const[examindex,setexamindex]=useState(null)
const[courselist,setcourselist]=useState([])


const[hitype,setType]=useState("");

const { exam } = useParams();

const handleExamsCheckbox = async(i) =>{
    let input  = [...subjects];
    // input.map(e=>({...e,checked:false}))
    input[i].checked= !input[i].checked;
    console.log(input)
    setSubjects(input)
}

const handleClassesCheckbox = async(i) =>{
    let input  = [...classes];
    // input.map(e=>({...e,checked:false}))
    input[i].checked= !input[i].checked;
    console.log(input)
    setClasses(input)
}
// console.log(subjects);
const userData =async()=>{
    let filterdata={};
    if(courseindex){
        filterdata.type= coursetypes[courseindex]._id;
    }
    if(exam){
        filterdata.exam= exam
    }
    if(subjects.length){
        let finalans1 =subjects.filter(word => word.checked == true);
        finalans1=finalans1.map((e,i)=>{
         return e._id
       });
       filterdata.subject= finalans1
    }
    if(classes.length){
        let finalans1 =classes.filter(word => word.checked == true);
        finalans1=finalans1.map((e,i)=>{
         return e._id
       });
       filterdata.eligiblity= finalans1
    }
 const apicourselist = await coursefilter(filterdata);
 setcourselist(apicourselist.userValue)

}
const fetchtypeopt = async() =>{
    const typelist = await typesfromexam(exam)
    setCoursetypes(typelist.typefilter)
}


const fetchsubject = async() =>{
    let reqData={
        type:coursetypes[courseindex]._id,
        exam: exam
    }
    const sub = await subjectlist(reqData)
   console.log(sub,"subbbbb")
 setSubjects(sub.subjectsfilter)
 setClasses(sub.classesfilter)

}

// console.log(courselist,"ccccccccccccccccccccccccccc")
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    userData();
},[courseindex,examindex,exam,subjects,classes])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if(courseindex != null){
    fetchsubject();
    }
},[exam,courseindex])

//Fetching the types for options
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setcourseindex(null)
    fetchtypeopt();
},[exam])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setcourseindex(null);
},[])
    return (

        <div className='allbody'>

        <div className='overall-courses'>   
          <Container>
                <Row>
                <Col md={4}>
                        <Row style={{ alignItems: "center" }}>
                        <Col md={6}>
                            <p className='filter'>Filters</p>
                        </Col>
                        <Col md={6}>
                            {/* <p className='clearall'>Clear All</p> */}
                            </Col>   
                        </Row>

                        <Row>
                            <Col md={12}>
                                <div className="card mb-4">
                                    <h5 className="card-header">Course Mode</h5>

                                    <div className="card-body">
                                        {coursetypes.map( (e,i) =>
                                        <div key={e.typevalue._id} className="form-check" style={{ marginTop:"6px" }}>
                                            <input className="form-check-input product_type_filter" style={{ marginTop:"6px" }} type="radio" value={e.typevalue._id} checked={courseindex == i} onChange={() => setcourseindex(i)}  id="product_type_3" data-title="Tab/PD" />
                                                <label className="form-check-label">
                                                {e.typevalue.content}                     </label>
                                        </div>)}
                                    </div>

                                </div>




                             
                                {subjects.length ? <div className="card mb-4">
                                    <h5 className="card-header " onclick="collapse_filter('Classes',this.id,'cf_class')">Subjects</h5>

                                    <div className="card-body">
                                    {subjects.map( (e,i) =>
                                        <div key={e.subjectvalue._id} className="form-check" style={{ marginTop:"6px" }}>
                                             <input className="form-check-input product_master_filter cf_class" style={{ marginTop:"6px" }} type="checkbox" value={e.subjectvalue._id} checked={e.subjectvalue.checked} onChange={() => handleExamsCheckbox(i)} data-title="Class-12" />
                
                                                <label className="form-check-label">
                                                {e.subjectvalue.content}                     </label>
                                        </div>)}
                                       
                                    </div>

                                </div>:null
}

                        {classes.length ? <div className="card mb-4">
                                    <h5 className="card-header ">Classes</h5>

                                    <div className="card-body">
                                    {classes.map( (e,i) =>
                                        <div key={e.classvalue._id} className="form-check" style={{ marginTop:"6px" }}>
                                             <input className="form-check-input product_master_filter cf_class" style={{ marginTop:"6px" }} type="checkbox" value={e.classvalue._id} checked={e.classvalue.checked} onChange={() => handleClassesCheckbox(i)} data-title="Class-12" />
                
                                                <label className="form-check-label">
                                                {e.classvalue.content}                     </label>
                                        </div>)}
                                       
                                    </div>

                                </div>:null }

                               
                            </Col>
                        </Row>
                  
                    </Col>
                    
                    <Col md={8}>

                            <Row>
                                <Col md={12}>
                                    {/* <div>
                                        <h4>
                                            Sort By :
                                        </h4>
                                    <Form.Select aria-label="Default select example">
                                        <option>Recent</option>
                                        <option value="1">Engineering</option>
                                        <option value="2">Medical</option>
                                        <option value="3">State Exams</option>
                                        </Form.Select>
                                       
                                    </div> */}
                                </Col>
                                { courselist.length ? courselist.map(e =>
                            <Col key={e._id} lg={4}>
                                <Card className='card-course'>
                                    <Card.Img className='course-img' variant="top" src={`${config.API}/images/user/${e.image}`} />
                                    <Card.Body style={{ padding:"10px 16px" }}>
                                        <Card.Title>{e.title.slice(0,15)}</Card.Title>
                                        <Card.Text>
         <div
dangerouslySetInnerHTML={{ __html: e.description.slice(0,20) }}
></div><div style={{color:"#3498db"}}>...</div>
{/* <div style={{color:"#3498db"}}>Click...View Details below</div> */}


         </Card.Text>
                                        {/* <Card.Text>
                                        Most Popular Online Courses of All Time
                                        </Card.Text> */}
                                        {/* <Button variant="primary">Go somewhere</Button> */}
                                            <p className='rate'>Rs.{e.price}</p>
                                                <h5 className='validity'><span style={{ fontWeight:"600" }}>Validity :</span> 365 days </h5>
                                            <Link to={"/buycourse/"+e._id}><Button className='viewdetail' variant="primary">View Details</Button>{' '}</Link>
                                    </Card.Body>
                                </Card> 
                            </Col>
                            ):<div style={{margin:"auto"}}> <img  src={require('../../img/no-course.jpg')} /></div>}
                                
                        </Row>

                    </Col>
                </Row>
            </Container>

 
         
            </div> 
        </div>
    );
}

 
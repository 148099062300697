import React, { useState, useEffect } from "react";
import './forget.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import isEmpty from "../../lib/isEmpty";
import { agentregister } from "../../actions/users";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";

const initialFormValue = {   
   
    name:"",
    email:"",
    password:"",
    phonenumber:"",
    bankname:"",
    accountnumber:"",
    ifsccode:""    
  };
export default function Forget() {
    const [formValue, setFormValue] = useState(initialFormValue);
    const history = useNavigate();
    const [validateError, setValidateError] = useState({});


    

    const onChange = (e) => {
        e.preventDefault();
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        console.log(formValue);
    
        
        //setValidateError(formData)
      };


      const {
      
      email,
      name,
      password,
      phonenumber,
      bankname,
      accountnumber,
      ifsccode
      
    
      } = formValue;



      const handleFormSubmit = async () => {
        console.log("saran");
        
        console.log(formValue);
        let reqData = {
           
          email,
          name,
          password,
          phonenumber,
          bankname,
          accountnumber,
          ifsccode
           
        };
        console.log(reqData,"sss")
        // let result = await forgotPassword(reqData);

        // if (result) {
        //     history("/");
        //     swal("Password reset link has been sent to your mail");
        //   } else {
        //     swal("Please try again");
        //   }
        let { error } = await agentregister(reqData);
        console.log("error", error);
        if (!error) {
          history("/agentsignin");
          swal("Your account has been added as agent successfully");
         
        } 
       else {
          setValidateError(error);
        }
      
       
        
       
      };
    

      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
},[])

    return (
        <>

         <div className='allbody'>

          <div className='ghhh'>

<Container>
 
 <div className="trip">
 <div className="forgetzzz">
    
    <h5 className="pass">Referrer Registration</h5>
    <form id="sbform" method="post">
                                        <div class="row">
                                           
                                           
                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Name <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="name" id="name" onChange={onChange} value={name}  />
                                                {validateError.name && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.name}
                                                          </span>
                                                        )}
                                                
                                            </div>

                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Email <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="email" id="email" onChange={onChange} value={email}  />
                                                {validateError.email && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.email}
                                                          </span>
                                                        )}
                                                
                                            </div>

                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Password <span style={{ color:"red" }}> *</span></label>
                                                <input type="password" class="form-control" name="password" id="password" onChange={onChange} value={password} />
                                                {validateError.password && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.password}
                                                          </span>
                                                        )}
                                                
                                            </div>


                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Phone number <span style={{ color:"red" }}> *</span></label>
                                                <input type="text" class="form-control" name="phonenumber" id="phonenumber" onChange={onChange} value={phonenumber}  />
                                                {validateError.phonenumber && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.phonenumber}
                                                          </span>
                                                        )}
                                                
                                            </div>

                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            {/* <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Bank name</label>
                                                <input type="text" class="form-control" name="bankname" id="bankname" onChange={onChange} value={bankname} />
                                                {validateError.bankname && (
                                                          <span style={{color:"red",fontSize:"10px"}}>
                                                            {validateError.bankname}
                                                          </span>
                                                        )}
                                                
                                            </div>

                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">Acccount number</label>
                                                <input type="text" class="form-control" name="accountnumber" id="accountnumber" onChange={onChange} value={accountnumber}  />
                                                {validateError.accountnumber && (
                                                          <span style={{color:"red",fontSize:"10px"}}>
                                                            {validateError.accountnumber}
                                                          </span>
                                                        )}
                                                
                                            </div>

                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingInput">IFSC Code</label>
                                                <input type="text" class="form-control" name="ifsccode" id="ifsccode" onChange={onChange} value={ifsccode}  />
                                                {validateError.ifsccode && (
                                                          <span style={{color:"red",fontSize:"10px"}}>
                                                            {validateError.ifsccode}
                                                          </span>
                                                        )}
                                                
                                            </div> */}
                                          
                                        </div>


                                    

                                       




                                        <button type="button" class="btn btn-primary mb-3  mt-3 m-auto" id="save_profile" onClick={handleFormSubmit}> Register </button>
                                        

                                    </form>

  </div>
  </div>

</Container>

</div>
         </div>



</>

);
}
import React,{useState,useEffect} from 'react';
import './onlinecourse.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { coursefilter,examsfromtypes,subjectlist } from '../../actions/users';
import config from '../../lib/config'
import { useHistory, useParams,Link } from "react-router-dom";

function datealignment(startdate){
   
    var now = new Date(startdate);

var startdate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear() + " " ;

   
      
// Regular expression to identify HTML tags in 
// the input string. Replacing the identified 
// HTML tag with a null string.
return startdate;
}

export default function Onlinecourse() {
    
const[coursetypes,setCoursetypes]=useState([])
const[exams,setExams]=useState([])
const[subjects,setSubjects]=useState([])
const[classes,setClasses]=useState([])
const[courseindex,setcourseindex]=useState(null)
const[examindex,setexamindex]=useState(null)
const[courselist,setcourselist]=useState([])

const[hitype,setType]=useState("");

const { type } = useParams();


const handleExamsCheckbox = async(i) =>{
    let input  = [...subjects];
    // input.map(e=>({...e,checked:false}))
    input[i].checked= !input[i].checked;
    console.log(input)
    setSubjects(input)
}

function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
  }

const handleClassesCheckbox = async(i) =>{
    let input  = [...classes];
    // input.map(e=>({...e,checked:false}))
    input[i].checked= !input[i].checked;
    console.log(input)
    setClasses(input)
}
// console.log(subjects);
const fetchexamopt = async() =>{
    const examlist = await examsfromtypes(type)
    setExams(examlist.examfilter)
    
}
const userData =async()=>{
    let filterdata={};
    if(type){
        filterdata.type= type;
    }
    if(examindex){  
        filterdata.exam= exams[examindex]._id
    }
    if(examindex == 0)
    {
        filterdata.exam= exams[0]._id
    }
    if(subjects.length){
        let finalans1 =subjects.filter(word => word.checked == true);
        finalans1=finalans1.map((e,i)=>{
         return e._id
       });
       filterdata.subject= finalans1
    }
    if(classes.length){
        let finalans1 =classes.filter(word => word.checked == true);
        finalans1=finalans1.map((e,i)=>{
         return e._id
       });
       filterdata.eligiblity= finalans1
    }
 const apicourselist = await coursefilter(filterdata);
 console.log(apicourselist,"spds")
//   const data=apicourselist.userValue.map(e => {
//     if(e.type == "62cd0caf1538ec3c90ab3c74")
//     return
//     {
//        startdate=e.startdate  
//     }
//   })

 setcourselist(apicourselist.userValue)
 setCoursetypes(apicourselist.typefilter)
}

const fetchsubject = async() =>{
    let reqData={
        type:type,
        exam: exams[examindex]._id
    }
    const sub = await subjectlist(reqData)
   
 setSubjects(sub.subjectsfilter)
 setClasses(sub.classesfilter)
}
// console.log(courselist,"ccccccccccccccccccccccccccc")

//Fetching the course list on every changes
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    userData();
},[courseindex,examindex,type,subjects,classes])


//Fetching the exams for options
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setexamindex(null)
    fetchexamopt();
},[type])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setSubjects([])
    if(examindex != null){
    fetchsubject();
    }
},[type,examindex])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setcourseindex(null);
},[])
    return (

        <div className='allbody'>

        <div className='overall-courses'>   
          <Container>
                <Row>
                <Col md={4}>
                        <Row style={{ alignItems: "center" }}>
                        <Col md={6}>
                            <p className='filter'>Filters</p>
                        </Col>
                   
                        </Row>

                        <Row>
                            <Col md={12}>


                                { exams.length ? <div className="card mb-4">
                                    <h5 className="card-header">Exams</h5>

                                    <div className="card-body">
                                    {exams.map((e,i) =>
                                        <div key={e.examvalue._id} className="form-check">
                                            <input className="form-check-input product_type_filter" style={{ marginTop:"6px" }} type="radio" value={e.examvalue._id} checked={examindex == i} onChange={() => setexamindex(i)}  id="product_type_3" data-title="Tab/PD" />
                                                <label className="form-check-label">
                                                {e.examvalue.content}                     </label>
                                        </div>)}
                                    </div>

                                </div>: null }




                              
                                {subjects.length ? <div className="card mb-4">
                                    <h5 className="card-header ">Subjects</h5>

                                    <div className="card-body">
                                    {subjects.map( (e,i) =>
                                        <div key={e.subjectvalue._id} className="form-check">
                                             <input className="form-check-input product_master_filter cf_class" style={{ marginTop:"6px" }} type="checkbox" value={e.subjectvalue._id} checked={e.subjectvalue.checked} onChange={() => handleExamsCheckbox(i)} data-title="Class-12" />
                
                                                <label className="form-check-label">
                                                {e.subjectvalue.content}                     </label>
                                        </div>)}
                                       
                                    </div>

                                </div>:null }

                                {classes.length ? <div className="card mb-4">
                                    <h5 className="card-header ">Classes</h5>

                                    <div className="card-body">
                                    {classes.map( (e,i) =>
                                        <div key={e.classvalue._id} className="form-check">
                                             <input className="form-check-input product_master_filter cf_class" style={{ marginTop:"6px" }} type="checkbox" value={e.classvalue._id} checked={e.classvalue.checked} onChange={() => handleClassesCheckbox(i)} data-title="Class-12" />
                
                                                <label className="form-check-label">
                                                {e.classvalue.content}                     </label>
                                        </div>)}
                                       
                                    </div>

                                </div>:null }
                               
                            </Col>
                        </Row>
                  
                    </Col>
                    
                    <Col md={8}>

                            <Row>
                                <Col md={12}>
                                    {/* <div>
                                        <h4>
                                            Sort By :
                                        </h4>
                                    <Form.Select aria-label="Default select example">
                                        <option>Recent</option>
                                        <option value="1">Engineering</option>
                                        <option value="2">Medical</option>
                                        <option value="3">State Exams</option>
                                        </Form.Select>
                                       
                                    </div> */}
                                </Col>
                                { courselist.length ? courselist.map(e =>{
                                    if(e.type == "62cd0caf1538ec3c90ab3c74")
                                return(
                            <Col key={e._id} lg={4}>
                                <Card className='card-course'>
                                    <Card.Img className='course-img' variant="top" src={`${config.API}/images/user/${e.image}`} />
                                    <Card.Body style={{ padding: "6px 15px" }}>
                                        <Card.Title>{e.title.slice(0,15)}...</Card.Title>
                                        <Card.Text>
                                        <div
                dangerouslySetInnerHTML={{ __html: e.description.slice(0,20) }}
              ></div><div style={{color:"#3498db"}}>...</div>

              
                                        </Card.Text>
                                        {/* <Button variant="primary">Go somewhere</Button> */}
                                            <p className='rate'>Rs.{e.price}</p>
                                           
                              
                                            <h5 className='validity'>Start-date :{datealignment(e.startdate)} </h5>
                                            <h5 className='validity'>End-date :{datealignment(e.enddate)} </h5>
                                            <Link to={"/buycourse/"+e._id}><Button className='viewdetail' variant="primary">View Details</Button>{' '}</Link>
                                    </Card.Body>
                                </Card>
                       </Col>)

return(
                             <Col key={e._id} lg={4}>
 <Card className='card-course'>
     <Card.Img className='course-img' variant="top" src={`${config.API}/images/user/${e.image}`} />
     <Card.Body style={{ padding: "6px 15px" }}>
         <Card.Title>{e.title.slice(0,15)}...</Card.Title>
         <Card.Text>
         <div
dangerouslySetInnerHTML={{ __html: e.description.slice(0,20) }}
></div><div style={{color:"#3498db"}}>...</div>
{/* <div style={{color:"#3498db"}}>Click...View Details below</div> */}


         </Card.Text>
         {/* <Button variant="primary">Go somewhere</Button> */}
             <p className='rate'>Rs.{e.price}</p>
            

           
                <h5 className='validity'><span className="expie">Expiry-date : </span> {datealignment(e.enddate)} </h5>
             <Link to={"/buycourse/"+e._id}><Button className='viewdetail' variant="primary">View Details</Button>{' '}</Link>
     </Card.Body>
 </Card>
</Col>)
 } 



 ):<div style={{margin:"auto"}}> <img  src={require('../../img/no-course.jpg')} /></div>}
                                
                        </Row>

                    </Col>
                </Row>
            </Container>

 
         
            </div> 
        </div>
    );
}

 
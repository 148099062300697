import React, { useState, useEffect } from "react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import "./buycourse.css";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Header from './../Header/header';

import { FaRegFilePdf } from 'react-icons/fa';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  NavItem,
} from "react-bootstrap";

import { useCallback } from "react";

import useRazorpay from "react-razorpay";
import { ReactVideoPlayer } from "video-player-for-react";
import ReactPlayer from "react-player";
import {
  getcoursedata,
  createorder,
  verifyPayment,
  verifycourse,
  wallet,
  verifycupons,
  getdemovideo,
  getdemopdf,
  walletcoin
} from "../../actions/users";

import config from "../../lib/config";
import DateB2 from "../../lib/DaysB2";
import { useNavigate, useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import PDFViewer from "pdf-viewer-reactjs";

function datealignment(startdate){
   
  var now = new Date(startdate);

var startdate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear() + " " ;

 
    
// Regular expression to identify HTML tags in 
// the input string. Replacing the identified 
// HTML tag with a null string.
return startdate;
}
export default function Buycourse() {
  const [userwallet, setuserwallet] = useState({});
  const [coursedetail, setcoursedetail] = useState({});
  const [couponedetail, setcupondetail] = useState({});
  const [salesprice, setsalesprice] = useState(0);
  const [pdfdetail, setpdfdetail] = useState(0);
  const [videodetail, setvideodetail] = useState(0);
  const [testdetail, settestdetail] = useState(0);
  const [walletprice, setwalletprice] = useState(0);
  const [cuponprice, setcuponprice] = useState(0);
  const [walletcoinsused, setwalletcoinsused] = useState(0);
  const [popup, setpopup] = useState(0);
  console.log(popup,"lll")
  const [cupbtn, setcupbtn] = useState(false);
  const [cuponcode, setcuponcode] = useState("");
  const [cuponcodefinal, setcuponcodefinal] = useState("");
  const [validateError, setValidateError] = useState({});
  const [purchasedstatus, setpurchasedstatus] = useState(false);
  const [demovideos, setdemovideos] = useState([]);
  const [errordemovideos, seterrordemovideos] = useState("");
  const [demopdfs, setdemopdfs] = useState([]);
  const [errordemopdfs, seterrordemopdfs] = useState("");
  const { ic_id } = useParams();
  const[walletvalue,setwalletvalue]=useState(0)
  const history = useNavigate();

  // document.addEventListener(
  //     "click",
  //     function (event) {
  //         var target = event.target;
  //         var replyForm;
  //         if (target.matches("[data-toggle='reply-form']")) {
  //             replyForm = document.getElementById(target.getAttribute("data-target"));
  //             replyForm.classList.toggle("d-none");
  //         }
  //     },

  // );

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  console.log(cupbtn, "cupbtncupbtncupbtn");
  async function displayRazorpay(pamount, packagedetail) {
   
if(pamount){
    const result = await createorder(pamount * 100);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    console.log(pamount, result, "resultresultresultresult");
    const { amount, id: id, currency } = result.userValue;

    const options = {
      key: "rzp_live_Rg4h4KgIQss1Ry", // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: "INR",
      name: "Marvels",
      description: "Marvels",
      order_id: id,
      handler: async function (response) {
        console.log(
          "sattavcvc",
          response,
          "response.razorpay_payment_id",
          response.razorpay_payment_id,
          "response.razorpay_order_id",
          response.razorpay_order_id,
          "response.razorpay_signature",
          response.razorpay_signature
        );
        // const data = {
        //   orderCreationId: "order_JAWG8geMxr0j1x",
        //   razorpayPaymentId: response.razorpay_payment_id,
        //   razorpayOrderId: response.razorpay_order_id,
        //   razorpaySignature: response.razorpay_signature,
        // };

        const data = {
          orderID: response.razorpay_order_id,
          transaction: response,
          courseID: coursedetail._id,
          orderdetail: {
            courseprice: salesprice - walletprice - cuponprice,
            walletpoints: walletcoinsused,
            cuponcode: cuponcodefinal,
          },
        };

        const result = await verifyPayment(data);
        console.log(result.userValue, "resultresultresultresultresultresult");
        
        if (result.userValue) {
          history("/my-profile-courses");
          swal("Payment Successful and course purchased");
          window.location='/my-profile-courses'
        } else {
          swal("Oops!", "Your signature is Invalid", "error");
          window.location='/my-profile-courses'
        }

        // alert(result);
      },
      prefill: {
        name: localStorage.name,
        email: "",
        contact: localStorage.phone_number,
      },
      notes: {
        address: "Example Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }else{
    const data = {
      courseID: coursedetail._id,
      orderdetail: {
        courseprice: salesprice - walletprice - cuponprice,
        walletpoints: walletcoinsused,
        cuponcode: cuponcodefinal,
      },
    };
  
    const result = await verifyPayment(data);
    console.log(result.userValue, "resultresultresultresultresultresult");
    if(result.userValue=true){
      history("/my-profile-courses");
      swal("Redeemed Successful and course purchased").then(function() {
        window.location = "/my-profile-courses";
    })
    }
    // if (result.userValue) {
    //   history("/my-profile-courses");
    //   swal("Payment Successful", "Your signature is valid", "success");
    //   window.location='/my-profile-courses'
    // }
     else {
      swal("Oops!", "Your signature is Invalid", "error");
      window.location='/my-profile-courses'
    }
  }
  }
  const userData = async () => {
   

    
    const cousrsed = await getcoursedata(ic_id);
    console.log(cousrsed,"hii")
    if ("error" in cousrsed) {
      alert("Internal server error");
    } else {
      setcoursedetail(cousrsed.userValue);
      setpdfdetail(cousrsed.pdfcount);
      setvideodetail(cousrsed.videocount);
      settestdetail(cousrsed.testcount);
      setsalesprice(cousrsed.userValue.price);
    }
    if (localStorage.user_token) {
      const verify = await verifycourse(ic_id);
      if ("error" in verify) {
        alert("Internal server error");
      } else {
        setpurchasedstatus(verify.purchased);
      }

      const walletdata = await wallet();
      console.log(walletdata,"sss")
      if ("error" in walletdata) {
        alert("Internal server error");
      } else {
        setuserwallet(walletdata.userWallet);
      }

      const demos = await getdemovideo(ic_id);
      console.log(demos,"vvv")
      if ("error" in demos) {
        alert("Internal server error");
      }  if(demos.statement="true"){
        setdemovideos(demos.userValue);
       
      }
      if(demos.statement="false"){
       
        seterrordemovideos(demos.messages);
      }



      const demospdf = await getdemopdf(ic_id);
      console.log(demospdf,"cccc")
      if ("error" in demospdf) {
        alert("Internal server error");
      }  if(demospdf.statement="true"){
        setdemopdfs(demospdf.userValue);
       
      }
      if(demospdf.statement="false"){
       
        seterrordemopdfs(demospdf.messages);
      }

      
    }
    else{
      const demos = await getdemovideo(ic_id);
      console.log(demos,"vvv")
      if ("error" in demos) {
        alert("Internal server error");
      }  if(demos.statement="true"){
        setdemovideos(demos.userValue);
       
      }
      if(demos.statement="false"){
       
        seterrordemovideos(demos.messages);
      }
    }

    const walletcoinvalue = await walletcoin();
    console.log(walletcoinvalue,"sms")
   

        setwalletvalue(walletcoinvalue.userValue)
       

    
  };

  const redeem = () => {
    let limit = userwallet.walletcoins / walletvalue;
    if(limit==0){
alert("Your account don't have enough wallet points to redeem")
    }
    if(limit < coursedetail.price){
    setwalletprice(userwallet.walletcoins / walletvalue);
    setwalletcoinsused(userwallet.walletcoins);
    }else{
      let usedcoins = coursedetail.price *walletvalue;
      setwalletprice(coursedetail.price);
      setwalletcoinsused(usedcoins);

    }
  };

  const unredeem = () => {
    setwalletprice(0);
    setwalletcoinsused(0);
  };

  //    console.log(cuponcode,"djdddkdjdjdkj")
  const verifycuponcodes = async () => {
    if (cuponcode) {
      const reqData = {
        code: cuponcode,
      };
      const cuponresult = await verifycupons(reqData);
      console.log(cuponresult, "cuponresultcuponresultcuponresult");
      if (cuponresult.userValue) {
        if (salesprice > cuponresult.userValue.minamount) {
          setcuponprice(
            salesprice * (cuponresult.userValue.offerpercent / 100)
          );
          setcupondetail(cuponresult.userValue);
          setcuponcodefinal(cuponcode);
        } else {
          setValidateError({
            ...validateError,
            cupon: `You need to purchase more than ₹${cuponresult.userValue.minamount}`,
          });
        }
      } else {
        setValidateError({ ...validateError, cupon: "Invalid coupon code" });
      }
    } else {
      setValidateError({
        ...validateError,
        cupon: "Please enter a coupon code",
      });
    }
  };
  const cancelcupon = () => {
    setcuponprice(0);
    setcuponcode("");
    setcuponcodefinal("");
  };

  useEffect(() => {
    userData();
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const buycoursepage = (type, id) => {
    console.log(type, "typetypetypetypetype");
    
    switch (type) {
      case "62cd0ca31538ec3c90ab3c73": {
        //live
        return "/schedule/" + id;
        break;
      }
      case "62cd0cb71538ec3c90ab3c75": {
        //recorded
        return "/coursewelcome/" + id;
        break;
      }
      case "62ce648ce201276788868b3e": {
        //Micro
        return "/coursewelcome/" + id;
        break;
      }
      case "62cd0caf1538ec3c90ab3c74": {
        //Direct
        return "/directwelcome/" + id;
        break;
      }
      case "62ce6480e201276788868b3d": {
        //test-series
        return "/testcourse-content/" + id;
        break;
      }

      default:
        return "#";
    }
  };

  return (
    <>
    {popup>0?
      <Header headerpopup={popup} />:null}
      <div className="allbody">
        <div style={{ marginBottom:"20px" }}>
          <Container>

            <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>

            <Row>
              <Col md={7}>
                <div className="full-coourse">
                  {/* <Button className="course-mode" variant="danger"> */}
                  <h5 className="courselabel">{coursedetail.typename} </h5>
                  {/* </Button> */}
                  <h1 className="course-title">{coursedetail.title} </h1>
                  <h6 className="plan">
                    {coursedetail.eligiblity
                      ? `For ${coursedetail.eligiblity} Students`
                      : null}
                    
                    <span> | </span>  {coursedetail.typename}
                  </h6>
                  {/* <h4>Duration : 365 days | Language : English</h4> */}

                  <ul class="nav nav_timing mt-3">
                    <li class="nav-item center_li">
                      <span>
                        {" "}
                        <span className="gtgg">Expiry-date : </span> {datealignment(coursedetail.enddate)}
                        {DateB2(
                          new Date(coursedetail.enddate),
                          new Date(coursedetail.startdate)
                        )}{" "}
                      </span>
                    </li>
                    <li class="nav-item">
                      <span>  <span className="gtgg">Language :  </span> {coursedetail.language} </span>
                    </li>
                  </ul>

                  <ul class="  course-details mt-4">
                    {/* <li class="nav-item center_li">
                      <strong> {coursedetail.totaltest} </strong>
                      <span> Tests </span>
                    </li> */}
                    {/* <li class="nav-item">
                      <strong> {coursedetail.totalquestions}</strong>
                      <span> Practise Qs</span>
                    </li> */}
                    <li class="nav-item">
                      <strong> {pdfdetail}</strong>
                      <span> Pdf's</span>
                    </li>
                    <li class="nav-item">
                      <strong> {videodetail}</strong>
                      <span> Video's</span>
                    </li>
                    <li class="nav-item">
                      <strong> {testdetail}</strong>
                      <span> Test count</span>
                    </li>
                  </ul>

                  <div class="d-flex align-items-center  justify-content-between">
                    <div class="cp_selling__price ">
                      <strong> Rs. {coursedetail.price} </strong>
                      {/* <span> Rs. 5000  </span> */}
                    </div>
                    {purchasedstatus ? (
                      <Link
                        class="btn btn-demo"
                        to={buycoursepage(coursedetail.type, coursedetail._id)}
                      >
                        View Course
                      </Link>
                    ) : localStorage.user_token ? (
                      <button
                        type="button"
                        class="btn btn-demo"
                        data-toggle="modal"
                        data-target="#myModal2"
                      >
                        Buy Course
                      </button>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-demo"
                        onClick={() =>
                          swal(
                            "Need to login",
                            "Please login to Buy this course",
                            "warning"
                          ).then(function() {
                            setpopup(1);
   
})
                        }
                      >
                        Buy Course
                      </button>
                    )}
                  </div>

                  <div
                    className="modal right fade"
                    id="myModal2"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel2"
                    style={{ zIndex: 77777777 }}
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <h4 className="modal-title" id="myModalLabel2">
                            Payment Mode
                          </h4>
                        </div>

                        <div className="modal-body">
                          <ul
                            style={{ marginLeft: "20px" }}
                            className="onetime"
                          >
                            <li>One Time Payment</li>
                          </ul>
                          <div className="coupon">
                            <div className="offer">
                              <h4>Get Exciting Offer Inside</h4>
                              <p
                                className="apply"
                                data-target="comment-4-reply-form"
                                onClick={() => setcupbtn(!cupbtn)}
                              >
                                Apply coupon
                              </p>
                            </div>

                            {cupbtn ? (
                              <form
                                method="POST"
                                class="comment-form inline-items"
                              >
                                <div className="card card-body ">
                                  <div className="input-group mt-2 keep">
                                    <span className="input-group-text search_icon couponss">
                                      {" "}
                                      <i className="fas fa-search"></i>{" "}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="coupon_code"
                                      onChange={(e) =>
                                        setcuponcode(e.target.value)
                                      }
                                      value={cuponcode}
                                      placeholder="Enter Coupon code"
                                    />

                                    <button
                                      className="btn btn-outline-secondary applies"
                                      type="button"
                                      onClick={verifycuponcodes}
                                    >
                                      Apply
                                    </button>
                                    <button
                                      className="btn btn-outline-secondary applies"
                                      type="button"
                                      onClick={cancelcupon}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  <span
                                    id="error_coupon"
                                    style={{ color: "red" }}
                                  >
                                    {validateError.cupon}
                                  </span>
                                </div>
                              </form>
                            ) : (
                              <></>
                            )}
                            {/* <div style={{paddingTop:"10px"}}><p style={{color:"#061b4f"}}>Your wallet balane {userwallet&&userwallet.walletcoins} points</p></div> */}
                            <div className="align-items-center content-center py-3">
                              <div className="left_cont" style={{ marginLeft:"2px" }}>
                                <p
                                  className="mb-0 para"
                                  id="reward_remaining"
                                  remaining=""
                                >
                                  {" "}
                                  You can use {userwallet.walletcoins} points
                                </p>
                                <p className="mb-0 para"> ({walletvalue} points = 1 Rs)</p>
                              </div>
                              {walletcoinsused ? (
                                <button
                                  className="btn btn_redeem redeemed"
                                  type="button"
                                  onClick={unredeem}
                                  rp="540"
                                  cr="20"
                                  mctr="1"
                                  data-id="1"
                                >
                                  {" "}
                                  UnRedeem
                                </button>
                              ) : (
                                <button
                                  className="btn btn_redeem redeemed"
                                  type="button"
                                  rp="540"
                                  onClick={redeem}
                                  cr="20"
                                  mctr="1"
                                  data-id="1"
                                >
                                  {" "}
                                  Redeem
                                </button>
                              )}
                            </div>
                            <li class="list-group-item  course__price content-center border-0">
                              {" "}
                              <label> Price </label>{" "}
                              <strong id="package_price" pp="24999">
                                Rs.{salesprice} /-
                              </strong>{" "}
                            </li>
                            {walletcoinsused ? (
                              <li class="list-group-item  course__price content-center border-0">
                                {" "}
                                <label> Points {walletcoinsused} </label>{" "}
                                <strong id="package_price" pp="24999">
                                  Rs.{walletprice} /-
                                </strong>{" "}
                              </li>
                            ) : null}
                            {cuponprice ? (
                              <li class="list-group-item  course__price content-center border-0">
                                {" "}
                                <label>
                                  {" "}
                                  Cuponcode {cuponcodefinal},
                                  Offer{" "} {couponedetail.offerpercent}%
                                </label>{" "}
                                <strong id="package_price" pp="24999">
                                  Rs.{cuponprice} /-
                                </strong>{" "}
                              </li>
                            ) : null}
                            <li
                              class="list-group-item course__total  content-center"
                              style={{ fontSize: "18px" }}
                            >
                              {" "}
                              <label> Total </label>{" "}
                              <strong id="grand_total" grand-total="24999">
                                Rs. {salesprice - walletprice - cuponprice}/-
                              </strong>{" "}
                            </li>
                            <div style={{ textAlign: "center" }}>
                              {/* <a href="/transaction"><button type="button" className="btn btn-primary buynow">Buy Now</button></a> */}
                              <a
                                onClick={() =>
                                  displayRazorpay(
                                    salesprice - walletprice - cuponprice,
                                    coursedetail._id
                                  )
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary buynow"
                                >
                                  Buy Now
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={5}>
                <div class="card course-videos hidden__mobile">
                  <div class="videos mb-3 ">
                    {/* <Link to="#"> */}
                      <img
                        className="coursecardimg"
                        src={`${config.API}/images/user/${coursedetail.image}`}
                      />
                    {/* </Link> */}
                  </div>

                  <div
                dangerouslySetInnerHTML={{ __html: coursedetail.description }}
              ></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="course-foundation">
          <Container>
            <div class="ck_default_style1">
              <div className="chemistry1">
                <strong>{coursedetail.title}</strong>
              </div>

              {/* <div className="chemistry1">
                <strong>Course Validity</strong>: <strong>Till Exam</strong>
              </div> */}
              <div
                dangerouslySetInnerHTML={{ __html: coursedetail.introduction }}
              ></div>
            </div>
          </Container>
        </div>

        <div>
          {(coursedetail.type == "62cd0cb71538ec3c90ab3c75" ||
            coursedetail.type == "62ce648ce201276788868b3e") && (
            <Container>
              <div className="freedemozz">
              <h1  style={{ color: "black", fontSize:"24px", fontWeight:"500" }}>Free Demo</h1>
                <h3 style={{ color: "#3498db", marginTop: "10px", fontSize: "18px" }} > {errordemovideos} </h3>
              </div>
              <Row>
                {
                  demovideos.map((e) => (
                    <Col lg={3} md={6}>
                      <Card className="filter-course">
                      
                         <Plyr source={  {type: "video",
                                 sources: [
                                   {
                                     src:`${config.API}/images/user/${e.file}`,

                                   }
                                 ]}} />
          
                        <Card.Body>
                          <Card.Title className="filter-title">
                            {e.title}{" "}
                          </Card.Title>
                          <Card.Text className="filter-para">
                            <p>{e.description} </p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>

              {/* <h3>{errordemopdfs}</h3> */}

              {/* <h3>Free Pdf</h3> */}

              {/* <Row>
                {demopdfs.length &&
                  demopdfs.map((e) => (
                    <Col lg={3} md={6}>
                      <Card className="filter-course"> */}
                      
                        {/* <PDFViewer
                          width="50%"
                          height="50%"
                          document={{
                          url:`${config.API}/images/user/${e.file}`
                          }}
                         
                        /> */}
                        {/* <Card.Body>
                          <Card.Title className="filter-title">
                            {e.title}{" "}
                          </Card.Title>
                          <Card.Text className="filter-para">
                            <p>{e.description} </p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row> */}

              


              

              
            </Container>
          )}
        </div>
      </div>
    </>
  );
}

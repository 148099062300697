import React from 'react';
import './teacherfollowing.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'

export default function Teacherfollowing() {



    return (

        <>
            
            <div className='allfollow'>

                <div class="followerList border p-3">

                    <div class="notification__list card ooo">
                        <ul class="list-group ">
                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Andrea</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Following</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Martin</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Following</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Dhanya</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Following</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Arun vijay</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Following</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">John</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Following</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Joseph</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Following</button>
                                </span>

                            </li>


                        </ul>

                    </div>

                </div>

            </div>
        </>


    );
}
import React, { useState, useEffect } from "react";
import './privacy.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { privacy  } from "./../../actions/users";


export default function Privacy() {

    const [privacypolicy, setprivacypolicy] = useState([]);

    const getUserListdata = async () => {
        var test = await privacy();
        console.log(test,"cccc")
        
        setprivacypolicy(test.privacy);
       
        
    }
    
        useEffect(() => {
          //logout(history)
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          getUserListdata();
        }, []);



    return (
        <>

            <div className='phoneheader allbody'>


                <div className='learnbanner'>

                    <Container>

                        <div className='banner-title'>
                            <h2 className="privacy-h2">Privacy Policy</h2>
                           
                        </div>

                    </Container>

                </div>


                <div className='privacycontent'>

                    <Container>

                        <div >
                            <p><div dangerouslySetInnerHTML={{ __html: privacypolicy.privacypolicy }} /></p>
                        </div>

                    </Container>

                </div>
               





              





            </div>
        </>

    );
}
import React, { useEffect,useState } from 'react';
// import { Routes, Switch, Route, BrowserRouter,useNavigate } from "react-router-dom";
import { Routes, Route, Navigate, useLocation,useNavigate,BrowserRouter } from "react-router-dom";
import './App.css';
import Home from './Pages/Home/home'

import Header from './Pages/Header/header';
import Footer from './Pages/Footer/footer'
import Onlinecourse from './Pages/Courselist/onlinecourse';
import Examcourse from './Pages/Courselist/examscourse';
import Buycourse from './Pages/Buycourse/buycourse';
import Freestuff from './Pages/Freestuff/freestuff';
import Freelearning from './Pages/Freelearning/freelearning';
import Freesublearning from './Pages/Freesublearning/freesublearning';
import Freevideo from './Pages/Freevideo/freevideo';


import Freeresource from './Pages/Freeresource/freeresource';
import Freeresourceneet from './Pages/Freeresource/neet';
import Freeresourcejeemain from './Pages/Freeresource/jeemain';
import Freeresourcejeeadvance from './Pages/Freeresource/jeeadvance';
import Freeresourcecbse from './Pages/Freeresource/cbse';
import Freeresourcefoundation from './Pages/Freeresource/foundation';
import Freeresourcetnboard from './Pages/Freeresource/tnboard';

import Feed from './Pages/Feed/feed';
import Notes from './Pages/Myprofile/mynotes';

import Myprofile from './Pages/Myprofile/myprofile';
import Faq from './Pages/Myprofile/faq';
import Faq1 from './Pages/Myprofile/faq1';
import Wallet from './Pages/Myprofile/mywallet';
import Following from './Pages/Myprofile/myfollowing';
import Follower from './Pages/Myprofile/myfollowers';
import Exam from './Pages/Myprofile/myexamselection';
import Notification from './Pages/Myprofile/notification';
import Mycourses from './Pages/Myprofile/mycourses';
import Mypost from './Pages/Myprofile/mypost';
import Myedit from './Pages/Myprofile/myedit';

import Ourexpert from './Pages/Ourexperts/ourexperts';
import Teacher from './Pages/Ourexperts/teacherprofile';
import Coursewelcome from './Pages/coursewelcome/coursewelcome';
import Courseintro from './Pages/courseintro/courseintro';
import Coursetopic from './Pages/coursetopic/coursetopic';
import Coursecontent from './Pages/coursecontent/coursecontent';

import Zoompage from './Pages/livecourse/zoomvideo';

import Directwelcome from './Pages/directwelcome/coursewelcome';

import Livewelcome from './Pages/livewelcome/coursewelcome';

import DirectSchedule from './Pages/directSchedule/schedule';

import Transaction from './Pages/Transaction/transaction';

import Testcontent from './Pages/Testseries/testseries';
import Testintro from './Pages/Testintro/testintro';
import Testattempt from './Pages/Testattempt/testattempt';
import Freetestattempt from './Pages/Testattempt/freetestattempt';

import Teststart from './Pages/Teststart/teststart';
import Testclick from './Pages/Testclick/testclick';

import Testonline from './Pages/Testonline/testonline';
import Testresult from './Pages/Testresult/testresult';

import Direct from './Pages/Direct/direct';
import Schedule from './Pages/Schedule/schedule';


import Privacy from './Pages/Privacy/privacy';
import Terms from './Pages/Terms/terms';
import Aboutus from './Pages/Aboutus/aboutus';

import Forget from './Pages/Forget/forget';
import Agentregistration from './Pages/Agentregistration/agentregistration';
import Agentsignin from './Pages/Agentsignin/agentsignin';
import Agentdashboard from './Pages/agentdashboard/mywallet';
import Userviaagent from './Pages/Userviaagent/userviaagent';
import Changepassword from './Pages/Forget/Changepassword';
import Subcoursecontent from './Pages/subcoursecontent/subcoursecontent';

import Receipt from './Pages/Receipt/receipt';
import Myrefers from './Pages/Myprofile/myrefers';
import { checkuser,checkuser1 } from "./actions/users";
export default function App() {
 
  

  // const history = useNavigate();
  

  useEffect(()=>{
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  },[])

 
//  const checkadmin = async() =>{
//   const user = await checkuser();
//   if("error" in user){
//     localStorage.removeItem("user_token");
//     // window.location.reload();
   
  
//     // history("/");
   
//   }  
//   console.log(user,"useruseruseruser")
  
//  }

//  const checkadmin1 = async() =>{
//   const user = await checkuser1();
//   if(user.success==false){
//     localStorage.removeItem("user_token");
    
   
  
//     // history("/");
   
//   }  
//   console.log(user,"useruseruseruser")
//  }
  // Setting the dir attribute for the body element
 

  useEffect(() => {

    // checkadmin()
    // checkadmin1()
  }, []);
  return (
    <div>
      <BrowserRouter >
        <Header />


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/forgetpassword" element={< Forget />} />
          <Route path="/agentregistration" element={< Agentregistration />} />
          <Route path="/agentsignin" element={< Agentsignin />} />
          <Route path="/agentdashboard" element={< Agentdashboard />} />
          <Route path="/userviaagent/:id" element={< Userviaagent />} />
          <Route path="/changepassword/:userId" element={< Changepassword />} />
          <Route path="/courses/:type" element={< Onlinecourse />} />
          <Route path="/exam/:exam" element={< Examcourse />} />
          <Route path="/buycourse/:ic_id" element={< Buycourse />} />
          <Route path="/coursewelcome/:ic_id" element={< Coursewelcome />} />
          <Route path="/directwelcome/:ic_id" element={< Directwelcome />} />

          <Route path="/livewelcome/:ic_id" element={<Livewelcome />} />

          <Route path="/courseintro/:ic_id" element={< Courseintro />} />
          <Route path="/coursetopic/:ic_id" element={< Coursetopic />} />
          <Route path="/coursecontent/:t_id" element={<Coursecontent />} />
          <Route path="/freestuff" element={< Freestuff />} />
          <Route path="/schedule/:ic_id" element={< Schedule />} />
          <Route path="/directschedule/:ic_id" element={< DirectSchedule />} />
          <Route path="/zoomvideo" element={< Zoompage />} />
         
          <Route path="/freelearning/:ic_id" element={< Freelearning />} />
          <Route path="/freesublearning/:ic_id" element={< Freesublearning />} />
          <Route path="/freevideo/:t_id" element={< Freevideo />} />
          <Route path="/freevideo/:t_id" element={< Freevideo />} />

          <Route path="/freeresource/:id" element={< Freeresource />} />
          <Route path="/freeresource-NEET" element={< Freeresourceneet />} />
          <Route path="/freeresource-JEEMAIN" element={< Freeresourcejeemain />} />
          <Route path="/freeresource-JEEADVANCE" element={< Freeresourcejeeadvance />} />
          <Route path="/freeresource-CBSE" element={< Freeresourcecbse />} />
          <Route path="/freeresource-FOUNDATION" element={< Freeresourcefoundation />} />
          <Route path="/freeresource-TNBOARD" element={< Freeresourcetnboard />} />
          <Route path="/subcoursecontent/:t_id" element={<Subcoursecontent />} />

          <Route path="/feed" element={< Feed />} />

          <Route path="/my-profile" element={< Myprofile />} />
          <Route path="/my-profile-faq" element={< Faq />} />
          <Route path="/my-profile-wallet" element={< Wallet />} />
          <Route path="/my-profile-myfollowers" element={< Follower />} />
          <Route path="/my-profile-myfollowing" element={< Following />} />
          <Route path="/my-profile-myexamselection" element={< Exam />} />
          <Route path="/my-profile-notification" element={< Notification />} />
          <Route path="/my-profile-notes" element={< Notes />} />
          <Route path="/my-profile-courses" element={< Mycourses />} />
          <Route path="/my-profile-post" element={< Mypost />} />
          <Route path="/my-profile-edit" element={< Myedit />} />

          <Route path="/ourexpert" element={< Ourexpert />} />
          <Route path="/userprofile" element={< Teacher />} />

          <Route path="/transaction" element={< Transaction />} />

          <Route path="/testcourse-content/:ic_id" element={< Testcontent />} />
          <Route path="/testintro/:ic_id" element={< Testintro />} />
          <Route path="/testattempt/:id" element={< Testattempt />} />
          <Route path="/freetestattempt/:id" element={< Freetestattempt />} />

          <Route path="/teststart/:id" element={< Teststart />} />
          <Route path="/testclick/:id/:test_id" element={< Testclick />} />

          <Route path="/testonline/:id" element={< Testonline />} />

          <Route path="/testresult/:id" element={< Testresult />} />

          <Route path="/privacy-policy" element={< Privacy />} />
          <Route path="/terms-condition" element={< Terms />} />
          <Route path="/about-us" element={< Aboutus />} />

          <Route path="/receipt" element={< Receipt />} />

          <Route path="/direct" element={< Direct />} />
          <Route path="/my-profile-yourrefers" element={< Myrefers />} />

          <Route path="/faq" element={< Faq1 />} />

        </Routes>
        <Footer />
       

      </BrowserRouter>

     
    </div>
  );
}



import React, { useState, useEffect } from "react";
import './forget.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import isEmpty from "../../lib/isEmpty";
import { changePassword } from "../../actions/users";
import { useHistory, useParams,useNavigate } from "react-router-dom";

import swal from "sweetalert";

const initialFormValue = {   
    password: "",    
  };
export default function Forget() {
  const { userId } = useParams();
    const [formValue, setFormValue] = useState(initialFormValue);
    const history = useNavigate();
    const [validateError, setValidateError] = useState({});




    

    const onChange = (e) => {
        e.preventDefault();
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        console.log(formValue);
    
        
        //setValidateError(formData)
      };


      const {
      
      password,
      
    
      } = formValue;



      const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        console.log("saran");
        
        console.log(formValue);
        let reqData = {
           
            password,
            userId,
           
        };
        // console.log(reqData,"sss")
        // let result = await changePassword(reqData);

        // if (result) {
        //   history("/");
        //   swal("Your password has been changed successfully");
        // } else {
        //   swal("Please try again");
        // }
        let { error } = await changePassword(reqData);
        console.log("error", error);
        if (!error) {
          history("/");
          swal("Your password has been changed successfully");
         
        } 
        else {
          setValidateError(error);
        }

       
      
       
        
       
      };
    



    return (
        <>

         <div className='allbody'>

<Container>
 
 <div className="trip">
 <div className="forgetzzz">
    
    <h5 className="pass">Forget Password</h5>
    <form id="sbform" method="post">
                                        <div class="row">
                                           
                                           
                                            <input type="hidden" name="password" id="password" value="password" />
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">New password</label>
                                                <input type="password" class="form-control" name="password" id="password" onChange={onChange} value={password} placeholder="Password" />
                                                {validateError.password && (
                                                          <span style={{color:"red",fontSize:"10px"}}>
                                                            {validateError.password}
                                                          </span>
                                                        )}
                                                
                                            </div>
                                          
                                        </div>


                                    

                                       




                                        <button type="button" class="btn btn-primary mb-3  mt-3 m-auto" id="save_profile" onClick={handleFormSubmit}> Save Changes </button>
                                        

                                    </form>

  </div>
  </div>

</Container>


         </div>



</>

);
}
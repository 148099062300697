import React, { useState, useEffect } from "react";
import { NavLink,useNavigate,useLocation,NavNavLink } from "react-router-dom";
import { getdetails,checkuser } from "../../actions/users";
import config from "../../lib/config";
import { useParams } from "react-router-dom"; 
import { FaEdit } from 'react-icons/fa';
import { FaBookReader } from 'react-icons/fa'; 
import { FaWallet } from 'react-icons/fa';
import { MdNotifications } from 'react-icons/md'; 
import { FaQuestion } from 'react-icons/fa';
const initialFormValue = {
    
    Photofile3:"",
   
    
  };

export default function Prosidabar() {
    const [formValue, setFormValue] = useState(initialFormValue);
    const { id } = useParams();
    const history = useNavigate();


    const getUserListdata = async () => {
        var test = await getdetails(id);
    console.log(test,"1234")
    let data = {};
 
    data["name"] = test.detail.name;
      data["Photofile3"]=test.detail.profileimage;
     


    
  
    // formdata["Photofile"] = test.userValue.image;
    setFormValue(data);





  
        
    };

    const {
       
        name,
       
      } = formValue;


    const {
       
        Photofile3,
        
      } = formValue;

      const checkadmin = async() =>{
        const user = await checkuser();
        if("error" in user){
          localStorage.removeItem("user_token");
         
          // window.location.reload();
         
        
          history("/");
         
        }  
        console.log(user,"useruseruseruser")
        
       }

      useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();
        checkadmin();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);

    return(
        <div className="profile__sidebar card">
        <div className="profile__header">

            <div className="profile__img">
            {Photofile3?
                <img style={{ width:"100%" }}  src= {config.API + "/images/user/" + Photofile3} className="img-fluid profile___shap" alt="" />:<img style={{ width:"100%" }} src= {config.API + "/images/user/" + 'IMAGE-1674666998165.png'}/>}


            </div>
            <div className="text-center">
                <h4 className="profile__name">{name}</h4>
                {/* <p className="profile__mail">CP Roll : 105918 </p> */}
                <p className="profile__mail">{localStorage.email}</p>
            </div>

        </div>



        <div className="profile__body">
            <div className="list-group p-3">
                {/* <NavLink to="/my-profile-post" className="list-group-item list-group-item-action" activeClassName="active"> <i className="fas fa-blog">  </i> <span> My Post  </span> </NavLink> */}
                {/* <NavLink to="/my-profile-notes" className="list-group-item list-group-item-action " activeClassName="active"> <i className="far fa-file-alt">  </i> <span> My Notes  </span> </NavLink> */}
                    <NavLink to="/my-profile-edit" className="list-group-item list-group-item-action" activeClassName="active"> <FaEdit className="alliconclicks" />  <span> Edit Profile </span> </NavLink>
                    <NavLink to="/my-profile-courses" className="list-group-item list-group-item-action " activeClassName="active"> <FaBookReader className="alliconclicks" />  <span> My Purchased Courses  </span> </NavLink>
                    <NavLink to="/my-profile-wallet" className="list-group-item list-group-item-action " activeClassName="active"> <FaWallet className="alliconclicks" />  <span> My Wallet  </span> </NavLink>
                {/* <NavLink to="/my-profile-myfollowers" className="list-group-item list-group-item-action " activeClassName="active"> <i className="far fa-heart">  </i> <span> My Followers  </span> </NavLink>
                <NavLink to="/my-profile-myfollowing" className="list-group-item list-group-item-action " activeClassName="active"> <i className="far fas fa-user-plus"> </i> <span> My Following  </span> </NavLink> */}
                {/* <NavLink to="/my-profile-myexamselection" className="list-group-item list-group-item-action " activeClassName="active"> <i className="fas fa-vote-yea">  </i> <span> My Exam Selection  </span> </NavLink> */}
                    <NavLink to="/my-profile-notification" className="list-group-item list-group-item-action " activeClassName="active"> <MdNotifications className="alliconclicks" style={{ fontSize:"24px" }} />  <span> Notifications  </span> </NavLink>
                    <NavLink to="/my-profile-faq" className="list-group-item list-group-item-action " activeClassName="active"> <FaQuestion className="alliconclicks" />  <span> Faq's  </span> </NavLink>
                

            </div>
        </div>

    </div>
    )
}

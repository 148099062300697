import React from 'react';
import './receipt.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';


export default function Receipt() {



    return (
        <>

            <div className='allbody'>


               
                 <div className='receipt'>

                  
                    <Container>
                        <div className='receiptzz'>

                            <div className='re1'>
                            <Row>
                                <Col md={6}>
                                    <div>
                                    <h4>MAREVEL CLASSES</h4>
                                    <p>917092482482</p>
                                    <p>marvelneet@gmail.com</p>
                                    </div>

                                    <div>
                                        <p>Payment Method: online</p>
                                        <p>SAC Code:998314</p>
                                        <p>Valid through:2022/7/26</p>
                                    </div>

                                  </Col>


                                    <Col md={6}>
                                        <div className='invoicee'>
                                    <div>
                                        <img className='righttt' src={require('../../img/maricon.jpeg')} />
                                        <h4>INVOICE</h4>
                                    </div>
                                    <div>
                                        <p>Invoice #:DZRUJ-ST-A0061</p>
                                        <p>Invoice Date: 2022/7/26</p>
                                        <p>Bill To: Radhakrishnan</p>
                                        <p>Mobile No: 6381879400</p>
                                            </div>
                                        </div>
                                </Col>

                           
                            </Row>

                            </div>



                    <div className='idescript'>
                     
                            
                            <div className='allamounts'>
                                <div className='am1'>
                                <ul>
                                    <li>
                                        <p>#Item & Description</p>
                                        <p>Amount</p>
                                    </li>
                                    </ul>
                                </div>

                                {/* <div className='am2'>
                                    <ul>
                                        <li>
                                            <p>Test Course</p>
                                            <p><i class="fa fa-rupee-sign"></i> 1.00</p>
                                        </li>
                                    </ul>
                                </div> */}

                                <div className='am3'>
                                    <ul>
                                        <li>
                                            <p>Test Course</p>
                                            <p><i class="fa fa-rupee-sign"></i> 1.00</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Sub Total</p>
                                            <p><i class="fa fa-rupee-sign"></i> 0.85</p>
                                        </li>
                                        <li>
                                            <p>CGST(9%)</p>
                                            <p><i class="fa fa-rupee-sign"></i> 0.08</p>
                                        </li>
                                        <li>
                                            <p>SGST(9%)</p>
                                            <p><i class="fa fa-rupee-sign"></i> 0.07</p>
                                        </li>
                                    </ul>


                                    <ul>
                                        <li>
                                            <p>Internet Handling Fees (Exclusive)</p>
                                            <p><i class="fa fa-rupee-sign"></i> 0.03</p>
                                        </li>
                                        <li>
                                            <p>CGST(9%)</p>
                                            <p><i class="fa fa-rupee-sign"></i> 0.00</p>
                                        </li>
                                        <li>
                                            <p>SGST(9%)</p>
                                            <p><i class="fa fa-rupee-sign"></i> 0.00</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Total</p>
                                            <p><i class="fa fa-rupee-sign"></i> 1.03</p>
                                        </li>
                                        <li>
                                            <p>Amount Received</p>
                                            <p><i class="fa fa-rupee-sign"></i> 1.03</p>
                                        </li>
                                        <li>
                                            <p>Amount Received in Words</p>
                                            <p>One Rupees and Three paise</p>
                                        </li>
                                    </ul>
                                </div>

                                <div className='am4'>
                                    <h5>Notes:</h5>
                                    <p>This is a computer generated pay receipt,does not require signature.</p>
                                </div>

                           </div>

                     
                            </div>
                            
                            </div>

                </Container>

                </div>










            </div>
        </>

    );
}
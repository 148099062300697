import React, {useEffect,useState} from 'react';
import config from "../../lib/config";
import './schedule.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';

import Upcoming from '../../Components/Upcoming/upcoming'
import Completded from '../../Components/Completed/completed'

import { upcomingSchedules,completedSchedules,getnotes } from "../../actions/users";

import { useHistory, useParams, Link,useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

export default function Schedule() {
    
    const { ic_id } = useParams();
    const [userdet, setUserDet] = useState([]);
    const [userdet1, setUserDet1] = useState([]);
    const [meetid, setmeetid] = useState({});
    const [materialfilename, setmaterialfilename] = useState("");
    const history = useNavigate();

    // const openWindow = () => {
    //     setOpen(!isOpen);
    //   };


    
    // const download = () => {
    //     history("/images/user"+materialfilename);
        
    //   };

    const getUserListdata = async () => {
        // alert("dsdsdsd")
        var test7 = await upcomingSchedules(ic_id);
        setUserDet(test7.userValue);
        setmeetid(test7.userValue[0]);
        console.log(test7.userValue[0],"test")
    
        var test = await completedSchedules(ic_id);
        setUserDet1(test.userValue);
          console.log("initial_id",test.userValue[0])
        var test8 = await getnotes(test7.userValue[0]._id);
        
        var data = test8.userValue
        console.log(data,"datass")
        if(data){
        setmaterialfilename(data)
        }
        // setmaterialfilename(test.userValue);
      };
    
      useEffect(() => {
        getUserListdata();
      }, []);

    return (
        <>
        
            <div className='allbody'>
          

{/* <button  onClick={openWindow}>
                
               gjhjhjg
            </button> */}
                <div className='fearless'>
                    <Container> 


                        <div style={{ marginBottom: "10px" }}>
                           <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                        </div>


                    
                    <div className='ags'> 
                  

{materialfilename?  

<a target="_blank" href={config.API +"/images/user/"+materialfilename}> <button  type="button" class="btn btn-primary"> Open materials </button> </a>:null}

                            <div id="exTab1" style={{ marginTop:"20px" }}>
                                <ul class="nav nav-pills">
                                {/* <li class="active">
                                        <button  onClick={download}>Download</button>
                                    </li> */}
                                    
                               
                                    <li class="active">
                                        <a href="#aaa" data-toggle="tab">Upcoming</a>
                                    </li>
                                    <li><a href="#bbb" data-toggle="tab">Completed</a>
                                    </li>

                                </ul>

                                <div class="tab-content clearfix">
                                    <div class="tab-pane active" id="aaa">
                                        <Upcoming onTimeExpire={()=>getUserListdata()} schedules={userdet} />
                                    </div>
                                    <div class="tab-pane" id="bbb">
                                        <Completded schedules={userdet1} />
                                    </div>

                                </div>
                         
                        </div>

                  
                        </div>

                    </Container>
                </div>

            </div>


        </>


    );
}
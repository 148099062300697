import React from 'react';
import './freevideo.css'
import { Container, Row, Col, Card, Button, Tab, Tabs, Nav, NavItem } from 'react-bootstrap'

import Learningvideo from '../../Components/Learningvideo/learningvideo'
import Learningpdf from '../../Components/Learningpdf/learningpdf'

import Tabpara from '../../Components/Tabpara/tappara'

import { useNavigate, useParams,Link } from "react-router-dom";

export default function Freevideo() {

    const { t_id } = useParams();

    return (
         <>
            <div className='allbody'>
                
                <div>
                  {/* <Container>
                        <Tabs defaultActiveKey={2} id="uncontrolled-tab-example">
                            <Tab eventKey={1} title="Video">
                                <Learningvideo/>
                            </Tab>
                            <Tab eventKey={2} title="PDF">
                                <Learningpdf />
                            </Tab>
                        </Tabs>
                    </Container> */}

                    <div className='tabing'>
                        <div className='container'>
                            <div id="exTab1" >
                                <ul class="nav nav-pills">
                                    <li class="active">
                                        <Link to="#1a" data-toggle="tab">Video</Link>
                                    </li>
                                    <li><Link to="#2a" data-toggle="tab">PDF</Link>
                                    </li>
                                   
                                </ul>

                                <div class="tab-content clearfix">
                                    <div class="tab-pane active" id="1a">
                                        <Learningvideo topic_id={t_id} />
                                    </div>
                                    <div class="tab-pane" id="2a">
                                        <Learningpdf />
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
         
           </div>
        </>

        );
}
import React,{useState,useEffect} from 'react';
import './mycourses.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { purchasedcourse } from '../../actions/users';
import config from '../../lib/config'
import { useHistory, useParams,Link } from "react-router-dom";
import Sidebar from './sidbar';



export default function Mycourses() {

    const[courselist,setcourselist]=useState([])
    const userData =async()=>{

     const apicourselist = await purchasedcourse();
     console.log(apicourselist,"bbb")
     if("error" in apicourselist){
        console.log("Internal server error");
     }else{

     setcourselist(apicourselist.userValue)
     }
    }

    const buycoursepage = (type, id) => {
        // console.log(type, "typetypetypetypetype");
        switch (type) {
          case "62cd0ca31538ec3c90ab3c73":{ //live
            return "/livewelcome/" + id;
            break;}
          case "62cd0cb71538ec3c90ab3c75":{//recorded
            return "/coursewelcome/" + id;
            break;
          } 
          case "62ce648ce201276788868b3e":{//Micro
            return "/coursewelcome/" + id;
            break;
          } 
          case "62cd0caf1538ec3c90ab3c74": {
            //Direct
            return "/directwelcome/" + id;
            break;
          }
          case "62ce6480e201276788868b3d": {//test-series
            return "/testcourse-content/" + id;
            break;}
    
          default:
            return "#";
        }
      };

    useEffect(()=>{
        userData();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])
    return (

        <>
            <div className='allbody'>
            <div className='all-courses' >

                
                <Container>
                    <Row>

                        <div class="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>

                        


                        <div className="col-lg-8 col-md-8 col-sm-12">

                            <div className='row'>
                            { courselist.length ? courselist.map(e =>
                            <Col key={e.course._id} lg={4}>
                                <Card className='card-course co-card'>
                                    <Card.Img className='course-img' variant="top" src={`${config.API}/images/user/${e.course.image}`} />
                                    <Card.Body>
                                        <Card.Title>{e.course.title}</Card.Title>
                                        <Card.Text>
                                        <div dangerouslySetInnerHTML={{ __html:e.course.description.slice(0, 100) }} />
                                      
                                        </Card.Text>
                                        {/* <Button variant="primary">Go somewhere</Button> */}
                                            <p className='rate'>Rs.{e.course.price}</p>
                                            {/* <h5 className='validity'>Validity : 365 days </h5> */}
                                           <Link to={buycoursepage(e.course.type,e.course._id)}><Button className='viewdetail' variant="primary">View Details</Button>{' '}</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            ) :<img style={{ margin:"auto" }} src={require("../../img/noco.jpg")}/>}
                            
{/*                             
                             <div>
                          <img style={{ margin:"auto" }} src={require("../../img/no.png")}/>
                            <p>No course found,Please purchase a course</p>
                          </div> */}

                            

                            </div>
                            </div>

                        </Row>


                    </Container>

                
       </div>

</div>
        </>

    )
};
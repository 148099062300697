import React,{useState,useEffect} from 'react';
import './testintro.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate, useParams,Link } from "react-router-dom";
import { verifycourse,getcoursedata } from '../../actions/users';
export default function Testintro() {

    const[coursedetail,setcoursedetail]=useState({})
    const { ic_id } = useParams();
    const history = useNavigate();

    const verifyuser =async()=>{

        const verify = await verifycourse(ic_id);
        if("error" in verify){
           alert("Internal server error");
        }else{
   if(!verify.purchased){
    history("/my-profile-courses");
   }
        //    setpurchasedstatus(verify.purchased)
        }
       
      }
      
      const userData =async()=>{
        const cousrsed = await getcoursedata(ic_id);
        if("error" in cousrsed){
           alert("Internal server error");
        }else{
   
           setcoursedetail(cousrsed.userValue)

        }


      }
   
       useEffect(()=>{
           verifyuser();
           userData();
       },[]);

    return (
        <>
            
            <div className='allbody'>
            <div className='t-intro'>
          
                <Container>

                    <div className="intro_header">
                        <h2>{coursedetail.title} </h2>
                        <nav className="nav_breadcrumb mb-5" aria-label="breadcrumb">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item ">
                                    <Button onClick={()=>history(-1)} className="breadcrumb_back"> <BsFillArrowLeftCircleFill style={{ fontSize:"22px", marginTop:"2px" }}/>  </Button>
                                </li>
                                {/* <li className="breadcrumb-item active" aria-current="page">Intro</li> */}
                            </ul>
                        </nav>
                    </div>


                    <div>

                        <div className="intro_content ck_default_style">
                            <div className='testline'><span ><span ><strong>{coursedetail.title}<br></br>
                                *Remaining Papers will be attach soon*</strong></span></span></div>

                            <p style={{ marginTop:"12px" }}><span ><span >As the NEET 2022 session is approaching its time to give a final touch to the performance. Career Point brings NEET previous year question papers test series for the aspirants to finalize their NEET 2022 performance. </span></span></p>

                            <p><span ><span ><strong>What is NEET Previous Year Test Series? </strong></span></span></p>

                            <p><span ><span >The test series comprises of previous year NEET/AIIMS question papers.&nbsp;In this test series NEET aspirants will get all the question papers, there are total 10+ NEET Previous Year Papers. These&nbsp;papers will help to boost up your performance, area of improvements and also help in overcome the exam fear. </span></span></p>

                            <p><span ><span >This year NEET 2022 will held on 17th July, 2022.</span></span></p>
                        </div>

                    </div>


                </Container>


                </div>
                
                </div>
        </>

    );
}
import React, { useState, useEffect } from "react";
import "./testseries.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BsBookFill } from "react-icons/bs";
import { BsFileCheckFill } from "react-icons/bs";
import { useNavigate, useParams, Link } from "react-router-dom";
import { verifycourse, getcoursedata } from "../../actions/users";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';


export default function Coursewelcome() {
  const [coursedetail, setcoursedetail] = useState({});
  const [nocourse, setnocourse] = useState("");
  const { ic_id } = useParams();
  const history = useNavigate();

  const verifyuser = async () => {
    const verify = await verifycourse(ic_id);
    if ("error" in verify) {
      alert("Internal server error");
    } else {
      if (!verify.purchased) {
        history("/my-profile-courses");
      }
      //    setpurchasedstatus(verify.purchased)
    }
  };

  const userData = async () => {
    const cousrsed = await getcoursedata(ic_id);
    console.log(cousrsed.statement,"sandy")
    if(cousrsed.statement== false){
       
      setnocourse(false);
    }
    else{
      setnocourse(true);
    }
    console.log(cousrsed,"hello")
    if ("error" in cousrsed) {
      alert("Internal server error");
    } else {
      setcoursedetail(cousrsed.userValue);
    }
  };

  useEffect(() => {
    verifyuser();
    userData();
  }, []);
  return (
    <>
      <div className="phoneheader allbody">
        <div className="learnbanner">
          <Container>
            <div className="banner-title">
              {nocourse == true ?
              <>
              <h2 style={{ fontSize:"30px", fontWeight:"600" }}>{!coursedetail.title?"Your course has been expired":coursedetail.title}</h2>
              <h4 className="sub-para">{!coursedetail.title?"Your course has been expired":coursedetail.title}</h4>
              </>:null}
            </div>
          </Container>
        </div>
    
        {nocourse == true ? 
        <div className="subject">
          <Container>

               <div style={{ marginBottom: "10px" }}>
                            <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                </div>

            <Row>
              <Col md={4} lg={4} sm={6} className="mb4">
                <div className="shadow bg-white p-3">
                  <Link className="atag" to={"/courseintro/" + ic_id}>
                    <div className="heading-discription d-flex align-items-center">
                      <span className="footerSocialLink1">
                        <BsBookFill className="i-book" />{" "}
                      </span>
                      <h4 className="m-0">Intro</h4>
                    </div>
                  </Link>
                </div>
              </Col>

              <Col md={4} lg={4} sm={6} className="mb4">
                <div className="shadow bg-white p-3">
                  <Link className="atag" to={"/directschedule/" + ic_id}>
                    <div className="heading-discription d-flex align-items-center">
                      <span className="footerSocialLink1">
                        <BsFileCheckFill className="i-book" />
                      </span>
                      <h4 className="m-0">Schedules</h4>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col md={4} lg={4} sm={6} className="mb4">
                <div className="shadow bg-white p-3">
                  <Link className="atag" to={"/coursetopic/" + ic_id}>
                    <div className="heading-discription d-flex align-items-center">
                      <span className="footerSocialLink1">
                        <BsFileCheckFill className="i-book" />
                      </span>
                      <h4 className="m-0">Materials</h4>
                    </div>
                  </Link>
                </div>
              </Col>

              <Col md={4} lg={4} sm={6} className="mb4">
                <div className="shadow bg-white p-3">
                  <Link className="atag" to={"/testattempt/" + ic_id}>
                    <div className="heading-discription d-flex align-items-center">
                      <span className="footerSocialLink1">
                        <BsFileCheckFill className="i-book" />
                      </span>
                      <h4 className="m-0">Tests</h4>
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>:<div style={{ textAlign:"center" }}>
        <img style={{ margin: "auto" }} src={require("../../img/courseexpired.png")} />
        </div>
        } 
        
        {/* // <span style={{ color: "red", fontSize: "30px" }}>Your course has been expired,Please purchase the course again</span> */}

        {/* <Navbar key={'sm'} bg="light" expand={'sm'} className="mb-3">
                            <Container fluid>
                                <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'sm'}`} />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${'sm'}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${'sm'}`}
                                    placement="end"
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'sm'}`}>
                                            Offcanvas
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3">
                                            <Nav.Link href="#action1">Home</Nav.Link>
                                            <Nav.Link href="#action2">Link</Nav.Link>
                                            <NavDropdown
                                                title="Dropdown"
                                                id={`offcanvasNavbarDropdown-expand-${'sm'}`}
                                            >
                                                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                                <NavDropdown.Item href="#action4">
                                                    Another action
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="#action5">
                                                    Something else here
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </Nav>

                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>
    */}
      </div>
    </>
  );
}

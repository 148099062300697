import React,{useState,useEffect} from 'react';
import './mypost.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'

import { BsThreeDotsVertical } from 'react-icons/bs';
import { BsFillEraserFill } from 'react-icons/bs';
import { BiHide } from 'react-icons/bi';
import { BsQuestionCircle } from 'react-icons/bs';

import { BsFillReplyFill } from 'react-icons/bs';

import { MdDelete } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';

import { FaComments } from 'react-icons/fa';
import Sidebar from './sidbar'
export default function Mypost() {

    document.addEventListener(
        "click",
        function (event) {
            var target = event.target;
            var replyForm;
            if (target.matches("[data-toggle='reply-form']")) {
                replyForm = document.getElementById(target.getAttribute("data-target"));
                replyForm.classList.toggle("d-none");
            }
        },
        false
    );

    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])
    return (

        <>
            <div className='allbody'>
            <div className='noted'>
                <Container>
                    <Row>

                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>




                        <div className="col-lg-8 col-md-8 col-sm-12">

                            <div className="notification__list card">

                                <ul className="list-group ">
                                    <li className="list-group-item content-center">
                                        <a href="#" className="notification-event content-center">
                                            <div className="author-thumb">
                                                <img src={require('../../img/deal.jpg')} alt="" />
                                            </div>
                                            <div className="notification-event-content">
                                                <h5> <span className="hnotification-friend">Sakthi</span>
                                                </h5>
                                                <p>2 days ago</p>
                                            </div>
                                        </a>
                                        <span id="followunfollow_1093">
                                            <div className="dropdown dropdown__right">

                                                <label className="exp_men pr-2" for=""><strong>
                                                    Expert
                                                </strong>  </label>
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-light btn-sm rounded threedots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <BsThreeDotsVertical /> </button>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <button className="dropdown-item" type="button"><BsFillEraserFill /> Remove from my notes</button>
                                                        <button className="dropdown-item" type="button"><BiHide /> Hide Post</button>
                                                        <button className="dropdown-item" type="button"><BsQuestionCircle /> Ask a Query</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>

                                    </li>
                                </ul>
                                <p style={{ padding: "8px 16px" }}>How to Join a online class</p>
                               
                                <nav className="nav nav-pills nav-fill post-additional-info-mini">
                                    <a href="#" id="comment_count82104"> 6 Comments <span>  </span> </a>
                                </nav>

                                {/* <nav class="nav nav-pills nav-fill mt-3 post-additional-info"> */}
                                {/* <div>
                                    <button type="button" class="nav-link"> <i class="far fa-comment"></i> <span> Comment </span> </button>
                                    </div> */}
                                <div style={{ padding: "8px 16px" }}>
                                    <button type="button" className='ddd' data-toggle="reply-form" data-target="comment-3-reply-form"><FaComments /> Comments</button>
                                </div>
                                {/* </nav> */}
                                <form method="POST" class="comment-form inline-items reply-form d-none" id="comment-3-reply-form">
                                    <div className="card card-body">
                                        <div className="load_comment82104">
                                            <div className="comments__box ">
                                                <div className="comment-temp">
                                                    <ul className="nav">

                                                       

                                                        <li>
                                                            <div className="comment-item mb-30">
                                                                 
                                                                    <div class="">
                                                                       
                                                                            <ul className="nav">
                                                                                <li>
                                                                                    <div className="comment-item mb-30">
                                                                                        <div className="comt-user">
                                                                                            <img src={require('../../img/mission.png')} alt="" />
                                                                                        </div>
                                                                                        <div className="comt-detail">
                                                                                            <div className="para-center">
                                                                                                <div className="comtuser-name">
                                                                                                    <h5>A.R.rahman</h5>
                                                                                                    <h6>2 days ago</h6>
                                                                                                </div>

                                                                                                <div className="more more2 dropdown dropdown__right">
                                                                                                    <div className="btn-group">
                                                                                                        <button type="button" className="btn btn-light btn-sm rounded some" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <BsThreeDotsVertical /> </button>
                                                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                                                            <button className="dropdown-item" type="button"><FiEdit /> Edit</button>
                                                                                                            <button className="dropdown-item" type="button"><MdDelete /> Delete</button>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>


                                                                                            </div>
                                                                                            <p>keep it up.</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </li>

                                                                                <li>
                                                                                    <div className="comment-item mb-30" id="div60115">
                                                                                        <div className="comt-user">
                                                                                            <img src={require('../../img/mission.png')} alt="" />
                                                                                        </div>
                                                                                        <div className="comt-detail">
                                                                                            <div className="para-center">
                                                                                                <div className="comtuser-name">
                                                                                                    <h5>A.R.rahman</h5>
                                                                                                    <h6>2 days ago</h6>
                                                                                                </div>

                                                                                                <div className="more more2 dropdown dropdown__right" p-id="82104" comment-id="60115">
                                                                                                    <div className="btn-group">
                                                                                                        <button type="button" className="btn btn-light btn-sm rounded some" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <BsThreeDotsVertical /> </button>
                                                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                                                            <button className="dropdown-item" type="button"><FiEdit /> Edit</button>
                                                                                                            <button className="dropdown-item" type="button"><MdDelete /> Delete</button>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>


                                                                                            </div>
                                                                                            <p>well done!..</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        
                                                                    </div>



                                                                
                                                            </div>


                                                        </li>

                                                    </ul>
                                                </div> 


                                                <form className="comment-form inline-items comments">
                                                    <div className="post__author author vcard inline-items">
                                                        <div className="commentImg">
                                                            <img src={require('../../img/follow2.jpg')} className="img-fluid" alt="author" />
                                                        </div>
                                                        <div className="form-group with-icon-right "><textarea className="form-control" id="commentdata82104" data-gramm="false" wt-ignore-input="true"></textarea></div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button" className="btn btn__comment reply" >Comment</button>
                                                        <button type="button" className="btn reply" >Cancel</button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </div>

                            </div>





                    </Row>

                </Container>

                </div>
                
                </div>
            
        </>


    )
};
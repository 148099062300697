import React, { useState, useEffect } from "react";
import './performance.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';
import { testresult,getscore  } from "./../../actions/users";
import { useNavigate, useParams } from "react-router-dom";
import { testsetname } from "../../actions/users";



export default function Performance() {
    const [testresults, settestresults] = useState(0);
    const [wronganswer, setwronganswer] = useState(0);
    const [noofquestions, setnoofquestions] = useState(0);
    const [noofattempt, setnoofattempt] = useState(0);
    const [mark, setmark] = useState(0);
    const [negativemark, setnegativemark] = useState(0);
    const [score, setscore] = useState([]);
    
    const { id } = useParams();

    const getUserListdata = async () => {
        var test = await testresult(id);
        console.log(test,"ttt")
        
        settestresults(test.testresult);
        setwronganswer(test.wronganswer);


        var test = await testresult(id);
        console.log(test,"ggg")
        
        setnoofquestions(test.noofquestions);

        var test = await testresult(id);
        console.log(test,"ghhgj")
        
        setnoofattempt(test.attempted);

        var test = await getscore(id);
        console.log(test,"31")
        
        setscore(test.score);

        var test = await testsetname(id);
        console.log(test,"311")
        
        setmark(test.testsetname.mark);
        setnegativemark(test.testsetname.negativemark);
        


        

       
        
    }

    const a=testresults*mark-wronganswer
    const b=noofquestions*mark

    useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);


    return (
        <>
            
        
        <div className='result-performance'>
  
                <Container>


                    <Row>
                        {testresults?
                        <div className="col-12">
                            <h4 className="result_heading">Your score</h4>
                            <div className="tab-holder-performance display-inline-flex">
                                <div className="perform-award display-inline-flex1">
                                    <img className="perform-award-img" src={require('../../img/winner (1).png')} />
                                        <div className="padding-top-5">
                                            <span className="big-score ng-binding">{testresults*mark-wronganswer}</span>
                                            <span className="small-score grey-score ng-binding">/{noofquestions*mark}</span>
                                            {/* <span  className="badge badge-sm bg-white text-danger margin-right10 float-right badge-slides ng-binding">Cut-off: 0</span> */}
                                            <p>Score</p>
                                        </div>

                                </div>
                                {/* <div className="perform-award display-inline-flex1 ">
                                    <img className="perform-award-img" src={require('../../img/batch (1).png')} />
                                        <div className="padding-top-5">
                                            <input type="hidden" className="hide_tabs"  autocomplete="off"/>
                                                <span className="big-score hide_tab_rank ng-binding">5</span>
                                                <span className="small-score grey-score hide_tab_rank_total ng-binding">/8</span>
                                                <p>Rank</p>
                                        </div>
                                </div> */}
                                <div className="perform-award display-inline-flex1 ">
                                    <img className="perform-award-img" src={require('../../img/manyperson (1).png')} />
                                        <div className="padding-top-5">
                                            <span className="big-score hide_tab_percentile ng-binding">{a/b*100}%</span>
                                            <p>Percentage</p>
                                        </div>
                                </div>
                                {/* <div className="perform-award display-inline-flex1 ">
                                    <img className="perform-award-img" src={require('../../img/caccuracy (1).png')} />
                                        <div className="padding-top-5">
                                            <span className="big-score ng-binding">30.00%</span>
                                            <p>Accuracy</p>
                                        </div>
                                </div> */}
                                {/* <div className="perform-award display-inline-flex1">
                                    <img className="perform-award-img" src={require('../../img/sandelclk (1).png')} />
                                        <div className="padding-top-5">
                                            <span className="big-score ng-binding">{noofattempt}/</span>
                                            <span className="small-score grey-score ng-binding">{noofquestions}</span>
                                            <p>Attempted</p>
                                        </div>
                                </div> */}
                            </div>
                        </div>:null}
                    </Row>



                    
                   <Row>
                        <div className="col-12">
                            <div className="row margin-top10 ">
                                <div className="col-6 text-center bst-score margin-bottom10" >
                                    {/* <h6 className="total-count-head  text-center ">Best Score</h6>
                                    <h4 className="text-center ng-binding">10.00</h4> */}
                                </div>
                                <div className="col-6 text-center bst-avrg margin-bottom10">
                                    {/* <h6 className="total-count-head"style={{ fontSize:"17px" }} >Average Score</h6>
                                    <h4 className="ng-binding">10.00</h4> */}
                                </div>
                            </div>
                        </div>
                    </Row>

                    
                    <div>
                        <h4 className="lar">Last Attempted Results </h4>
                    </div>
                    
                    <Row>
                       
                        <Col lg={3}>

                          <div className="ree">
                    <div className=" item slid-awad tab-holder-performance">
                
                        <div className="red-gradint slid-head">
                            <div>
                                 <img className="docsdsa" src={require('../../img/test_trophy.png')} />
                            </div>
                            {/* <div className="slider-content-h5" >
                                <h5 className="slider-head">Total Score</h5>
                                <h5>
                                    <span className="big-score ng-binding"></span><span className="small-score ng-binding">{testresults}</span>
                                </h5>
                            </div> */}
                        </div>
                       
                            {/* <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">14.000</span><span className="small-score grey-score ng-binding">/140.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Physics (PART A)</h5> */}

                                
                                </div>
                                
                                
                            {score.length ? 
                            score.map((score) => (
                            <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                <h5 className="margin-bottom10">

                                    <span className="big-score ng-binding">{score.mark*mark-wronganswer}</span><span className="small-score grey-score ng-binding">/{noofquestions*mark}</span>
                                    <span className="badge badge-secondary float-right section-cutoff ng-binding"></span>
                                </h5>
                                {/* <h5 className="slider-head ng-binding">Physics (PART A)</h5> */}
                            </div>)) :
                            <h1>You have not attempted the test till now</h1>
                           
                            }
                    {/* <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                            <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">-4.000</span><span className="small-score grey-score ng-binding">/40.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Physics (PART B)</h5>
                    </div>
                    <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                            <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">0.000</span><span className="small-score grey-score ng-binding">/140.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Chemistry (PART A)</h5>
                    </div>
                    <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                            <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">0.000</span><span className="small-score grey-score ng-binding">/40.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Chemistry (PART B)</h5>
                    </div>
                    <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                            <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">0.000</span><span className="small-score grey-score ng-binding">/140.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Biology (PART A1)</h5>
                    </div>
                    <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                            <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">0.000</span><span className="small-score grey-score ng-binding">/40.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Biology (PART B1)</h5>
                    </div>
                    <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                            <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">0.000</span><span className="small-score grey-score ng-binding">/140.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Biology (PART A2)</h5>
                    </div>
                    <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                            <h5 className="margin-bottom101">
                               
                                <span className="big-score ng-binding">0.000</span><span className="small-score grey-score ng-binding">/40.00</span>
                                <span className="badge badge-secondary float-right section-cutoff ng-binding">Cut-off: 0</span>
                            </h5>
                            <h5 className="slider-head ng-binding">Biology (PART B2)</h5>
                    </div> */}
                            </div>
                        </Col>


                        <Col lg={3}>
                            <div className="ree">
                            <div className=" item slid-awad tab-holder-performance">
                                <div className="blue-gradint slid-head">
                                    <div>
                                        <img className="doc2" src={require('../../img/accuracy.png')} />
                                    </div>
                                    {/* <div className="slider-content-h5" style={{ marginLeft: "10px" }}>
                                        <h5 className="slider-head">Accuracy</h5>
                                        <h5>
                                            <span className="big-score ng-binding">{testresults/noofquestions*100}%</span>
                                        </h5>
                                    </div> */}
                                </div>
                                {score.map((score) => (
                                <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom10">
                                        <span className="big-score ng-binding">({((((score.mark*mark)-(score.wronganswer*negativemark))/(noofquestions*mark)))*100}%)</span><span className="small-score grey-score"></span>
                                    </h5>
                                    {/* <h5 className="slider-head ng-binding">Physics (PART A)</h5> */}
                                </div>))}
                            </div>
                            </div>
                        </Col>
                        

                        <Col lg={3}>

                            {/* <div className=" item slid-awad tab-holder-performance">
                                <div className="navy-gradint slid-head">
                                    <div className="slid-head1">
                                        <img className="doc2" src={require('../../img/ticktick.png')} />
                                    </div>
                                    <div className="slider-content-h5" style={{ marginLeft: "10px" }}>
                                        <h5 className="slider-head">Attempted</h5>
                                        <h5>
                                            <span className="big-score ng-binding">20</span><span className="small-score ng-binding">/180</span>
                                        </h5>
                                    </div>
                                </div>
                               <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">6</span><span className="small-score grey-score ng-binding">/16</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Physics (PART A)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">0</span><span className="small-score grey-score ng-binding">/4</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Physics (PART B)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">0</span><span className="small-score grey-score ng-binding">/0</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Chemistry (PART A)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">0</span><span className="small-score grey-score ng-binding">/0</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Chemistry (PART B)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">0</span><span className="small-score grey-score ng-binding">/0</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART A1)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">0</span><span className="small-score grey-score ng-binding">/0</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART B1)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">0</span><span className="small-score grey-score ng-binding">/0</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART A2)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">0</span><span className="small-score grey-score ng-binding">/0</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART B2)</h5>
                                </div>
                            </div> */}
                            
                        </Col>


                        {/* <Col lg={3}>
                            <div className=" item slid-awad tab-holder-performance">
                                <div className="green-gradint slid-head">
                                    <div className="slid-head1">
                                        <img className="doc2" src={require('../../img/clock.png')} />
                                    </div>
                                    <div className="slider-content-h5" style={{ marginLeft: "10px" }}>
                                        <h5 className="slider-head">Time</h5>
                                        <h5>
                                            <span className="big-score ng-binding">02:51</span><span className="small-score ng-binding">/180 Mins</span>
                                        </h5>
                                    </div>
                                </div>
                                <div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">02:16</span><span className="small-score grey-score ng-binding">/40:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Physics (PART A)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">00:23</span><span className="small-score grey-score ng-binding">/20:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Physics (PART B)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">00:06</span><span className="small-score grey-score ng-binding">/40:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Chemistry (PART A)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">00:04</span><span className="small-score grey-score ng-binding">/20:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Chemistry (PART B)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">00:01</span><span className="small-score grey-score ng-binding">/20:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART A1)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">00:01</span><span className="small-score grey-score ng-binding">/10:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART B1)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">00:00</span><span className="small-score grey-score ng-binding">/20:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART A2)</h5>
                                </div><div className="qua-apti1 padding10 ng-scope" ng-repeat="x in test_sections">
                                    <h5 className="margin-bottom101">
                                        <span className="big-score ng-binding">00:00</span><span className="small-score grey-score ng-binding">/10:00</span>
                                    </h5>
                                    <h5 className="slider-head ng-binding">Biology (PART B2)</h5>
                                </div>
                            </div>
                        </Col> */}
                    </Row>


                    {/* <div className="row ">
                        <div className="col-12 col-md-6">
                            <div className="leader-board">
                                <div>
                                    <h4 className="result_heading"> Leader Board</h4>
                                </div>
                                <div className="bg-white">
                                   <div className="leader-1 ng-scope" data-counter="1" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Suvamita Sau</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 1 <span className="float-right ng-binding">328/720</span> </h6>
                                        </div>
                                    </div><div className="leader-1 ng-scope" data-counter="2" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Ajay</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 2 <span className="float-right ng-binding">276/720</span> </h6>
                                        </div>
                                    </div><div className="leader-1 ng-scope" data-counter="3" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Abhishek Kumar</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 3 <span className="float-right ng-binding">273/720</span> </h6>
                                        </div>
                                    </div><div className="leader-1 ng-scope" data-counter="4" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Naval</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 4 <span className="float-right ng-binding">86/720</span> </h6>
                                        </div>
                                    </div><div className="leader-1 ng-scope" data-counter="5" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Harikrishnan</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 5 <span className="float-right ng-binding">10/720</span> </h6>
                                        </div>
                                    </div><div className="leader-1 ng-scope" data-counter="6" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Babu</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 6 <span className="float-right ng-binding">0/720</span> </h6>
                                        </div>
                                    </div><div className="leader-1 ng-scope" data-counter="7" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Abhishek Kumar</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 7 <span className="float-right ng-binding">-1/720</span> </h6>
                                        </div>
                                    </div><div className="leader-1 ng-scope" data-counter="8" ng-repeat="x in toper_list" value="">
                                        <div className="leader-img">
                                            <img src={require('../../img/leader.png')} />
                                        </div>
                                        <div className="leader-2">
                                            <h6 className="rank-leader ng-binding">Harsh Mishra</h6>
                                            <h6 className="rank-leader1 ng-binding">Rank 8 <span className="float-right ng-binding">-2/720</span> </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </Container>
                

         </div>
        
        
        
        
        
        
        </>







    );
}
import React,{useState,useEffect} from 'react';
import './myfollowers.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import Sidebar from './sidbar'
export default function Myfollowers() {


    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])
    return (

        <>
            <div className='allbody'>
        
            <div className='judge'>

                <Container>
                    <Row>

                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>



                        <div className="col-lg-8">
                            <div className="notification__list card">

                                <ul className="list-group ">
                                    <li className="list-group-item content-center">
                                        <a href="#" className="notification-event content-center">
                                            <div className="author-thumb">
                                                <img src={require('../../img/deal.jpg')} alt="" />
                                            </div>
                                            <div className="notification-event-content">
                                                <h5> <span className="hnotification-friend">Rohit Sharma</span>
                                                </h5>
                                            </div>
                                        </a>
                                        <span id="followunfollow_1093">
                                            <button className="btn btn-primary unfollow_id_user" >Followers</button>
                                        </span>

                                    </li>

                                    <li className="list-group-item content-center">
                                        <a href="#" className="notification-event content-center">
                                            <div className="author-thumb">
                                                <img src={require('../../img/deal.jpg')} alt="" />
                                            </div>
                                            <div className="notification-event-content">
                                                <h5> <span className="hnotification-friend">Sachin</span>
                                                </h5>
                                            </div>
                                        </a>
                                        <span id="followunfollow_1093">
                                            <button className="btn btn-primary unfollow_id_user" >Followers</button>
                                        </span>

                                    </li>

                                    <li className="list-group-item content-center">
                                        <a href="#" className="notification-event content-center">
                                            <div className="author-thumb">
                                                <img src={require('../../img/deal.jpg')} alt="" />
                                            </div>
                                            <div className="notification-event-content">
                                                <h5> <span className="hnotification-friend">Hardik Pandya</span>
                                                </h5>
                                            </div>
                                        </a>
                                        <span id="followunfollow_1093">
                                            <button className="btn btn-primary unfollow_id_user" >Followers</button>
                                        </span>

                                    </li>


                                </ul>



                            </div>
                        </div>






                    </Row>

                </Container>



            </div>
</div>

        </>

    );

}
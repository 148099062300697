
import React, { useState, useEffect } from "react";
import config from "../../lib/config";
import './myedit.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'

import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import DatePicker from 'react-date-picker';
import Form from 'react-bootstrap/Form';
import { getdetails } from "../../actions/users";
import { getclass } from "../../actions/users";
import { additionaldetails } from "../../actions/users";
import { addfiles } from "../../actions/users";
import { addimagefile } from "../../actions/users";
import { useNavigate, useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import Sidebar from './sidbar';
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
    fathername: "",
    fatheremail: "",
    fathermobile: "",
    mothername:"",
    motheremail:"",
    mothermobile:"",
    name:"",
    email:"",
    mobilenumber:"",
    Photofile:"",
    Photofile2:"",
    city:"",
    school:"",
    state:"",
    board:"",
    country:"",
    mclass:"",
    Photofile3:"",
    dob:new Date(),
    referandearncode:""
    
  };


export default function Myedit() {

    const [open, setOpen] = useState(false);
    const [classopt, setclassopt] = useState([]);
    const [detail, setdetail] = useState({});
    const { id } = useParams();
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const history = useNavigate();

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [value, onChange] = useState(new Date());

    const getUserListdata = async () => {
        var test = await getdetails(id);
    console.log(test,"1234")
    let data = {};
 
    data["name"] = test.detail.name;
    data["email"] = test.detail.email;
    data["mobilenumber"] = test.detail.mobilenumber;
    data["fathername"]=test.detail.fathername;
    data["fatheremail"]=test.detail.fatheremail;
    data["fathermobile"]=test.detail.fathermobile;
    data["mothername"]=test.detail.mothername;
    data["motheremail"]=test.detail.motheremail;
    data["mothermobile"]=test.detail.mothermobile;
    data["city"]=test.detail.city;
    data["school"]=test.detail.school;
    data["state"]=test.detail.state;
    data["board"]=test.detail.board;
    data["country"]=test.detail.country;
    data["mclass"]=test.detail.class;
    data["Photofile3"]=test.detail.profileimage;
    data["Photofile3"]=test.detail.profileimage;
    data["referandearncode"]=test.detail.referandearncode;
    onChange(new Date(test.detail.dob));


    
  
    // formdata["Photofile"] = test.userValue.image;
    setFormValue(data);


    var test = await getclass();
    
    
    
  
    // formdata["Photofile"] = test.userValue.image;
    setclassopt(test.mclass);
        
    }

    const onChange1 = (e) => {
        e.preventDefault();
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        console.log(formValue);
        //setValidateError(formData)
      };

      const handleFile = (event) => {
        event.preventDefault();
        console.log(event.target.files[0]);
        const { id, files } = event.target;
        //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
    
        let formData = { ...formValue, ...{ [id]: files[0] } };
        setFormValue(formData);
        //setValidateError(formData)
      };
    
      const {
        fathername,
        fatheremail,
        fathermobile,
        mothername,
        motheremail,
        mothermobile,
        name,
        email,
        mobilenumber,
        Photofile,
        Photofile2,
        city,
        school,
        state,
        board,
        country,
        mclass,
        Photofile3,
        referandearncode,
        dob
      } = formValue;

      const handleFormSubmit = async () => {
        console.log("saran");
        
        console.log(formValue);
        let reqData = {
            fathername,
            fatheremail,
            fathermobile,
            mothername,
            motheremail,
            mothermobile,
            name,
            email,
            mobilenumber,
            city,
            school,
            state,
            board,
            country,
            mclass,
            Photofile3,
            dob:value
        };
        console.log(reqData.dob,"ssshhh")
        // let result = await additionaldetails(reqData);
       
        // if (result) {
        //     history("/my-profile-edit");
        //     swal("Profile updated successfully");
        //   } else {
        //     swal("Please try again");
        //   }

        let { error } = await additionaldetails(reqData);
        console.log("error", error);
        if (!error) {
          history("/my-profile-edit");
          swal("Profile updated successfully").then(function() {
            window.location = "/my-profile-edit";
        });
         
         
        } 

        
        else {
          setValidateError(error);
        }

       
      };


      const handleFormSubmit2 = async () => {
        console.log("saran");
        
        console.log(formValue,"satt");
        let reqData = {
           Photofile,
           Photofile2
        };
        let result = await addfiles(reqData);
        if (result) {
            history("/my-profile-edit");
            swal("File uploaded successfully");
          } else {
            swal("Please try again");
          }
       
      };

      const handleFormSubmit3 = async () => {
        console.log("saran");
        var fileInput =
        document.getElementById('Photofile3');
     
    var filePath = fileInput.value;
  
    // Allowing file type
    var allowedExtensions =
            /(\.jpg|\.jpeg|\.png)$/i;
     
    if (!allowedExtensions.exec(filePath)) {
        alert('Please import only jpg,jpeg,png files');
        window.location.reload();
        // fileInput.value = '';
        // return false;
    }
        
        console.log(formValue,"satt");
        let reqData = {
           Photofile3,
          
        };


        
        let result = await addimagefile(reqData);
        if (result) {
            history("/my-profile-edit");
            swal("Profile image updated successfully");
           
          } else {
            swal("Please try again");
          }
       
      };

      const Satta =(value) => {
        onChange(value)
        // let formData = { ...formValue, ...{ [dob]: value } };
        // setFormValue(formData)
      }

    useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);

    return (

        <>
            <div className='sds allbody'>



                <Container>
                    <Row>

                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>






                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="profile__edit_box">
                                <div className="card p-4 mb-5">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="profile__img">
                                            {Photofile3?
                                           <img style={{ width:"100%" }} src= {config.API + "/images/user/" + Photofile3}/>:<img style={{ width:"100%" }} src= {config.API + "/images/user/" + 'IMAGE-1674666998165.png'}/>}
                                           
                                                
                                               
                                            </div>
                                        </div>

                                    </div>
                                   
                                   
                                    <input type="file"  class="form-control choosedfiles" name="image2" id="Photofile3"   onChange={handleFile}/>
                                    <div className="dealss">
                                    <button id="uploadPic2" type="button" class="btn mt-3 m-auto btn-primary"onClick={handleFormSubmit3} >Upload Profile Image</button>
                                    </div>
                                   
                                    <div class="col-md-12">
                                        {/* <button type="button" class="btn btn-primary mb-3  mt-3 m-auto team" onClick={onOpenModal}>Reset Password</button> */}
                                        <Link to='/forgetpassword'>
                                                        
                                                        <Button className="forget"  style={{color:"white", marginTop:"10px"}} >Reset Password</Button>
                                                        </Link>
                                        <Modal open={open} onClose={onCloseModal} center>
                                            <h3>Change Password</h3>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Text className="text-muted">
                                                    We'll send you an OTP.
                                                </Form.Text>
                                                <Form.Control style={{ marginTop:"10px" }} type="email" placeholder="Enter number" />
                                               
                                            </Form.Group>
                                        </Modal>
                                    </div>
                                    {/* <div class="col-md-12"> <a href="#tab-content-rpassword" data-bs-toggle="modal" class="text-right d-block btn btn-info "><b>Reset Password</b></a> </div> */}
                                    <br></br>
                                    <h3 className='basicinfo'>Basic Information</h3>

                                    <form id="sbform" method="post">
                                        <div class="row">
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput"> Name </label>
                                                <input type="text" class="form-control" name="previous_name" onChange={onChange1}   id="name" placeholder="Name" value={name}/>
                                              
                                            </div>
                                           
                                            <input type="hidden" name="user_id" id="user_id" value="105918" />
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Email</label>
                                                <input type="text" class="form-control" name="email" id="email" onChange={onChange1} value={email} readOnly="true"  />
                                                
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Mobile </label>
                                                <input type="text" class="form-control" name="mobile" id="mobilenumber"onChange={onChange1}  value={mobilenumber}   />

                                                {validateError.mobilenumber && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.mobilenumber}
                                                          </span>
                                                        )}
                                               
                                            </div>
                                        </div>


                                        <div class="row">

                                            <div class="form-floating col-md-4 mb-3">
                                                <div>
                                                    <label for="floatingInput">Date Of Birth </label>
                                                    <DatePicker format='d/M/y '  name="dob" id="dob"  selected={value}    onChange={(date) => Satta(date)} value={value} />
                                                </div>
                                                {validateError.dob && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.dob}
                                                          </span>
                                                        )}
                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput"> Refer and Earn </label>
                                                <input type="text" class="form-control" name="previous_name" readOnly={true}   id="referandearncode" placeholder="Name" value={referandearncode}/>
                                              
                                            </div>

                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Class</label>
                                                <select class="form-select form-control" name="class" id="mclass" onChange={onChange1} value={mclass}aria-label="Floating label select example">
                                                {classopt.map(classopt =>
                                                    <option value={classopt.content}>{classopt.content} </option>)}
                                                    {/* <option value="13">1st</option>
                                                    <option value="12">2nd</option>
                                                    <option value="11">3rd</option>
                                                    <option value="10">4th</option>
                                                    <option value="9">5th</option>
                                                    <option value="8">6th</option>
                                                    <option value="7">7th</option>
                                                    <option value="6">8th</option>
                                                    <option value="5">9th</option>
                                                    <option value="4">10th</option>
                                                    <option value="3">11th</option>
                                                    <option value="2">12th</option>
                                                    <option value="1">12th-Pass</option> */}
                                                </select>

                                               
                                            </div>

                                            {/* <div class="form-floating col-md-6 mb-3">
                                                <label for="floatingSelect">Change Language</label>
                                                <select class="form-select form-control" onchange="change_lang(this.value)" id="floatingSelect" aria-label="Floating label select example">
                                                    <option value="">Select </option>
                                                    <option selected="" value="1">English </option>
                                                    <option value="2">Hindi</option>
                                                </select>
                                               
                                            </div> */}
                                        </div>

                                        <div class="row">
                                           
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Board</label>
                                                <select class="form-select form-control" name="board" id="board" aria-label="Floating label select example"onChange={onChange1} value={board}>
                                                    <option value="">Select </option>
                                                    <option value="Andhra Pradesh Board">Andhra Pradesh Board</option>
                                                    <option value="Assam Board">Assam Board</option>
                                                    <option value="Bihar Board">Bihar Board</option>
                                                    <option value="CBSE Board">CBSE Board</option>
                                                    <option value="Chhattisgarh Board">Chhattisgarh Board</option>
                                                    <option value="CISCE Board">CISCE Board</option>
                                                    <option value="Goa Board">Goa Board</option>
                                                    <option value="Gujarat Board">Gujarat Board</option>
                                                    <option value="Haryana Board">Haryana Board</option>
                                                    <option value="Himachal Pradesh Board">Himachal Pradesh Board</option>
                                                    <option value="Jammu and Kashmir Board">Jammu and Kashmir Board</option>
                                                    <option value="Jharkhand Board">Jharkhand Board</option>
                                                    <option value="Karnataka Board">Karnataka Board</option>
                                                    <option value="Kerala Board">Kerala Board</option>
                                                    <option value="Madhya Pradesh Board">Madhya Pradesh Board</option>
                                                    <option value="Maharashtra Board">Maharashtra Board</option>
                                                    <option value="Manipur Board">Manipur Board</option>
                                                    <option value="Meghalaya Board">Meghalaya Board</option>
                                                    <option value="Mizoram Board">Mizoram Board</option>
                                                    <option value="Nagaland Board">Nagaland Board</option>
                                                    <option value="Odisha Board">Odisha Board</option>
                                                    <option value="Punjab Board">Punjab Board</option>
                                                    <option value="Rajasthan Board">Rajasthan Board</option>
                                                    <option value="Tamilnadu Board">Tamilnadu Board</option>
                                                   
                                                    <option value="Tripura Board">Tripura Board</option>
                                                    <option value="Uttar Pradesh Board">Uttar Pradesh Board</option>
                                                    <option value="Uttarakhand Board">Uttarakhand Board</option>
                                                    <option value="West Bengal Board">West Bengal Board</option>
                                                </select>
                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">School</label>
                                                <input type="text" class="form-control" name="school_name" id="school" onChange={onChange1} value={school} />
                                               
                                            </div>
                                        </div>

                                        <br></br>
                                        <h3 className='basicinfo' style={{ marginTop:"15px" }}>Additional Information</h3>

                                        <div class="row" style={{ marginTop:"15px" }}>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Father Name </label>
                                                <input type="text" class="form-control" name="father_name"  placeholder="Name"id="fathername"  onChange={onChange1}  value={fathername} />
                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Father Email</label>
                                                <input type="email" class="form-control" name="father_email"  id="fatheremail" onChange={onChange1}  value={fatheremail}/>
                                                {validateError.fatheremail && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.fatheremail}
                                                          </span>
                                                        )}
                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Father Mobile </label>
                                                <input type="text" class="form-control" name="father_mobile " id="fathermobile" onChange={onChange1}  value={fathermobile}/>
                                                {validateError.fathermobile && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.fathermobile}
                                                          </span>
                                                        )}
                                               
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Mother Name </label>
                                                <input type="text" class="form-control" name="mother_name"   id="mothername" onChange={onChange1} value={mothername}/>
                                                
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Mother Email</label>
                                                <input type="email" class="form-control" name="mother_email"  id="motheremail" onChange={onChange1}  value={motheremail}/>
                                                {validateError.motheremail && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.motheremail}
                                                          </span>
                                                        )}
                                                
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Mother Mobile</label>
                                                <input type="text" class="form-control" name="mother_mobile"  onChange={onChange1}id="mothermobile" value={mothermobile} />
                                                {validateError.mothermobile && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.mothermobile}
                                                          </span>
                                                        )}
                                                
                                            </div>
                                        </div>

                                        {/* <div class="row">
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Class</label>
                                                <select class="form-select form-control" name="class" id="mclass" onChange={onChange1} value={mclass}aria-label="Floating label select example">
                                                {classopt.map(classopt =>
                                                    <option value={classopt.content}>{classopt.content} </option>)}
                                                  
                                                </select>

                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Board</label>
                                                <select class="form-select form-control" name="board" id="board" aria-label="Floating label select example"onChange={onChange1} value={board}>
                                                    <option value="">Select </option>
                                                    <option value="Andhra Pradesh Board">Andhra Pradesh Board</option>
                                                    <option value="Assam Board">Assam Board</option>
                                                    <option value="Bihar Board">Bihar Board</option>
                                                    <option value="CBSE Board">CBSE Board</option>
                                                    <option value="Chhattisgarh Board">Chhattisgarh Board</option>
                                                    <option value="CISCE Board">CISCE Board</option>
                                                    <option value="Goa Board">Goa Board</option>
                                                    <option value="Gujarat Board">Gujarat Board</option>
                                                    <option value="Haryana Board">Haryana Board</option>
                                                    <option value="Himachal Pradesh Board">Himachal Pradesh Board</option>
                                                    <option value="Jammu and Kashmir Board">Jammu and Kashmir Board</option>
                                                    <option value="Jharkhand Board">Jharkhand Board</option>
                                                    <option value="Karnataka Board">Karnataka Board</option>
                                                    <option value="Kerala Board">Kerala Board</option>
                                                    <option value="Madhya Pradesh Board">Madhya Pradesh Board</option>
                                                    <option value="Maharashtra Board">Maharashtra Board</option>
                                                    <option value="Manipur Board">Manipur Board</option>
                                                    <option value="Meghalaya Board">Meghalaya Board</option>
                                                    <option value="Mizoram Board">Mizoram Board</option>
                                                    <option value="Nagaland Board">Nagaland Board</option>
                                                    <option value="Odisha Board">Odisha Board</option>
                                                    <option value="Punjab Board">Punjab Board</option>
                                                    <option value="Rajasthan Board">Rajasthan Board</option>
                                                    <option value="Tamilnadu Board">Tamilnadu Board</option>
                                                   
                                                    <option value="Tripura Board">Tripura Board</option>
                                                    <option value="Uttar Pradesh Board">Uttar Pradesh Board</option>
                                                    <option value="Uttarakhand Board">Uttarakhand Board</option>
                                                    <option value="West Bengal Board">West Bengal Board</option>
                                                </select>
                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">School</label>
                                                <input type="text" class="form-control" name="school_name" id="school" onChange={onChange1} value={school} />
                                               
                                            </div>
                                        </div> */}



                                        <div class="row">
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">City</label>
                                                <input type="text" class="form-control" name="city"  id="city" onChange={onChange1} value={city} />
                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">State</label>
                                                <select name="state_id" id="state" class="form-control" onChange={onChange1} value={state} >
                                                    
                                                    <option value="Karnataka"> Karnataka</option>
                                                    <option value="Kerala"> Kerala</option>
                                                   <option value="Puducherry"> Puducherry</option>
                                                
                                                    <option value="Tamilnadu"> Tamil Nadu</option>
                                                    <option value="Telangana"> Telangana</option>
                                                   
                                                </select>
                                               
                                            </div>
                                            <div class="form-floating col-md-4 mb-3">
                                                <label for="floatingInput">Country</label>
                                                <select name="country" id="country" class="form-control"onChange={onChange1} value={country}>
                                                    <option value="">Select</option>
                                                    <option value="101"> India</option>
                                                </select>
                                               
                                            </div>
                                        </div>



                                        {/* <h3 className='basicinfo'>Exam Details</h3>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="sub_stream mt-3">Exam</label>
                                                    <select class="form-control" name="exam" id="exam" required="">
                                                        <option>Select Exam</option>
                                                        <option value="2">NEET 2022</option>
                                                        <option value="3">JEE MAIN 2022</option>
                                                        <option value="4">JEE Advanced</option>
                                                        <option value="5">CBSE</option>
  
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="sub_stream">Registered Mobile Number</label>
                                                    <input type="number" class="form-control" name="r_mobile"  placeholder="Enter Mobile Number" required="" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="sub_stream mt-3">Registered Email ID</label>
                                                    <input type="email" class="form-control"  name="r_email"  placeholder="Enter Email" required="" />
                                                </div>
                                            </div>


                                        </div><br></br>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="sub_stream">Roll Number</label>
                                                    <input type="text" class="form-control" name="roll_number"  placeholder="Roll Number" />
                                                </div>

                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="sub_stream mt-3">Application Number</label>
                                                    <input type="text" class="form-control" name="registered_application_number"  placeholder="Application Number" />
                                                </div>
                                            </div>


                                        </div> */}




                                        <button type="button" class="btn btn-primary mb-3  mt-3 m-auto" id="save_profile" onClick={handleFormSubmit}> Save Changes </button>
                                        

                                    </form>

                                    {/* <br></br>
                                    <h3 className='basicinfo'>Upload Form Details</h3>
                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="sub_stream mt-3">Upload Application Form</label>
                                                <input type="file" class="form-control" name="image2" id="Photofile"   onChange={handleFile}/>
                                            </div><br></br>


                                            <button id="uploadPic2" type="button" class="btn mt-3 m-auto btn-primary"onClick={handleFormSubmit2} >Upload</button>
                                        </div>


                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="sub_stream mt-3">Upload Admit Card</label>
                                                <input type="file" class="form-control" name="image1" id="Photofile2"  onChange={handleFile} />
                                            </div><br></br>

                                       
                                        </div>


                                    </div><br></br> */}



                                </div>


                            </div>

                        </div>






                    </Row>

                </Container>






            </div>
        
        </>

    );

}
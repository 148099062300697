import React, { useState, useEffect } from "react";
import './testonline.css'
import { Container, Row, Col, Card, Button, Tabs, Nav, Table, Tab, Modal } from 'react-bootstrap';
import config from "../../lib/config";

import Tabpara from '../../Components/Tabpara/tappara'

import Qsildes from '../../Components/Qcarousel/qcarousel';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useNavigate, useParams, Link } from "react-router-dom";

import { FiClock } from 'react-icons/fi';
import { BsCheckCircle } from 'react-icons/bs';
import { AiOutlineMinusCircle } from 'react-icons/ai'; 
import { BsFillStarFill } from 'react-icons/bs';

import { FaCircle } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import { FiMenu } from 'react-icons/fi';
import { testtopic  } from "./../../actions/users";
import { getquestion,getsetname  } from "./../../actions/users";
import { postanswer,updatefinalresult  } from "./../../actions/users";
import { reviewanswers  } from "./../../actions/users";
import { submitanswer,readanswer,currentquestionstatus  } from "./../../actions/users";

import { Next } from "react-bootstrap/esm/PageItem";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTimer } from 'react-timer-hook';
import NewWindow from "react-new-improved-window";
const initialFormValue = {
   
    
    ans:"",
   
    
    
  };

  function DOMNodeProvider(props) {
    const [node, setNode] = useState(null);
  
    return (
      <div className="dom-node-provider" ref={setNode}>
        {props.children(node)}
      </div>
    );
  }

export default function Testonline({id,test_id}) {
    const [questionmenutoggler, setquestionmenutoggler] = useState(false);
    const [formValue, setFormValue] = useState(initialFormValue);
    const [testtopics, settesttopic] = useState([]);
    const [currenttopic, setcurrenttopic] = useState(0);
    const [questionanswered, setquestionanswered] = useState(0);
    const [reviewanswer, setreviewanswer] = useState(0);
    const [notvisited, setnotvisited] = useState(0);
    const [notquestionanswered, setnotquestionanswered] = useState(0);
    const [questionstatusanswered, setquestionstatusanswered] = useState(0);
    const [questionstatusreviewed, setquestionstatusreviewed] = useState(0);
    const [currentquestionnumber, setcurrentquestionnumber] = useState(1);
    const [answer, setanswer] = useState("");
    const [topicname, settopicname] = useState({});
    const [isFull, setisFull] = useState(true);
    const [currentquestion, setcurrentquestion] = useState({question:"",
        id:"",
        options:[],
    image:""});
    const [getquestions, setgetquestions] = useState([]);
    const [questioncount, setquestioncount] = useState(0);
    const [setname, setsetname] = useState({});
    const [settimes, setsettime] = useState(0);
    
  
    const{id1}=useParams();
    
    const handle = useFullScreenHandle();



    const {
        seconds,
        minutes,
        hours,
        days,
        start,
        pause,
        resume,
        restart,
      } = useTimer({ expiryTimestamp:1000, onExpire: () => onGotestresult() });





    
    const getUserListdata = async () => {
        var test = await testtopic(id);
        // console.log(test,"ghhgj")
        settesttopic(test.testtopic);

        console.log(id,"marvelsz")


        var test = await getsetname(id);
        console.log(test,"timesof")
      
        setsetname(test.setname);
        // setsettime(test.time);
        const time123=test.time*3600

      const time = new Date();
        time.setSeconds(time.getSeconds() + (time123));
        restart(time)
    
          
        
    }

    const onChange = (e) => {
        e.preventDefault();
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        console.log(formValue);
        //setValidateError(formData)
      };

// console.log(getquestions);
    const {
       
        
        ans,
       
      } = formValue;

      const saveAns = async (e) => {
        // alert("dsdssd")
        setanswer(e);

        console.log(formValue);
        let reqData = {            
            ans:e,
            time:(hours*360)+(minutes*60)+seconds,    
            test_id       
        };
        let { error } = await postanswer(reqData,currentquestion.id);
       if(!error){
        if(e){
        const cques = [...getquestions];
        cques[currentquestionnumber-1].status = 2;
        cques[currentquestionnumber-1].answer = e;
        setgetquestions(cques)
        }else{
            const cques = [...getquestions];
            cques[currentquestionnumber-1].status = 1;
            cques[currentquestionnumber-1].answer = e;
            setgetquestions(cques)
        }
       }
        console.log("error", error);


        var test1 = await currentquestionstatus(testtopics[currenttopic]._id,test_id);
        console.log(test1,"c")
        
        setquestionstatusanswered(test1.noofanswered);
        setquestionstatusreviewed(test1.noofreview);
       
       
      };

      const nextQus = async (e) => {
        setanswer("");
        // console.log(getquestions.length,currentquestionnumber)
        if(getquestions.length<currentquestionnumber+1){
            // alert("dsdsd")
            setcurrentquestionnumber(1);
            setcurrenttopic(currenttopic+1);
        }else{
       
            const current=currentquestionnumber+1;
            setcurrentquestionnumber(current);
        }

      };

      const previousQus = async (e) => {
        setanswer("");
        // console.log(getquestions.length,currentquestionnumber)
        if(currentquestionnumber==1){
            // alert("dsdsd")
            setcurrentquestionnumber(1);
            setcurrenttopic(currenttopic-1);
        }else{
       
            const current=currentquestionnumber-1;
            setcurrentquestionnumber(current);
        }

      };

      const handleFormSubmit1 = async (e) => {

        
        console.log("saran");
        e.preventDefault();
        console.log(formValue);
        let reqData = {
           
            
            ans:answer?ans:"",
            test_id
            
        };
        let { error } = await reviewanswers(reqData,currentquestion.id,test_id);
        console.log("error", error);

        if(!error){
            const cques = [...getquestions];
            cques[currentquestionnumber-1].review = !cques[currentquestionnumber-1].review;
            setgetquestions(cques)


            var test1 = await currentquestionstatus(testtopics[currenttopic]._id,test_id);
            console.log(test1,"bbd")
            
            setquestionstatusanswered(test1.noofanswered);
            setquestionstatusreviewed(test1.noofreview);
         

           }
       
      };

    //   const handleFormSubmit2 = async (e) => {

        
    //     console.log("saran");
    //     e.preventDefault();
    //     console.log(formValue);
        
    //     let { error } = await submitanswer();
       
    //     console.log("error", error);
       
    //   };


    const fetchQuestion=async()=>{     
        console.log("for post man",id,testtopics[currenttopic]._id,test_id)   
        var test1 = await getquestion(id,testtopics[currenttopic]._id,test_id);
        console.log(test1,"ssd")

        const loadstatus =(no,id) =>{
            if(no==1){
            readanswer(id,test_id);
            return  1;
            }else{
                return  0;
            }
        }
        // console.log(test1,"kkkkk")
        const structure = test1.questions.map((question)=>{
            let ques = {question:question.question,
            id:question._id,
            options:question.options,questiontype:question.questiontype,
            questionnumber:question.questionnumber,
            status:question.answers.length ? question.answers[0].status : loadstatus(question.questionnumber,question._id),
            review:question.answers.length ? question.answers[0].review : 0,
            answer:question.answers.length ? question.answers[0].answer : "",
            image: question.image ? question.image  : "", 
              }

        
    
        return ques;
        });

      

        // console.log(structure,"kkkkk")
        setgetquestions([...structure]);
        setquestioncount(test1.totalquestions);
        
        

        initialcreatequestion(structure,1);

        var test1 = await currentquestionstatus(testtopics[currenttopic]._id,test_id);
        console.log(test1,"bbv")
        
        setquestionstatusanswered(test1.noofanswered);
        setquestionstatusreviewed(test1.noofreview);
        settopicname(test1.topicname);
    }

    const fetchSubmitpart = async() =>{
        var test1 = await submitanswer({time:(hours*360)+(minutes*60)+seconds},test_id);
        console.log(test1,"bb")
        
        setquestionanswered(test1.answers);

        setnotquestionanswered(test1.notanswers);

        setnotvisited(test1.notvisited);

        setreviewanswer(test1.reviewanswer);
    }

    const onGotestresult = async() =>{
        // await updatefinalresult({time:`${hours}:${minutes}:${seconds}`},test_id)
        window.location = '/testresult/'+id;
    }

    // function Next() {
    //    const current=currentquestionnumber+1
    //    setcurrentquestionnumber(current);
    //   }

    // const Questionnumber=async()=>{
        
    //     var test1 = await getquestion(id,currenttopic);
    //     console.log(test1,"kkkkk")
        
    //     setcurrentquestionnumber(test1.questions);
       
    // }

    const  initialcreatequestion =(qs,num) =>{
        let question = qs.find(e=>(e.questionnumber==String(num)));
        console.log(question,"questionquestionquestion")
            setcurrentquestion(question)
                                if(question.status){
                                    setanswer(question.answer);
                                    
                                }else{
                                    readanswer(question.id,test_id);
            //                         const cques = [...getquestions];
            // cques[currentquestionnumber-1].status = 1;
            // setgetquestions(cques)
                                }
                                setcurrentquestionnumber(1)

    }

    const  createquestion =() =>{
        let question = getquestions.find(e=>(e.questionnumber==String(currentquestionnumber)));
        console.log(question,"questionquestionquestion")
            setcurrentquestion(question)
                                if(question.status){
                                    setanswer(question.answer);
                                    
                                }else{
                                    readanswer(question.id,test_id);
                                    const cques = [...getquestions];
                                    cques[currentquestionnumber-1].status = 1;
                                    setgetquestions(cques)
                                }


    }


    useEffect(() => {
  console.log("u1")
        if(getquestions.length){
            createquestion();
        }
 
     },[currentquestionnumber]);

   

    useEffect(() => {
        console.log("u2")
//   console.log("ppppppppppppppppppppppp")
       if(testtopics.length){
       fetchQuestion();

       }

    },[currenttopic,testtopics]);

    useEffect(() => {
        
        handle.enter();
        getUserListdata();

        //timer 
        // const time = new Date();
        // time.setSeconds(time.getSeconds() + 3600);
        // restart(time)
    },[]);

    // console.log(currenttopic,testtopics.length,getquestions.length,currentquestion);
    const PopupExample = () => (
        <Popup trigger={<button>Trigger</button>} position="top left">
            {close => (
                <div>
                    Content here
                    <a className="close" onClick={close}>
                        &times;
                    </a>
                </div>
            )}
        </Popup>
    );
    const loadques = ()=>{
        if(currentquestion.questiontype == 'mcq'){
        return <div className='qnumbers'>
                        <div className='qconnect'>
                        <h5>Question : {currentquestionnumber}</h5>
                        {currentquestion.image?
                        <img src={config.API + "/images/user/" + currentquestion.image} alt="..." style={{ maxWidth: 50, maxHeight: 50 }} />: null}
                     
                   
                            <p>{currentquestion.question}</p>
                       
                        </div>
                        <ul>
                        {currentquestion.options.map(e =>
                            <li onClick={()=>saveAns(e)}>
                                <div>
                                <input className="form-check-input product_type_filter" style={{ marginTop:"6px" }} type="radio"    checked={e==answer}/>
                                </div>
                                <div>
                                <p>{e}</p>
                                </div>
                            </li>)}
                            {/* <li>
                            <div>
                                <input className="form-check-input product_type_filter" style={{ marginTop:"6px" }} type="radio" name="radiobutton" id="radiobutton2"
                               value="option2" />
                                </div>
                                <div>
                                <p>{currentquestion.options[1]}</p>
                                </div>
                            </li>
                            <li>
                            <div>
                                <input className="form-check-input product_type_filter" style={{ marginTop:"6px" }} type="radio" name="radiobutton" id="" value="option3" />
                                </div>
                                <div>
                                <p>{currentquestion.options[2]}</p>
                                </div>
                            </li>
                            <li>
                            <div>
                                <input className="form-check-input product_type_filter" style={{ marginTop:"6px" }} type="radio" name="radiobutton" id="" value="option4" />
                                </div>
                                <div>
                                <p>{currentquestion.options[3]}</p>
                                </div>
                            </li> */}

                        </ul>
                    </div>
        }else if(currentquestion.questiontype == 'fillin'){
            return <div className='qnumbers'>
                        <div className='qconnect'>
                        <h5>Question : {currentquestionnumber}</h5>
                        {currentquestion.image?
                        <img src={config.API + "/images/user/" + currentquestion.image} alt="..." style={{ maxWidth: 50, maxHeight: 50 }} />: null}
                     
                   
                            <p>{currentquestion.question}</p>
                           
                        </div>
                        <ul>
                            <li>
                                <input type="text" onChange={(e)=>saveAns(e.target.value)} value={answer}/>
                            </li>
                        


                        </ul>
                    </div>
        }
    }

    // const loadquesnumcolor =(e)=>{
    //     if(e.answers.length){
    //         if(){
    //         return "secondary";
    //         }
    //     }else{
    //         return "secondary";
    //     }
    // }
    const qusNumber = (e) =>{
        if(e.review){
            return "sa_review"
        }
        if(e.status){
            switch(e.status) {
                case 1:
                  return "sa_unanswered";
                  break;
                case 2:
                    return "sa_success";
                  break;
                default:
                    return "numberz";
              }

        }else
{
    return "numberz";
}
    }

    const converttoseconds = () =>{
var finalseconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds); 

console.log(finalseconds);
return finalseconds;
    }
return (

    <>
<FullScreen
         handle={handle}
        >
     <DOMNodeProvider>
          {(node) => (

        <div className='test-online allbody-online'>
            
                <div>
                <ul className='lolo'>
                    <li> <img className="buildings" src={require('../../img/download.png')} /></li>
                    <li style={{display: "flex", alignItems:"center" }}>
                        <div>
                        <img className='iones' src={require('../../img/ones.jpg')} />
                        </div> 
                        <div className='ptonline'>
                            <h5 className="ng-binding"><span style={{ color: "black", fontWeight: "700" }}>Canditate Name</span> : <span style={{ color: "black", fontWeight: "500" }}>{localStorage.name}</span></h5>
                            <h5 className="ng-binding"><span style={{ color: "black", fontWeight: "700" }}>Remaining Time</span> : <span style={{ color: "black", fontWeight: "500" }}>{hours}:{minutes}:{seconds}</span></h5>
                        </div>

                    </li>
            </ul>
            
            </div>



            <div className='attempt'>

         <h3>{setname.setname}</h3>


            </div>


            <div className='ontestgap'>

                <Row>

                    <Col lg={8}>  
                        <div className='light1'>
                <Tabs
                    transition={false}
                    activeKey={currenttopic}
                    id="noanim-tab-example"
                    className="mb-3"
                    onSelect={(i) => setcurrenttopic(Number(i))}
                >

{testtopics.map((testtopic,i) =>
                    <Tab  eventKey={i}  title={testtopic.content}>
                         
        <div className='qslides'>
            
               
       
                <div>
              {  loadques()}

                </div>
                

               

   </div>
                    </Tab>)}
                    {/* <Tab eventKey="profile" title="Physics(NR)">
                                    <Qsildes />
                    </Tab>
                    <Tab eventKey="physics1" title="chemistry(single correct)">
                         <Qsildes />
                    </Tab>
                    <Tab eventKey="physics2" title="chemistry(NR)">
                         <Qsildes />
                    </Tab>
                    <Tab eventKey="physics3" title="Mathemetics">
                         <Qsildes />
                    </Tab> */}
                            </Tabs>
                            
                        </div>

                        <div className='sbtns'>
                            <ul>
                                <li>
                                    <Button variant="warning" onClick={handleFormSubmit1} className='marked'>Marked for review</Button>{' '}
                                    {(currenttopic == 0 && currentquestionnumber == 1) ? null:
                                    <Button variant="success" onClick={previousQus}  className='saves'>Previous</Button>  }
                                    {!((getquestions.length==currentquestionnumber) && (currenttopic+1  == testtopics.length)) &&<Button variant="success" onClick={nextQus}  className='saves'>Save & Next</Button>}{' '}
                                    <Button variant="danger" style={{ marginTop:"3px" }} onClick={()=>saveAns("")}> Clear Response </Button>{' '}
                                    {/* <Button variant="warning" onClick={handleFormSubmit1}  className='marked'> Save & Mark for review </Button>{' '} */}

                                </li>
                                <li>

                                    {(((getquestions.length==currentquestionnumber) && (currenttopic+1  == testtopics.length)) || ((getquestions.length==0) && (currenttopic+1  == testtopics.length))) &&<Popup  mountNode={node} onOpen={fetchSubmitpart} trigger={<Button variant="success"   className='saves' >Submit</Button>} position="bottom center">
                                        {close => (
                                            <div className='submitprocess'>
                                                <div>
                                                    <h3>Do we want to proceed ?</h3>
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li>
                                                            <div>
                                                                <FiClock /> Remaining Time
                                                            </div>
                                                            <div>
                                                                <p>{hours}:{minutes}:{seconds}</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <BsCheckCircle /> Answered
                                                            </div>
                                                            <div>
                                                                <p>{questionanswered}</p>
                                                            </div>
                                                           </li>
                                                        <li>
                                                            <div>
                                                                <AiOutlineMinusCircle /> Unanswered
                                                            </div>
                                                            <div>
                                                                <p>{notquestionanswered}</p>
                                                            </div></li>
                                                        <li>
                                                            <div>
                                                                <BsFillStarFill /> Not Visited
                                                            </div>
                                                            <div>
                                                                <p>{notvisited}</p>
                                                            </div></li>
                                                        <li>
                                                            <div>
                                                                <BsFillStarFill /> Marked for review
                                                            </div>
                                                            <div>
                                                                <p>{reviewanswer}</p>
                                                            </div></li>
                                                        {/* <li>
                                                            <div>
                                                                <BsFillStarFill /> Answered and mark for review(will be considered for evalution)
                                                            </div>
                                                            <div>
                                                                <p>0</p>
                                                            </div></li> */}
                                                    </ul>
                                                </div>
                                                <div className='fullbt'>
                                                    <Button variant="primary" onClick={close}>No</Button>{' '}
                                                    <Button onClick={onGotestresult} variant="primary">Yes</Button>{' '}
                                                </div>
                                               
                                            </div>
                                        )}
                                    </Popup>}

                                  
                                </li>
                          </ul>

                        </div>



<div className="testtogglez">

                        {questionmenutoggler ? <GrFormClose className='lion' onClick={() => setquestionmenutoggler(!questionmenutoggler)} />
                        :<FiMenu className='lion' onClick={() => setquestionmenutoggler(!questionmenutoggler)} />}
                        <div className='legends' style={{ display: questionmenutoggler ? 'block' : 'none'}}>
                         
                            <div className='padss'>
                            <div>
                                    <h4>LEGENDS</h4>
                                  
                            </div>
                            <div>
                                    <h4>{topicname.content}</h4>
                                  
                            </div>

                                <div className='bline'>
                            <ul className="jenii">
                                {/* <li>
                                    <div style={{ display: "flex" }}>
                                <Button variant="secondary" className='notvisit'></Button>{' '}
                                        <p> 6 Not Visited</p>
                              </div>
                                </li> */}
                                <li>
                                    <div className="fra" style={{ display:"flex" }}>
                                        <FaCircle className='markreview' />
                                        <p> {questionstatusreviewed} Marked for review</p>
                                   </div>
                                </li>
                
                            </ul>

                            <ul>
                                <li>
                                    <div style={{ display: "flex" }}>
                                        <Button variant="secondary" className='answered'></Button>{' '}
                                        <p>{questionstatusanswered} answered</p>
                                        
                                    </div>
                                </li>

                                <li>
                                        <div style={{ display: "flex", marginLeft: "20px" }}>
                                        <Button variant="secondary" className='unanswered'></Button>{' '}
                                        <p> {questioncount-questionstatusanswered} unanswered</p>
                                    </div>
                                </li>
                            </ul>

                            <ul>
                                {/* <li>
                                    <div style={{ display: "flex" }}>
                                            <FaCircle style={{ fontSize: "24px", color:"#481d8c" }} />
                                        <p> 0 Answered and mark for review(will be considered and evalution) </p>
                                    </div>
                                </li> */}
                                </ul>
                                </div>
                                

                                <div className='numsection'>
                                    <ul>
                                        <li>
                                            {getquestions.length&& getquestions.map(e=><Button variant={qusNumber(e)} className={qusNumber(e)} onClick={()=>setcurrentquestionnumber(Number(e.questionnumber))}>{e.questionnumber}</Button>)}
                                         </li>   
                                    </ul>
                                </div>

                            </div>
                            
                        </div>


                        </div>



                       
                    </Col>
           

                    <Col lg={4} >  
                        {questionmenutoggler ? <GrFormClose className='lion' onClick={() => setquestionmenutoggler(!questionmenutoggler)} />
                        :<FiMenu className='lion' onClick={() => setquestionmenutoggler(!questionmenutoggler)} />}
                        <div className='legends' style={{ display: questionmenutoggler ? 'block' : 'none'}}>
                         
                            <div className='padss'>
                            <div>
                                    <h4>LEGENDS</h4>
                                  
                            </div>
                            <div>
                                    <h4>{topicname.content}</h4>
                                  
                            </div>

                                <div className='bline'>
                            <ul className="jenii">
                                {/* <li>
                                    <div style={{ display: "flex" }}>
                                <Button variant="secondary" className='notvisit'></Button>{' '}
                                        <p> 6 Not Visited</p>
                              </div>
                                </li> */}
                                <li>
                                    <div className="fra" style={{ display:"flex" }}>
                                        <FaCircle className='markreview' />
                                        <p> {questionstatusreviewed} Marked for review</p>
                                   </div>
                                </li>
                
                            </ul>

                            <ul>
                                <li>
                                    <div style={{ display: "flex" }}>
                                        <Button variant="secondary" className='answered'></Button>{' '}
                                        <p>{questionstatusanswered} answered</p>
                                        
                                    </div>
                                </li>

                                <li>
                                        <div style={{ display: "flex", marginLeft: "20px" }}>
                                        <Button variant="secondary" className='unanswered'></Button>{' '}
                                        <p> {questioncount-questionstatusanswered} unanswered</p>
                                    </div>
                                </li>
                            </ul>

                            <ul>
                                {/* <li>
                                    <div style={{ display: "flex" }}>
                                            <FaCircle style={{ fontSize: "24px", color:"#481d8c" }} />
                                        <p> 0 Answered and mark for review(will be considered and evalution) </p>
                                    </div>
                                </li> */}
                                </ul>
                                </div>
                                

                                <div className='numsection'>
                                    <ul>
                                        <li>
                                            {getquestions.length&& getquestions.map(e=><Button variant={qusNumber(e)} className={qusNumber(e)} onClick={()=>setcurrentquestionnumber(Number(e.questionnumber))}>{e.questionnumber}</Button>)}
                                         </li>   
                                    </ul>
                                </div>

                            </div>
                            
                        </div>
                      


                    </Col>

                </Row>
            </div>



            <div id="popup-root"></div>
            </div>

    
)}
</DOMNodeProvider>
            </FullScreen>

    </>

    );
}

import React, { Component,useState,useEffect } from 'react';
import './header.css'
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, Offcanvas } from 'react-bootstrap'

import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import config from "../../lib/config";

import { GrFacebook } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { GrLinkedin } from "react-icons/gr";
import { FaInstagram } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { toast } from "react-toastify";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Form from 'react-bootstrap/Form'
import isEmpty from "../../lib/isEmpty";
import { BsGoogle } from 'react-icons/bs';
import { googleSignin,userLogin,createUser,headermenusaddon,logout,logoutheader,forgotPassword,sitesettings,checkuser,getdetails } from '../../actions/users';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import { Link,useNavigate,useLocation,browserHistory } from "react-router-dom";
import { IoMdArrowDropdown } from 'react-icons/io';
import swal from "sweetalert";
import { confirm } from "react-confirm-box";


const initialFormValue = {
    name: "",
    email: "",
    phone:"",
    password:"",
    friendrefercode:""
    
  };

  const initialloginFormValue = {
    logemail: "",
    logpassword:""
    
  };
  // toaster config
// toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export default function Header(probs) {
    const [loginData, setLoginData] = useState(
        localStorage.getItem('loginData')
          ? JSON.parse(localStorage.getItem('loginData'))
          : null
      );
    const[regtab,setregtab]=useState(false)
  const [formValue, setFormValue] = useState(initialFormValue);
  const [logformValue, setlogFormValue] = useState(initialloginFormValue);
  const [validateError, setValidateError] = useState({});
  const[coursetypes,setCoursetypes]=useState([])
  const [sticky, setSticky] = useState("");
  
  const[exams,setExams]=useState([])
  const [sitesetting, setsitesetting] = useState({});
  const [active, setActive] = useState(false);
  const [forpopup, setForpopup] = useState(false);
  const history = useNavigate();

  // history.listen(()=>{
  //   
  // })

  const {
    name,
    email,
    phone,
    password,
    friendrefercode
  } =formValue;
  const {
    logemail,
    logpassword
  } =logformValue;

  const handleFailure = (result) => {
    console.log(result,"handleFailurehandleFailurehandleFailure");
  };

  const handleLogin = async (googleData) => {
    console.log(googleData.profileObj,"googleDatagoogleDatagoogleData")

    const reqData= {
      email:googleData.profileObj.email,
      password:googleData.profileObj.name,
  }
  let { error } = await googleSignin(reqData);
  console.log("error", error);
  if (isEmpty(error)) {
    // console.log("tassssssssssssssssssssssssssssssss")
    // toast.success("Added successfully", toasterOption);
    window.location="/my-profile-courses"
    // swal("Profile updated successfully");
   
  //   history("/admin/bannerindex");
  } else {
    // setValidateError(error);

  }

    // const res = await fetch('/api/google-login', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     token: googleData.tokenId,
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });

    // const data = await res.json();
    // setLoginData(data);
    // localStorage.setItem('loginData', JSON.stringify(data));
  };

  const handleLogout = () => {
    localStorage.removeItem('loginData');
    setLoginData(null);
  };

    const PopupExample = () => (
        <Popup trigger={<button>Trigger</button>} position="top left">
            {close => (
                <div>
                    Content here
                    <a className="close" onClick={close}>
                        &times;
                    </a>
                </div>
            )}
        </Popup>
    );

      // function
  const onChange = (e) => {
    e.preventDefault();
    // console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
    //setValidateError(formData)
  };
  const onChangelogin = (e) => {
    e.preventDefault();
    // console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...logformValue, ...{ [id]: value } };
    setlogFormValue(formData);
    console.log(formValue);
    //setValidateError(formData)
  };

    const register = async(e) =>{
        e.preventDefault();
        const reqData= {
            name,
            email,
            mobilenumber:phone,
            password,
            friendrefercode
        }
        let { error } = await createUser(reqData);
        console.log("error", error);
        if (isEmpty(error)) {
          // console.log("tassssssssssssssssssssssssssssssss")
          // toast.success("Added successfully", toasterOption);
          // window.location.reload()
         
             
              setForpopup(true)
              swal("Register successfully")
              setTimeout(() => {
              
               window.location.reload()
              },3000)
              
          //  swal("success")
        //    setTimeout(() => {
        //   window.location.reload();
        // },1000)
          // const willSearch = await swal({
          //   text: "Wanna log some information about Bulbasaur?",
          //   button: {
          //     text: "Search!",
          //     closeModal: false,
          //   },
          // });
          // setForpopup(false)
       
          
        //   history("/admin/bannerindex");
        } else {
          setValidateError(error);
        }
    }


    const login = async(e) =>{
        e.preventDefault();
        const reqData= {
            email:logemail,
            password:logpassword
        }
        let { error } = await userLogin(reqData);
        console.log("errorss", error);
        if (isEmpty(error)) {
          console.log("tassssssssssssssssssssssssssssssss")
          // toast.success("Added successfully", toasterOption);
          window.location="/my-profile-courses"
        //   history("/admin/bannerindex");
        } else {
          if(error.email == "You have logged in some other devices")
          {
            setActive(true)
          }
          setValidateError(error);
          

        }

        
    }


    const getUserListdata = async () => {
      var test = await sitesettings();
      console.log(test, "saamtwitt");
  
      setsitesetting(test.sitesettings);

      var test = await getdetails();
      console.log(test,"1234")
      let data = {};
   
      data["name"] = test.detail.name;
     
  
      
    
      // formdata["Photofile"] = test.userValue.image;
      setFormValue(data);
  
// if(probs.headerpopup==1){
//  alert("kkk") 
// }
    };

  const closenav = () => {
    const clearButtons = document.querySelectorAll(".dropdown");
  for (const clearButton of clearButtons) {

      clearButton.click();

    }
    // document.querySelectorAll(".navbar-toggler").click();
  }

    const userData =async()=>{

   const headers = await headermenusaddon();
   setCoursetypes(headers.typefilter)
   setExams(headers.examfilter)
  }
  const logoutfuns =async(reqData)=>{
    const test=await   confirm("Are you sure do you want to logout?");
    console.log(test,"sss")
       if(test){
   
       const headers = await logoutheader(reqData);
       history("/");
             swal("Logged out successfully");
       }
       window.location='/'
   
      }
  const logoutfun = async(e) =>{
    e.preventDefault();
    const reqData= {
        email:logemail,
        password:logpassword
    }
    let { error } = await logout(reqData);
    console.log("errorss", error);
    if (isEmpty(error)) {
      console.log("tassssssssssssssssssssssssssssssss")
      // toast.success("Added successfully", toasterOption);
      window.location="/"
    //   history("/admin/bannerindex");
    } else {
      if(error.email == "You have logged in some other devices")
      {
        setActive(true);
        
        
      }
      setValidateError(error);
      

    }

    
}

//    const login = async(e) =>{
//     e.preventDefault();
//     const reqData= {
//         email:logemail,
//         password:logpassword
//     }
//     let { error } = await userLogin(reqData);
//     console.log("errorss", error);
//     if (isEmpty(error)) {
//       console.log("tassssssssssssssssssssssssssssssss")
//       // toast.success("Added successfully", toasterOption);
//       window.location="/my-profile-courses"
//     //   history("/admin/bannerindex");
//     } else {
//       if(error.email == "You have logged in some other devices")
//       {
//         setActive(true)
//       }
//       setValidateError(error);
      

//     }

    
// }
  console.log(exams,"ccccccccccccccccccccccccccc");
  const initClient = () => {
    gapi.client.init({
    clientId: "423585970989-3aj6kbe9k3m7bk0t76of8h4hd9vqspt2.apps.googleusercontent.com",
    scope: ''
  });
};
const checkadmin = async() =>{
  const user = await checkuser();
  if("error" in user){
    localStorage.removeItem("user_token");
    // window.location.reload();
   
  
   
   
  }  
  console.log(user,"useruseruseruser")
  
 }

  useEffect(()=>{
    getUserListdata();
    checkadmin();

 gapi.load('client:auth2', initClient);

      userData();

      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
  },[])

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "none" : "block";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

const modalclose =() => {
 
}
    return (
            
        <div className='staticheader'>

            <div className='header1' style={{display:sticky}}>
                <div className='container'>
                <div className='row align-items-center'>
                        {/* <div className='col-md-3'>
                            <div className="hfirst">
                                <BsFillTelephoneFill />| 044-43 304 304
                            </div>
                        </div> */}
                        <div class="col-lg-6 col-md-6 col-12 align-items-center">
                            <Link to='#' className='top-cont'><BsFillTelephoneFill className='address'/> | {sitesetting.phone1}</Link>
                            <Link to='#' className='top-cont repeated'><GrMail className='address'/> | {sitesetting.mail1}</Link>
                        </div>

                        <div class="col-lg-6 col-md-6 col-12">
                            <ul class="social-icons">
                                <li><a href={sitesetting.facebook} target="_blank">< GrFacebook className='social-links' /></a></li>
                                <li><a href={sitesetting.twitter} target="_blank"><FaTwitter className='social-links' /></a></li>
                                <li> <a href="https://instagram.com/" target="_blank"><FaInstagram className='social-links' /></a></li>
                                <li><a href={sitesetting.youtube} target="_blank"><IoLogoYoutube className='social-links' /></a></li>
                                <li><a href={sitesetting.linkedin} target="_blank"><GrLinkedin className='social-links' /></a></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>



        <Navbar bg="light" expand="lg" className='navbar-fixed-top2 chip' style={{ alignItems:"center", margin:"0 auto" }}>
                <Container>
                <Navbar.Brand href="/" style={{ padding: "0px"}}> <img className="build tyty" src={config.API +"/images/user/"+sitesetting.image} /></Navbar.Brand>
                   
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav" className='n_headings'>
              <Nav className="me-auto hhh">
               
                <Link className="nav-link" to="/"><FaHome className='fahome' /></Link>
                <NavDropdown title="All Exams" id="basic-nav-dropdown">
                
                  
                            {exams.map((e)=>
                              <Link className="dropdown-item" to={"/exam/" + e._id} onClick={closenav}>{e.content}</Link>
                            )                              
                            }
                            </NavDropdown>

                            <NavDropdown title="Course" id="basic-nav-dropdown1">
                              {coursetypes.map(e =>
                                <Link className="dropdown-item" to={"/courses/" + e._id} onClick={closenav}>{e.content}</Link>
                              )}
                               
                            </NavDropdown>

                            <NavDropdown title="Free Resources" id="basic-nav-dropdown2">
                            {exams.map((e)=>
                                <Link className="dropdown-item" to={"/freeresource/"+e._id} onClick={closenav}>{e.content}</Link>
                            )                              
                            }
                               
                            </NavDropdown>

                            <NavDropdown title="Forum" id="basic-nav-dropdown3">
                                <Link className="dropdown-item" to="/feed" onClick={closenav}>Feeds</Link>
                                {/* <a className="dropdown-item" href="/feed">JEE MAIN</a>
                                <a className="dropdown-item" href="/feed">JEE Advance</a>
                                <a className="dropdown-item" href="/feed">CBSE</a>
                                <a className="dropdown-item" href="/feed">Foundation</a>
                                <a className="dropdown-item" href="/feed">TN Board</a> */}
                            </NavDropdown>

                            <Link className="nav-link" to="/freestuff">Demo</Link>

                            <a className="nav-link" href="https://marvelclasses.freshdesk.com/">Support</a>
              </Nav>
              
                       { localStorage.user_token ?               <div class="btn-group profdrops">
                <button type="button" class="btn btn-light btn-sm dropdown-toggle rounded profileshowimg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img className="pimg" src={require('../../img/leader.png')} />
                  <h5 className='logname'>{name}</h5>
                  {/* <IoMdArrowDropdown className='proficon'/> */}
                </button>
                <ul className="dropdown-menu allprofmenu">
                  <li><Link className="dropdown-item profitem" to="/my-profile-edit" type="button"> My Profile</Link></li>
                  <li><Link className="dropdown-item profitem" to="/my-profile-courses" type="button"> My Courses</Link></li>
                  {/* <a className="dropdown-item profitem" to="/my-profile-notes" type="button"> My Notes</a> */}
                  <li><Link className="dropdown-item profitem" to="/my-profile-wallet" type="button"> My Wallet</Link></li>
                  {/* <a className="dropdown-item profitem" href="/my-profile-myexamselection" type="button"> My Exam Selection</a> */}
                  <li><Link className="dropdown-item profitem" to="/my-profile-notification" type="button"> Notification </Link></li>
                  <li><a className="dropdown-item profitem"  type="button" onClick={logoutfuns}> Logout </a></li>
                </ul>
              </div> : 
              !forpopup ? 
              <Popup  trigger={<div className='getstartz'><button type="button" class="btn btn-primary startgets" style={{ marginTop:"6px" }}>Register / Login</button></div>} position="bottom center">
                            {close => (
                                <div>
                                    <div>
                                        <div className='col-md-5 col-sm-5' style={{ textAlign:"center" }}>
                                            <img className="welcome" src={require('../../img/dfff.jpg')} />
                                            <GoogleLogin
                                                clientId={"457575061627-hkvrmrkpsug8k87709a3g2i5fu4qibc9.apps.googleusercontent.com"}
                                                buttonText="Log in with Google"
                                                className='dtttt'
                                                onSuccess={handleLogin}
                                                onFailure={handleFailure}
                                                cookiePolicy={'single_host_origin'}
                                            ></GoogleLogin>
                                            {/* <Button variant="danger" style={{ marginTop:"10px" }}><BsGoogle/> Google</Button> */}
                                        </div> 
                                        <div className='col-md-6 col-sm-7' style={{display:regtab ? "none" : "block"}}>
                                            <div className='method'>
                                                <h3 className='reels'>Login</h3>
                                            <Form onSubmit={login}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" id='logemail' value={logemail}  onChange={onChangelogin} />
                                                    <Form.Text className="text-muted">
                                                        We'll never share your email with anyone else.
                                                    </Form.Text>
                                                    <ul className='logout_alldevices' >
                                                      <li>
                                                    {validateError.email && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.email}
                                                          </span>
                                                        )}
                                                        </li>
                                                        <li>
                                                          {active == true ? 
                                                        <Button variant="primary" onClick={logoutfun} type="submit">
                                                    Logout from all devices
                                                        </Button>:null}
                                                          
                                                        </li>
                                                        </ul>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" id='logpassword' value={logpassword}  onChange={onChangelogin}/>
                                                    {validateError.password && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.password}
                                                          </span>
                                                        )}
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                        {/* <Form.Check type="checkbox" label="Remember Me" /> */}
                                                        <a href='/forgetpassword'>
                                                        
                                                        <p className="forget fp"  style={{color:"white"}} >Forgot Password ?</p>
                                                        </a>
                                                    </Form.Group>
                                                    <div style={{ marginRight: "10px" }}>
                                                <Button variant="primary" type="submit" style={{ fontSize:"17px" }}>
                                                    Login
                                                        </Button>
                                                   
                                                        {/* <Button href="/my-profile-edit" variant="primary" type="submit" style={{ marginLeft:"10px" }}>
                                                            My Profile
                                                        </Button> */}
                                                    </div>
                                                    <span className='Register' onClick={()=>setregtab(!regtab)}><p className='account1'>Do You Have an account?</p> <p className='account2'> Register Now</p></span>
                                                </Form>
                                            </div>
                                        </div> 

                                        
                                        



                                        <div className='col-md-6 col-sm-7' style={{display:regtab ? "block" : "none"}}>
                                            <div className='method'>
                                                <h3 className='reels'> Register </h3>
                                                <Form onSubmit={register}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="name" id='name' value={name}  onChange={onChange} placeholder="Enter name" />
                                                        {validateError.name && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.name}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Email Address</Form.Label>
                                                        <Form.Control type="email" id='email' value={email}  onChange={onChange} placeholder="Enter email" />
                                                        {validateError.email && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.email}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Mobile Number</Form.Label>
                                                        <Form.Control  placeholder="Mobile number" id='phone'  onChange={onChange} value={phone} />
                                                        {validateError.phone && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.phone}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control type="password" placeholder="Password"  id='password'  onChange={onChange} value={password} />
                                                        {validateError.password && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.password}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Friend Refer Code(Optional)</Form.Label>
                                                        <Form.Control type="name" id='friendrefercode' value={friendrefercode}  onChange={onChange} placeholder="Enter refer code" />
                                                        {validateError.friendrefercode && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.friendrefercode}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <div style={{ textAlign: "center", marginRight: "10px" }}>    
                                                        <Button variant="primary" type="submit">
                                                            create account
                                                        </Button>

                                                    </div>
                                                    <span className='Register' onClick={()=>setregtab(!regtab)}><p className='account1'>Do You Have an account?</p> <p className='account2'> Login now </p></span>
                                                </Form>
                                            </div>
                                        </div> 

                        
{/* 
                        <div className='col-md-6 col-sm-7'>
                          <div className='method'>
                            <h3 className='reels'> Forgot Password </h3>

                            <Form>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" id='logemail' value={logemail} onChange={onChangelogin} />
                                {validateError.email && (
                                  <span style={{ color: "red", fontSize: "10px" }}>
                                    {validateError.email}
                                  </span>
                                )}
                              </Form.Group>

                              <div style={{ textAlign: "center", marginRight: "10px" }}>
                                <Button variant="primary" type="submit">
                                 Login Now
                                </Button>
                                <Button variant="primary" type="submit" style={{ marginLeft:"12px"}}>
                                 Next 
                                </Button>
                              </div>

                            </Form>
                           
                          </div>
                        </div>  */}

                        

                        {/* <div className='col-md-6 col-sm-7'>
                          <div className='method'>
                            <h3 className='reels'> Enter OTP </h3>

                            <Form>
                              <Form.Group className="mb-3" >
                                <Form.Label>Enter OTP</Form.Label>
                                <Form.Control placeholder="Enter OTP" id='phone' onChange={onChange} value={phone} />
                                {validateError.phone && (
                                  <span style={{ color: "red", fontSize: "10px" }}>
                                    {validateError.phone}
                                  </span>
                                )}
                              </Form.Group>

                              <div style={{ textAlign: "center", marginRight: "10px" }}>
                                <Button variant="primary" type="submit">
                                  Resend OTP
                                </Button>
                                <Button variant="primary" type="submit" style={{ marginLeft: "12px" }}>
                                  Next
                                </Button>
                              </div>

                            </Form>

                          </div>
                        </div>  */}

                        
                                 </div>
                                    <a className="close" onClick={close}>
                                        &times;
                                    </a>
                                </div>
                            )}
                        </Popup>:null
                          }







{probs.headerpopup==1?

<Popup open={!forpopup} trigger={<div className='getstartz'></div>} position="bottom center">
                            {close => (
                                <div>
                                    <div>
                                        <div className='col-md-5 col-sm-5' style={{ textAlign:"center" }}>
                                            <img className="welcome" src={require('../../img/dfff.jpg')} />
                                            <GoogleLogin
                                                clientId={"457575061627-hkvrmrkpsug8k87709a3g2i5fu4qibc9.apps.googleusercontent.com"}
                                                buttonText="Log in with Google"
                                                className='dtttt'
                                                onSuccess={handleLogin}
                                                onFailure={handleFailure}
                                                cookiePolicy={'single_host_origin'}
                                            ></GoogleLogin>
                                            {/* <Button variant="danger" style={{ marginTop:"10px" }}><BsGoogle/> Google</Button> */}
                                        </div> 
                                        <div className='col-md-6 col-sm-7' style={{display:regtab ? "none" : "block"}}>
                                            <div className='method'>
                                                <h3 className='reels'>Login</h3>
                                            <Form onSubmit={login}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" id='logemail' value={logemail}  onChange={onChangelogin} />
                                                    <Form.Text className="text-muted">
                                                        We'll never share your email with anyone else.
                                                    </Form.Text>
                                                    <ul className='logout_alldevices' >
                                                      <li>
                                                    {validateError.email && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.email}
                                                          </span>
                                                        )}
                                                        </li>
                                                        <li>
                                                          {active == true ? 
                                                        <Button variant="primary" onClick={logoutfun} type="submit">
                                                    Logout from all devices
                                                        </Button>:null}
                                                          
                                                        </li>
                                                        </ul>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" id='logpassword' value={logpassword}  onChange={onChangelogin}/>
                                                    {validateError.password && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.password}
                                                          </span>
                                                        )}
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                        {/* <Form.Check type="checkbox" label="Remember Me" /> */}
                                                        <a href='/forgetpassword'>
                                                        
                                                        <p className="forget fp"  style={{color:"white"}} >Forgot Password ?</p>
                                                        </a>
                                                    </Form.Group>
                                                    <div style={{ marginRight: "10px" }}>
                                                <Button variant="primary" type="submit" style={{ fontSize:"17px" }}>
                                                    Login
                                                        </Button>
                                                   
                                                        {/* <Button href="/my-profile-edit" variant="primary" type="submit" style={{ marginLeft:"10px" }}>
                                                            My Profile
                                                        </Button> */}
                                                    </div>
                                                    <span className='Register' onClick={()=>setregtab(!regtab)}><p className='account1'>Do You Have an account?</p> <p className='account2'> Register Now</p></span>
                                                </Form>
                                            </div>
                                        </div> 

                                        
                                        



                                        <div className='col-md-6 col-sm-7' style={{display:regtab ? "block" : "none"}}>
                                            <div className='method'>
                                                <h3 className='reels'> Register </h3>
                                                <Form onSubmit={register}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="name" id='name' value={name}  onChange={onChange} placeholder="Enter name" />
                                                        {validateError.name && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.name}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Email Address</Form.Label>
                                                        <Form.Control type="email" id='email' value={email}  onChange={onChange} placeholder="Enter email" />
                                                        {validateError.email && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.email}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Mobile Number</Form.Label>
                                                        <Form.Control  placeholder="Mobile number" id='phone'  onChange={onChange} value={phone} />
                                                        {validateError.mobilenumber && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.mobilenumber}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control type="password" placeholder="Password"  id='password'  onChange={onChange} value={password} />
                                                        {validateError.password && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.password}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Friend Refer Code</Form.Label>
                                                        <Form.Control type="name" id='friendrefercode' value={friendrefercode}  onChange={onChange} placeholder="Enter refer code" />
                                                        {validateError.friendrefercode && (
                                                          <span style={{color:"red",fontSize:"14px"}}>
                                                            {validateError.friendrefercode}
                                                          </span>
                                                        )}
                                                    </Form.Group>

                                                    <div style={{ textAlign: "center", marginRight: "10px" }}>    
                                                        <Button variant="primary" type="submit">
                                                            create account
                                                        </Button>

                                                    </div>
                                                    <span className='Register' onClick={()=>setregtab(!regtab)}><p className='account1'>Do You Have an account?</p> <p className='account2'> Login now </p></span>
                                                </Form>
                                            </div>
                                        </div> 

                        
{/* 
                        <div className='col-md-6 col-sm-7'>
                          <div className='method'>
                            <h3 className='reels'> Forgot Password </h3>

                            <Form>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" id='logemail' value={logemail} onChange={onChangelogin} />
                                {validateError.email && (
                                  <span style={{ color: "red", fontSize: "10px" }}>
                                    {validateError.email}
                                  </span>
                                )}
                              </Form.Group>

                              <div style={{ textAlign: "center", marginRight: "10px" }}>
                                <Button variant="primary" type="submit">
                                 Login Now
                                </Button>
                                <Button variant="primary" type="submit" style={{ marginLeft:"12px"}}>
                                 Next 
                                </Button>
                              </div>

                            </Form>
                           
                          </div>
                        </div>  */}

                        

                        {/* <div className='col-md-6 col-sm-7'>
                          <div className='method'>
                            <h3 className='reels'> Enter OTP </h3>

                            <Form>
                              <Form.Group className="mb-3" >
                                <Form.Label>Enter OTP</Form.Label>
                                <Form.Control placeholder="Enter OTP" id='phone' onChange={onChange} value={phone} />
                                {validateError.phone && (
                                  <span style={{ color: "red", fontSize: "10px" }}>
                                    {validateError.phone}
                                  </span>
                                )}
                              </Form.Group>

                              <div style={{ textAlign: "center", marginRight: "10px" }}>
                                <Button variant="primary" type="submit">
                                  Resend OTP
                                </Button>
                                <Button variant="primary" type="submit" style={{ marginLeft: "12px" }}>
                                  Next
                                </Button>
                              </div>

                            </Form>

                          </div>
                        </div>  */}

                        
                                 </div>
                                    <a className="close" onClick={close}>
                                        &times;
                                    </a>
                                </div>
                            )}
                        </Popup>:null}








                          
                          
                        {/* <button type="button" class="btn btn-primary" style={{ marginTop: "2px" }}>My Profile</button> */}
                    </Navbar.Collapse>
  

                </Container>
            </Navbar>


      

          
         
      </div>
      


      

        )

    }



import React from 'react';
import './freevideo.css'
import { Container, Row, Col, Card, Button, Tab, Tabs, Nav, NavItem } from 'react-bootstrap'

import Learningsubvideo from '../../Components/Learningsubvideo/learningsubvideo'
import Learningsubpdf from '../../Components/Learningsubpdf/learningsubpdf'

import Tabpara from '../../Components/Tabpara/tappara'

import { useNavigate, useParams, Link } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';



export default function Freevideo() {

    const { t_id } = useParams();
    const history = useNavigate();

    return (
         <>
            <div className='allbody'>

               
                
                <div>

                    <Container>
                        <div style={{ marginBottom: "10px" }}>
                            <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                        </div>
                  {/* <Container>
                        <Tabs defaultActiveKey={2} id="uncontrolled-tab-example">
                            <Tab eventKey={1} title="Video">
                                <Learningvideo/>
                            </Tab>
                            <Tab eventKey={2} title="PDF">
                                <Learningpdf />
                            </Tab>
                        </Tabs>
                    </Container> */}

                    <div className='tabing'>
                        <div className='container'>
                            <div id="exTab1" >
                                <ul class="nav nav-pills">
                                    <li class="active">
                                        <a href="#1a" data-toggle="tab">Video</a>
                                    </li>
                                    <li><a href="#2a" data-toggle="tab">PDF</a>
                                    </li>
                                   
                                </ul>

                                <div class="tab-content clearfix">
                                    <div class="tab-pane active" id="1a">
                                        <Learningsubvideo topic_id={t_id} />
                                    </div>
                                    <div class="tab-pane" id="2a">
                                        <Learningsubpdf topic_id={t_id}  />
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                        
                        </div>
                        
                    </Container>

                </div>
         
           </div>
        </>

        );
}
import React, { useState, useEffect } from "react";
import './teststart.css'
import { Container, Row, Col, Card, Button, Tab, Nav, Table } from 'react-bootstrap';
import { testtopic,createTest  } from "./../../actions/users";
import { totalquestion  } from "./../../actions/users";
import { testtime  } from "./../../actions/users";

import { useNavigate, useParams } from "react-router-dom";

import Form from 'react-bootstrap/Form'

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

export default function Teststart() {

    const [testtopics, settesttopic] = useState([]);
    const [totalquestions, settotalquestions] = useState([]);
    const [testtimes, settesttimes] = useState({});
    const [confirmation, setconfirmation] = useState("");
    const [confirmationmessage, setconfirmationmessage] = useState("");
    const { id } = useParams();
    const history = useNavigate();
    const getUserListdata = async () => {
        var test = await testtopic(id);
        console.log(test,"ghhgj")
        
        settesttopic(test.testtopic);

        var test1 = await totalquestion(id);
        console.log(test1,"sssss")
        
        settotalquestions(test1.totalquestions);

        var test2 = await testtime(id);
        console.log(test2,"sssss")
        
        settesttimes(test2.testtime);


       
        
    }

    const startTest = async() =>{
        if(Form&&confirmation){
       const data = await createTest(id);
        window.location = '/testclick/'+id+'/'+data.updatedid._id;}
        else{
            setconfirmationmessage("Please read the mark allocations and check the box")
        }
        
    }

    const conFirmation = async() =>{
        setconfirmation("Please read the mark allocations and check in the box");
        
    }
   

    useEffect(() => {
        
        // or simply just AOS.init();
       
        getUserListdata();
        

        // $(window).on('load', function (event) {
        //     $('.jQueryEqualHeight').jQueryEqualHeight();
        // });
    },[]);



    return (
           <>

            <div className='teststarts allbody'>
        
                <div className="test-header">
                    {/* <img className="build" src={require('../../img/download.png')} /> */}

                        <div className="header-text">
                            <h5 className="ng-binding">{testtimes.setname}</h5>
                        </div>
                </div>

                
     <div className='starttesting'>
                    <Container>
                        
                        <div style={{ marginBottom: "10px" }}>
                            <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                        </div>
                        
                        <div className='contentzz'>

                            <Table striped bordered className='sksk'>
                        <thead>
                            <tr>
                                <th>Total Questions</th>
                                    <th>Max Allowed Questions</th>
                                    <th>Total Time</th>
                                    <th>Total Marks</th>
                                    <th>Total Number Of Sections</th>
                                    <th>Negative mark</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                    <td>{totalquestions}</td>
                                <td>{totalquestions}</td>
                                <td>{testtimes.time} hour</td>
                                    <td>{totalquestions*testtimes.mark}</td>
                                    <td>{testtopics.length}</td>
                                    <td>{testtimes.negativemark}</td>
                            </tr>
            
                        </tbody>
                            </Table>
                            


                            <Table striped bordered className='sksk'>
                                <thead>
                                    <tr>
                                        <th>Section Name</th>
                                        <th>Total Questions</th>
                                        <th>Max Allowed Questions</th>
                                        {/* <th>Total Time (Minutes)</th> */}
                                        <th>Total Marks</th>
                                        <th>Marks per question</th>
                                        {/* <th>Negative marks per question</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                {testtopics.map(testtopic =>
                                    <tr>
                                    <td>{testtopic.content}</td>
                                    <td>{testtopic.qus}</td>
                                    <td>{testtopic.qus}</td>
                                    {/* <td>120minutes</td> */}
                                    <td>{testtopic.qus*testtimes.mark}</td>
                                    <td>{testtimes.mark}</td>
                                    {/* <td>1</td> */}
                                    </tr>)}

                                </tbody>
                            </Table>


                            <Form>
                                
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check  onClick={conFirmation} type="checkbox" label="I have read above test details and understood the marks alloted and negative marks by marvel classes test team." />
                                   
                                    <span style={{ color: "red", fontSize: "15px" }}> {confirmationmessage}</span>
                                </Form.Group>

                            </Form>

                
                            <div className='intest'>
                                {/* <div >  */}
                                    <Button  onClick={startTest}  variant="success" className='btest' >Start Test</Button>
                                    {/* </div> */}
                            </div>
                         


                </div>


                </Container>
</div>



            </div>
        </>

    );
}
import React from 'react';
import './transaction.css'
import { Container, Row, Col, Card, Button, Tab, Table } from 'react-bootstrap';

import { BsFillCheckCircleFill } from 'react-icons/bs';


import { useNavigate, useParams, Link } from "react-router-dom";

export default function Transaction() {



    return (

        <>
            
            <div className='t-transaction allbody'>
                <Container>
                
                    <div>
                       <div className='trans'>
                            <img src={require('../../img/tran.png')} alt="" />
                        </div>
                    <h1 className='t-success'>Transaction Successfull</h1>

                        <div className='t-tabless'>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>order_hdfgsds41432</td>
                                        <td>3999</td>
                                        <td>Success</td>
                                        <td>28.6.2022 10:00:50AM</td>
                                    </tr>
                                </tbody>
                            </Table>

                        </div>

                        <div className='goto'>
                            <Link to='/my-profile-courses'><Button variant="danger">Go To My course</Button>{' '}</Link>

                        </div>

                    </div>
                
                
                
                </Container> 



            </div>
            
        </>


    );
}
import React,{useState,useEffect} from 'react';
import './neet.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate, useParams,Link } from "react-router-dom";
import { freestufflistwithexam } from '../../actions/users';



import config from '../../lib/config';

export default function Freeresource() {
    const[courselist,setcourselist]=useState([]);
    const { id } = useParams();
    const history = useNavigate();
    const userData =async()=>{
        const apicourselist = await freestufflistwithexam(id);
        if("error" in apicourselist){
           alert("Internal server error");
        }else{
   
        setcourselist(apicourselist.userValue)
        }
   
       }

       useEffect(()=>{
           userData();
       },[id])
    return (
           <>

            <div className='allbody'>
         
                <div>
                    <Container> 
                        
                        {/* <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button> */}
                        {/* <h4>Free Resources / NEET</h4> */}
                        <Row>
                        { courselist.length ? courselist.map(e =>
                            <Col key={e._id} md={3}>
                                <Card className='filter-course'>
                                    <Card.Img className='coursefilter-img' variant="top" src={`${config.API}/images/user/${e.image}`} />
                                    <Card.Body className='freepad' style={{ padding:"8px 8px" }}>
                                        <Card.Title className='filter-title'>{e.title}</Card.Title>
                                        <Card.Text className='filter-para'>
                                            For NEET 2022-23 Aspirants
                                        </Card.Text>
                                        <div style={{ textAlign:"center" }}>
                                            <Link to={'/freelearning/'+e._id}><Button className='viewdetail' variant="primary">Start Learning</Button>{' '}</Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : <div style={{ margin:"auto", textAlign:"center" }}> <img  src={require('../../img/no-course.jpg')} /></div>}
                            

                        </Row>
                    </Container> 
                </div>


            </div>
        </>

    );
}
import React,{useState,useEffect} from 'react';
import './mywallet.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { userwallet } from '../../actions/users';
import Moment from 'react-moment';
import Sidebar from './sidbar';
import { myrefers,getdetails } from "../../actions/users";
export default function Mywallet() {
    const[wallet,setwallet]=useState({})
    const[transactions,settransactions]=useState([])
    const [refer, setrefer] = useState([]);
    const [refercode, setrefercode] = useState({});

    document.addEventListener(
        "click",
        function (event) {
            var target = event.target;
            var replyForm;
            if (target.matches("[data-toggle='reply-form']")) {
                replyForm = document.getElementById(target.getAttribute("data-target"));
                replyForm.classList.toggle("d-none");
            }
        },
        false
    );

    const userData =async()=>{
        const userd = await userwallet();
        if("error" in userd){
            alert("Internal server error");
         }else{
    
            setwallet(userd.userWallet)
            settransactions(userd.userTransaction)
         }
       }

       const getUserListdata = async () => {
       
        var test = await myrefers();
        console.log(test,"kss")
        setrefer(test.refers);


        var test = await getdetails();
        console.log(test,"kss")
        setrefercode(test.detail.referandearncode);
       
      };
    
   
       useEffect(()=>{
        getUserListdata();
           userData();
           window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
       },[])
    return (

        <>
            
            <div className='allbody'>
            <div className='judge'>

                <Container>
                    <Row>

                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>


                        <div className="col-md-8">
                       
                            <div className='wallet'>
                                
                                <div className="shadow card p-4">
                                   
                                <div className="my-balance">
                                {/* <h1 style={{ color:"#008db8" }}>Your refer and earn code is {refercode} <br></br> It is referred for below students</h1> */}
   <p style={{ color:"#008db8" }}>Your refer and earn code is {refercode}</p>

   <h1 style={{ color:"black",paddingTop:"50px" }}>Refer and earn code used by below students</h1>
                             
                                   
                             
        
                                        <div className=" card-body">
                                            <div>
                                            {refer.map(refer =>
                                                <ul>


                                                  
                                                        <li className="list-group-item content-center transaction__list">
                                                        <div className="transaction__list_cont">
                                                            <h5>{refer.name} </h5>
                                                         
                                                        </div><div className="follow_no"></div>
                                                    </li>
                                                    

                                                    
                                                    

                                                    {/* <li className="list-group-item content-center transaction__list">
                                                        <div className="transaction__list_cont">
                                                            <h5>COMMENT ADD </h5>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> 18 hours</p>
                                                            <p id="load_rward_button"></p>
                                                        </div><div className="follow_no">56</div>
                                                    </li>

                                                    <li className="list-group-item content-center transaction__list">
                                                        <div className="transaction__list_cont">
                                                            <h5>COMMENT ADD </h5>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> 18 hours</p>
                                                            <p id="load_rward_button"></p>
                                                        </div><div className="follow_no">-10</div>
                                                    </li> */}
                                                </ul>)}
                                              
                                            </div>
                                            </div>
                                        
                                         

                                </div>

                                {/* <div class="collapse" id="transaction">
                                    <div class=" card-body">
                                        <div class="notification__list" id="txn_data">











                                        </div>
                                    </div>
                                </div> */}

                            </div>





                           


                               
                            </div>
                        </div>





                    </Row>

                </Container>



            </div>
</div>
        </>


    );
}
// import package
import axios from "axios";

// import lib
import config from "../lib/config";


export const userLogin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/login`,
      data,
    });

    localStorage.setItem("user_token", respData.data.token);
    localStorage.setItem("name", respData.data.result.name);
    localStorage.setItem("email", respData.data.result.email);
    localStorage.setItem("lastlogin", respData.data.result.lastlogin);


    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const agentlogin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/agentlogin`,
      data,
    });

    localStorage.setItem("agent_token", respData.data.token);
    localStorage.setItem("name", respData.data.result.name);
    localStorage.setItem("email", respData.data.result.email);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const googleSignin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/googlesignin`,
      data,
    });

    localStorage.setItem("user_token", respData.data.token);
    localStorage.setItem("name", respData.data.result.name);
    localStorage.setItem("email", respData.data.result.email);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const logout =async (data) => {
  localStorage.removeItem("user_token");
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("lastlogin");


  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/logout`,
      data,
      // headers: {
      //   Authorization: localStorage.user_token,
      // },
     
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }

  

  

  

  
};

export const logoutheader =async () => {
  localStorage.removeItem("user_token");
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("lastlogin");


  

 

  

  

  
};


export const createUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/register`,
      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const userbyagent = async (data,id) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/useragentregister/`+id,
      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const agentregister = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/agentregister`,
      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const coursefilter = async (data) => {
  try {
    console.log(data,"dta")
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/coursefilter`,
      data: data,
    });
    console.log("Abishek",respData.data.userValue)
    return {
      loading: false,
      userValue:respData.data.userValue,
      subjectsfilter:respData.data.subjectsfilter,
      examfilter:respData.data.examfilter,
      eligiblityfilter:respData.data.eligiblityfilter,
      typefilter:respData.data.typefilter
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const headermenusaddon = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/headermenusaddon`,
    });
    return {
      loading: false,
      examfilter:respData.data.exams,
      typefilter:respData.data.types
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const zoomtoken = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/zoomtoken/`+data,
    });
    return {
      loading: false,
      values:respData.data.values,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const examsfromtypes = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/examsfromtypes/`+data,
    });
    return {
      loading: false,
      examfilter:respData.data.examfilter,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const typesfromexam = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/typesfromexam/`+data,
    });
    return {
      loading: false,
      typefilter:respData.data.typefilter,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const subjectlist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/subjectsfromeandt?type=${data.type}&exam=${data.exam}`,
    });
    return {
      loading: false,
      subjectsfilter:respData.data.subjectfilter,
      classesfilter:respData.data.classesfilter,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getcoursedata = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getcoursedata/`+data,
    });
    return {
     
      userValue:respData.data.userValue,
      videocount:respData.data.videocount,
      pdfcount:respData.data.pdfcount,
      testcount:respData.data.testcount,
      statement:respData.data.success,
      messages:respData.data.messages,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getdemovideo = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getdemovideo/`+data,
    });
    return {
      loading: false,
      userValue:respData.data.userValue,
      // demopdf:respData.data.demopdf,
      messages:respData.data.messages,
      statement:respData.data.success
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdemopdf = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getdemopdf/`+data,
    });
    return {
      loading: false,
      userValue:respData.data.userValue,
      // demopdf:respData.data.demopdf,
      messages:respData.data.messages,
      statement:respData.data.success
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const createorder = async (amount, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/createOrder`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: {
        amount: Number(amount),
        currency: "INR"
      }
    });
    return {
      loading: false,
      userValue: respData.data.values,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const verifyPayment = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/verifyPayment`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data
    });
    return {
      loading: false,
      userValue: respData.data.validSignature,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const purchasedcourse = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/purchasedcourse`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const freestufflist = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/freestufflist`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const freestufflistwithexam = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/freestufflist?exam=`+data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const userwallet = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/userwallet/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userTransaction:respData.data.userTransaction,
      userWallet:respData.data.userWallet,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const agentwallet = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/agentwallet/`,
      headers: {
        Authorization: localStorage.agent_token,
      },
    });
    return {
      loading: false,
      // userTransaction:respData.data.userTransaction,
      userWallet:respData.data.userWallet,
      user:respData.data.user,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const agenttransactions = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/agenttransactions/`,
      headers: {
        Authorization: localStorage.agent_token,
      },
    });
    return {
      loading: false,
      // userTransaction:respData.data.userTransaction,
      userValue:respData.data.userValue,
      agentlength:respData.data.agentlength,
     
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const walletcoin = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/walletcoin/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
     
      userValue:respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const studentpercentage1 = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/studentpercentage/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
     
      userValue:respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const wallet = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/userWalletdata/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userWallet:respData.data.userWallet,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const verifycourse = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/verifycourse/`+data,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      purchased:respData.data.purchased,

    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const verifycupons = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/verifycupons`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data
    });
    return {
      loading: false,
      userValue: respData.data.cuponsdata,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const topiclist = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/topiclist/`+data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


// satta
export const gettrust = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/gettrust/`,
    });
    return {
      loading: false,
      trust: respData.data.trust,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getmarvel = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getservicelist/`,
    });
    return {
      loading: false,
      marvel: respData.data.servicelist,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getoffer = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getoffer/`,
    });
    return {
      loading: false,
      offer: respData.data.offer,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addenquiry = async (data) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/addenquiry/`,
      data:data
    });
    return {
      loading: false,
      enquiry: respData.data.enquiry,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const forgotPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/forgotPassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/web/forgotPassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const homepage = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/gethomepage/`,
    });
    return {
      loading: false,
      banner: respData.data.banner,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const sitesettings = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/sitesettings/`,
    });
    return {
      loading: false,
      sitesettings: respData.data.sitesettings,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getrecent = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getrecent/`,
    });
    return {
      loading: false,
      recent: respData.data.recent,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getlogo = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getlogo/`,
    });
    return {
      loading: false,
      logo: respData.data.logo,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getservice = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getservice/`,
    });
    return {
      loading: false,
      service: respData.data.service,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getservicelist = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getservicelist/`,
    });
    return {
      loading: false,
      servicelist: respData.data.servicelist,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getstudy = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getstudy/`,
    });
    return {
      loading: false,
      study: respData.data.study,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getmission = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getmission/`,
    });
    return {
      loading: false,
      mission: respData.data.mission,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const gettestimonial = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/gettestimonialpage/`,
    });
    return {
      loading: false,
      testimonial: respData.data.testimonial,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfaq = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getfaq/`,
    });
    return {
      loading: false,
      faq: respData.data.faq,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfame = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getfame/`,
    });
    return {
      loading: false,
      fame: respData.data.fame,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
//sattaend



export const getvideo = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getvideo/`+data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getpdf = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getpdf/`+data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsubpdf = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getsubpdf/`+data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const about = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/aboutus/`,
    });
    return {
      loading: false,
      about: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const privacy = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/privacy/`,
    });
    return {
      loading: false,
      privacy: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};




//test
export const testset = async (id) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/testset/`+id,
    
    });
    return {
      loading: false,
      testset: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const freetestset = async (id) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/freetestset/`+id,
    
    });
    return {
      loading: false,
      testset: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const testsetname = async (id) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/testsetname/`+id,
    
    });
    return {
      loading: false,
      testsetname: respData.data.testsetname,
      
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

// export const testtopic = async (id) => {
//   console.log('yes')
//   try {
//     let respData = await axios({
//       method: "get",
//       url: `${config.API}/web/testtopic/`+id,
//     });
//     return {
//       loading: false,
//       testtopic: respData.data.userValue,
//       // noofquestions:respData.data.noofquestions
     
//     };
//   } catch (err) {
//     return {
//       loading: false,
//       error: err.response.data.errors,
//     };
//   }
// };

export const questioncount = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/questioncount/`,
    });
    return {
      loading: false,
      questioncount: respData.data.questioncount,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const testtopic = async (id) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/testtopic/`+id,
    });
    return {
      loading: false,
      testtopic: respData.data.userValue,
      // noofquestions:respData.data.noofquestions
     
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsetname = async (id) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getsetname/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
      
    });
    return {
      loading: false,
      setname: respData.data.userValue,
      time: respData.data.time,
      // noofquestions:respData.data.noofquestions
     
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const createTest = async (id) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/createtest/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      updatedid: respData.data.updatedid,
      // noofquestions:respData.data.noofquestions
     
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const totalquestion = async (id) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/totalquestions/`+id,
    });
    return {
      loading: false,
      totalquestions: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const testtime = async (id) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/testtime/`+id,
    });
    return {
      loading: false,
      testtime: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getquestion = async (id,topicid,testid) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getquestions/`+id +'/'+ topicid + '/' +testid,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      questions: respData.data.userValue,
      totalquestions: respData.data.totalquestions,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const testresult = async (id) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/testresult/`+id ,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      testresult: respData.data.testresult,
      noofquestions: respData.data.noofquestions,
      attempted: respData.data.attempted,
      previousscore: respData.data.previousscore,
      wronganswer: respData.data.wronganswer,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getscore = async (id) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getscore/`+id ,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      score: respData.data.score,
     
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const postanswer = async (data,id) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/postanswer/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
      data:data
    });
    return {
      loading: false,
      answer: respData.data.answer,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatefinalresult = async (data,id) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/updatefinalresult/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
      data:data
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateagentdetails = async (data) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/updateagentdetails/`,
      headers: {
        Authorization: localStorage.agent_token,
      },
      data:data
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const reviewanswers = async (data,id,test) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/reviewanswer/`+id+'/'+test,
      headers: {
        Authorization: localStorage.user_token,
      },
      data:data
    });
    return {
      loading: false,
      answer: respData.data.answer,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const courses = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/courses/`,
    });
    return {
      loading: false,
      course: respData.data.course,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const coursesp = async (id) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/coursesp/`+id,
    });
    return {
      loading: false,
      coursep: respData.data.coursep,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const ourexams = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/exams/`,
    });
    return {
      loading: false,
      exam: respData.data.exam,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const submitanswer = async (data,test) => {


  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/correctanswer/`+test,
      headers: {
        Authorization: localStorage.user_token,
      },
      data:data
      
     
    });
    console.log(respData,"ssss")
    return {
      loading: false,
      answers: respData.data.answered,
      notanswers: respData.data.notanswered,
      notvisited: respData.data.notvisited,
      reviewanswer: respData.data.reviewanswer,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const upcomingSchedules = async (id, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/upcomingschedules/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.user,
    };
  } catch (err) {
    return {  
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getnotes = async (id, dispatch) => {
  console.log("id",id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getnotes/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    console.log("hari",respData.data.user)
    return {
      loading: false,
    
      userValue: respData.data.user,
    };
  } catch (err) {
    return {  
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const previousanswersave = async (id, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/previousanswersave/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.user,
    };
  } catch (err) {
    return {  
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const checkattempt = async (id, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/checkattempt/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.checkattempt,
    };
  } catch (err) {
    return {  
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const currentquestionstatus = async ( topic,test) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/currentquestionstatus/${topic}/${test}`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      noofanswered: respData.data.answered,
      noofreview: respData.data.markedforreview,
      topicname: respData.data.topicname,
    };
  } catch (err) {
    return {  
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addteacher = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("Photofile", data.Photofile);
    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("specialization", data.specialization);
    bodyFormData.append("qualification", data.qualification);
   
    bodyFormData.append("phonenumber", data.phonenumber);
   
   
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addteacher`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const completedSchedules = async (id, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/completedschedules/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.user,
    };
  } catch (err) {
    return {  
      loading: false,
      error: err.response.data.errors,
    };
  }
};

// profile

export const getdetails = async () => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getdetail/`,
      headers: {
        Authorization: localStorage.user_token,
      },
     
    });
    return {
      loading: false,
      detail: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getagentdetails = async (id) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/agentwallet/`,
      headers: {
        Authorization: localStorage.agent_token,
      },
     
    });
    return {
      loading: false,
      detail: respData.data.user,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getclass = async () => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getclass/`,
      headers: {
        Authorization: localStorage.user_token,
      },
     
    });
    return {
      loading: false,
      mclass: respData.data.mclass,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsolution = async (id,topicid) => {
  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getsolution/`+id +'/'+ topicid + '/',
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      questions: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const additionaldetails = async (data) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/adddetails/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data:data
    });
    return {
      loading: false,
      study: respData.data.contact,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const addfiles = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("Photofile", data.Photofile);
    bodyFormData.append("Photofile2", data.Photofile2);
   
   
   
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/addfiles`,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      //   Authorization: localStorage.admin_token,
      // },
      headers:{
      Authorization: localStorage.user_token,},
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addimagefile = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("Photofile3", data.Photofile3);
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/addimagefile`,
      headers:{
        Authorization: localStorage.user_token,},
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

//notifications
export const getnotification = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/upcomingnotifications/`,
      headers:{
        Authorization: localStorage.user_token,},
    });
    
    return {
      loading: false,
      batchdetail: respData.data.batchdetail,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


//feed

export const getteacher = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/teacherlist/`,
      headers:{
        Authorization: localStorage.user_token,},
    });
    
    return {
      loading: false,
      teacherlist: respData.data.userValue,
      lastteacher:respData.data.lastteacher,
      lastbeforeteacher:respData.data.lastbeforeteacher
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcourse = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/courselist/`,
      headers:{
        Authorization: localStorage.user_token,},
    });
    
    return {
      loading: false,
      
      lastcourse:respData.data.lastcourse,
      lastbeforecourse:respData.data.lastbeforecourse
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfeed = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/feedlist/`,
      headers:{
        Authorization: localStorage.user_token,},
    });
    
    return {
      loading: false,
      
      feed:respData.data.userValue,
      
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcouponcode = async () => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/couponlist/`,
      headers:{
        Authorization: localStorage.user_token,},
    });
    
    return {
      loading: false,
      
      couponcode:respData.data.userValue,
      
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getexam = async () => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getexam/`,
      headers: {
        Authorization: localStorage.user_token,
      },
     
    });
    return {
      loading: false,
      exam: respData.data.exam,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const readanswer = async (id,test_id) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/readanswer/${id}/${test_id}`,
      headers: {
        Authorization: localStorage.user_token,
      },
     
    });
    return {
      loading: false,
      exam: respData.data.exam,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const postcomment = async (data) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/postcomment/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data:data
    });
    return {
      loading: false,
      study: respData.data.contact,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcomments = async (id) => {
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/commentlist/`+id,
      headers: {
        Authorization: localStorage.user_token,
      },
     
    });
    return {
      loading: false,
      comments: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const myrefers = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getmyreferred`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data
    });
    return {
      loading: false,
      refers: respData.data.refers,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const checkuser = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/checkuser/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const checkuser1 = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/checkuser1/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      success:respData.data.success,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const subcourse = async (id, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/subtopiclist/`+id,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const subundercourse = async (id, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/subundertopiclist/`+id,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsubvideo = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getsubvideo/`+data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


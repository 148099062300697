import React, { useEffect } from 'react';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded'
import { zoomtoken  } from '../../actions/users';
import { useLocation } from "react-router-dom";
import './zoom.css';
const client = ZoomMtgEmbedded.createClient()

export default function Testintro() {

  const location = useLocation();
  const { meetid,password } = location.state;
console.log(meetid,password);
useEffect(()=>{
    let meetingSDKElement = document.getElementById('meetingSDKElement')

client.init({
  debug: true,
  zoomAppRoot: meetingSDKElement,
  language: 'en-US',
  success: async(success) => {
    alert("fhgfgf")
    const token = await zoomtoken(meetid);
    console.log(success)

    client.join({
      sdkKey: "9kAmWaGxM9CGwn9widBvldnZ6wJgKH0Hiuni",
      signature: token.values, // role in SDK Signature needs to be 1
      meetingNumber: meetid,
      password: password,
      userName: localStorage.name,
      success: (success) => {
        alert("gafdsfdsfadf")
      },
      error: (error) => {
        console.log(error)
      }
    })

  },
  error: (error) => {
    console.log(error)
  }

});

handleJoinButton();

})

// const generatesdkjwt = async () =>{
//    const header=  {
//         "alg": "HS256",
//         "typ": "JWT"
//       }
//       const payload=  {
//         "appKey":  "9kAmWaGxM9CGwn9widBvldnZ6wJgKH0Hiuni",
//   "sdkKey":  "9kAmWaGxM9CGwn9widBvldnZ6wJgKH0Hiuni",
//   "mn": 86315501564,
//   "role": 0,
//   "iat": 1646937553,
//   "exp": 1646944753,
//   "tokenExp": 1646944753
//       }

//       return HMACSHA256(
//         base64UrlEncode(header) + '.' + base64UrlEncode(payload),
//         "gufCK1S55ywsEcYMbRtTq0tbeR4YGEiyMte0"
//       );
// }

const handleJoinButton =async() =>{
  const token = await zoomtoken(meetid);
  console.log(token.values,"toktktktkt")
    client.join({
        sdkKey: "9kAmWaGxM9CGwn9widBvldnZ6wJgKH0Hiuni",
        signature: token.values, // role in SDK Signature needs to be 1
        meetingNumber: meetid,
        password: password,
        userName: localStorage.name,
        success: (success) => {
          alert("gafdsfdsfadf")
        },
        error: (error) => {
          console.log(error)
        }
      })
}

    return (
        <>
            
            <div className='allbody sa_allbody' >
            
            <div id="meetingSDKElement">

            </div>
            <h4 style={{color:"red",fontSize:"15px"}}>*If you want to maximize zoom window drag the corners</h4>
            <h4 style={{color:"red",fontSize:"15px"}}>*To close the video window,please click on leave meeting</h4>
            <button
                className="join-meeting-button"
                onClick={handleJoinButton}
              >
                Join Meeting
              </button>
                
                </div>
        </>

    );
}
import React, { useState, useEffect } from "react";
import './aboutus.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { about  } from "./../../actions/users";
import config from "./../../lib/config";
import { useNavigate, useParams } from "react-router-dom";

export default function Aboutus() {

    const [abouts, setabouts] = useState([]);
    

    const getUserListdata = async () => {
        var test = await about();
        console.log(test,"cccc")
        
        setabouts(test.about);
       
        
    }
    
        useEffect(() => {
          //logout(history)
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          getUserListdata();
        }, []);



    return (
        <>

            <div className='phoneheader allbody'>


                <div className='learnbanner'>

                    <Container>

                        <div className='banner-title'>
                            <h2 className="aboutt">About Us</h2>

                        </div>

                    </Container>

                </div>


                <div className='aboutus'>
                    
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div >
                                    <img className='marvelimgess' src={config.API +"/images/user/"+abouts.image} />
                                    <h4><div dangerouslySetInnerHTML={{ __html: abouts.heading }} /></h4>
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div>
                                <div dangerouslySetInnerHTML={{ __html: abouts.content1 }} />
                                </div>

                            </Col>
                            
                        </Row>
                       
                    </Container>

                </div>
                


                <div className='aboutus1'>
                    <Container>

                        <Row>
                        
                            <Col lg={4}>
                                <div class="card">
                                    <div class="card-body">
                                        <h3 class="card-title">Why Choose us</h3>
                                        <p class="card-text"> <div dangerouslySetInnerHTML={{ __html: abouts.content2 }} /></p>
                                    </div>

                                </div>
                            </Col>

                            <Col lg={4}>
                                <div class="card">
                                    <div class="card-body">
                                        <h3 class="card-title">Our Mission</h3>
                                        <p class="card-text"> <div dangerouslySetInnerHTML={{ __html: abouts.content3 }} /></p>
                                    </div>

                                </div>
                            </Col>

                            <Col lg={4}>
                                <div class="card">
                                    <div class="card-body">
                                        <h3 class="card-title">Our visions</h3>
                                        <p class="card-text">  <div dangerouslySetInnerHTML={{ __html: abouts.content4 }} /></p>
                                    </div>

                                </div>
                            </Col>

                        </Row>


                    </Container>
                </div>

                

                <div className='aboutus2'>

                    <Container>

                        <Row>
                            <Col lg={6}>

                                <div className='mar-p'>
                                      <h3>Marvel Classes Program:</h3>
                                      <div dangerouslySetInnerHTML={{ __html: abouts.content5 }} />
                                </div>
                               
                            </Col>

                            
                            <Col lg={6}>                              
                                <div className='mar-p'>
                                        <h3>Marvel Classes Highlights:</h3>
                                        <div dangerouslySetInnerHTML={{ __html: abouts.content6 }} />
                                    </div>

                            </Col>

                        </Row>
                        
                </Container>

                 </div>









            </div>
        </>

    );
}
import React,{useState,useEffect} from 'react';
import './myprofile.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Sidebar from './sidbar'
export default function Myprofile() {

    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])
    return (
         <>

            <div className='judge allbody'>
                
                <Container>
                    <Row>
                       
                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>



                       
                       


                    </Row>

               </Container>



            </div>

            




        </>

          );
}
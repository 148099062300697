import React,{useState,useEffect} from 'react';
import { Container, Row, Col, Card, Button, Tab, Nav, SignUp } from 'react-bootstrap'
import './learningpdf.css'
import { FaRegFilePdf } from 'react-icons/fa';

import Viewer from '@phuocng/react-pdf-viewer';
import PDFViewer from 'pdf-viewer-reactjs';

import { getsubpdf } from '../../actions/users';
import { useNavigate, useParams,Link } from "react-router-dom";
import config from '../../lib/config'
export default function Learningpdf({topic_id}) {
    const[selectpdf,setselectpdf]=useState(0);
    const[pdfsdata,setpdfsdata]=useState([]);
    const userData =async()=>{

        const coursetopic = await getsubpdf(topic_id);
        console.log(coursetopic,"bbb")
        if("error" in coursetopic){
           alert("Internal server error");
        }else{
            setpdfsdata(coursetopic.userValue)

        }

      }
      console.log(selectpdf)
    useEffect(()=>{
        userData();
    },[])
    return (

           <>

            <div>

             <div>
              
                    <Container>
               
                        <Row>
                        {selectpdf?<Col lg={12} md={6}>
                        <PDFViewer
                           document={{
                             url: `${config.API}/images/user/${selectpdf}`,
                           }}
                         />
                         </Col> : null}
                            {pdfsdata.length ? pdfsdata.map(e => <Col lg={3} md={6}>
                                <Card className='filter-course'>
                                    <div className='pdfround'>
                                        <FaRegFilePdf className='pdfimg' />
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='filter-title'>{e.title}</Card.Title>
                                        {/* <Card.Text className='filter-para'>
                                           <p>NEET 2023 | Electrochemistry | Lecture 1 | Physical Chemistry </p>
                                        </Card.Text> */}
                                        <div style={{ textAlign: "center" }}>
                                            <a className="btn m-auto download-btn anotherfile" onClick={() => setselectpdf(e.file)} >View</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>) : <img style={{ margin: "auto" , borderRadius:"6px" }} src={require("../../img/nopdf.png")} />
                                        }
                                {/* <h1>No Pdfs Available</h1>
                            } */}


                            
                        </Row>
                    </Container>
                    
             </div>




                

            </div>
           </>

    );
}
import React from 'react';
import './unattempt.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';



export default function Unattempt({questions=[],unaquscount}) {

    let quscount=0;
    const showanswer =(element) =>{
        if(element.answers.length){
            if(element.answers[0].evaluation==0){
                // setquscount(quscount+1);
                quscount=quscount+1;
                unaquscount(quscount);
       return ( <div className='qanda'>
                    <div className='beans'>
                    <div>
                   <h5><span className='q1s'>Q {element.questionnumber} : </span> <span className='qno'>{element.question}</span> </h5>
                        {/* <p>{element.question}</p> */}
                    </div>
                    <div>
                    <h5></h5>
                   <p className="jerk"> <span className='q1s'>Ans :</span> {element.solutions}</p>
                    </div>

                    <div className='right'>
                        <ul>
                            <li>Not Answered</li>
                            <li style={{ display:"flex" }}> <p style={{ color:"red", fontWeight:"500", marginRight:"4px" }}> UNATTEMPT</p> || Question Type: Single choice </li>
                        </ul>
                        </div>
                        </div>
                </div>)
            }
        }else{
            quscount=quscount+1;
                unaquscount(quscount);
            return ( <div className='qanda'>
            <div className='beans'>
            <div>
            <h5>Q.{element.questionnumber}</h5>
                <p className="que_bold">{element.question}</p>
            </div>

            <div className='right'>
                <ul>
                    <li>Not Answered</li>
                    <li> UNATTEMPT  ||  Question Type: Single choice </li>
                </ul>
                </div>
                </div>
        </div>)
        }
    }
    let correctqus = ()=>{
        return questions.map(element=>showanswer(element))
    }

    
    return (

        <>


            <div className='allunattempts'>
            {questions.length ? questions.map(element=>showanswer(element)):<h1>No questions in Wrong answer</h1>}

{/* 
                <div className='qanda'>
                    <div className='beans'>
                        <div>
                            <h5>Q.17</h5>
                            <p>A dipole is placed in an electric field as shown. In which direction will it move?</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer A</li>
                                <li> UNATTEMPT|| On Screen: 00:03|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='qanda'>
                    <div className='beans'>
                        <div>
                            <h5>Q.24</h5>
                            <p>A body is executing simple harmonic motion with frequency ' n ', the frequency of its potential energy is</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer c</li>
                                <li> UNATTEMPT|| On Screen: 00:08|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className='qanda'>

                    <div className='beans'>
                        <div>
                            <h5>Q.39</h5>
                            <p>A particle is released from height S from the surface of the Earth. At a certain height its kinetic energy is three times its potential energy. The height from the surface of earth and the speed of the particle at that instant are respectively</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer b</li>
                                <li> UNATTEMPT|| On Screen: 00:02|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>

                </div>



                <div className='qanda'>
                    <div className='beans'>
                        <div>
                            <h5>Q.13</h5>
                            <p>A body is executing simple harmonic motion with frequency ' n ', the frequency of its potential energy is</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer c</li>
                                <li> UNATTEMPT|| On Screen: 00:08|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className='qanda'>

                    <div className='beans'>
                        <div>
                            <h5>Q.40</h5>
                            <p>A particle is released from height S from the surface of the Earth. At a certain height its kinetic energy is three times its potential energy. The height from the surface of earth and the speed of the particle at that instant are respectively</p>
                        </div>

                        <div className='right'>
                            <ul>
                                <li>Your Answer b</li>
                                <li> UNATTEMPT|| On Screen: 00:02|| Question Type: Single choice </li>
                            </ul>
                        </div>
                    </div>

                </div> */}






            </div>








        </>

    );


}
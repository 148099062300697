let key = {};
if (process.env.NODE_ENV === "production") {
  console.log("Set Production Config");
  const API_URL = "http://64.227.27.26:3001";
  key = {
    secretOrKey: "",
    Recaptchakey: "", //local

    API: `${API_URL}`,
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://64.227.27.26:3001";
  key = {
    secretOrKey: "",
    Recaptchakey: "", //local

    API: `${API_URL}`,
  };
}

export default key;

import React,{useState,useEffect} from 'react';
import './freelearning.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { getcoursedata,topiclist,subcourse } from '../../actions/users';
import { useNavigate, useParams, Link } from "react-router-dom";

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

export default function Coursetopic() {
    const[topics,settopics]=useState([]) 
    const[subtopics,setsubtopics]=useState([])    
    const[coursedetail,setcoursedetail]=useState({})
    const history = useNavigate();
    const { ic_id } = useParams();

    const userData =async()=>{
        const cousrsed = await getcoursedata(ic_id);
        if("error" in cousrsed){
           alert("Internal server error");
        }else{
   
           setcoursedetail(cousrsed.userValue)

        }
        const coursetopic = await topiclist(ic_id);
        if("error" in coursetopic){
           alert("Internal server error");
        }else{
   
           settopics(coursetopic.userValue)

        }

        const subcoursetopic = await subcourse(ic_id);
        console.log(subcoursetopic,"vvv")
        if("error" in subcoursetopic){
           alert("Internal server error");
        }else{
   
           setsubtopics(subcoursetopic.userValue)

        }

      }
    useEffect(()=>{
        userData();
    },[])

    return (
           <>
        
            <div className='allbody'>
            

                <div className='learnbanner'>

                    <Container>
                       
                        <div className='banner-title'>
                            <h2 style={{ fontSize:"30px", fontWeight:"600" }}>{coursedetail&&coursedetail.title}</h2>
                            <h4 className='sub-para'>{coursedetail&&coursedetail.title}</h4>
                        </div>
                    
                    </Container>

               </div>     


                <div className='subject'>
                    <Container>

                        <div style={{ marginBottom: "10px" }}>
                            <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                        </div>

                        <Row>
                            {/* {topics.length ? topics.map((e,i) =>(
                                <Col md={6} key={e._id} lg={6} sm={6} className="mb4">
                           
                           <div className="shadow bg-white p-3">
                               <Link className="atag" to={"/coursecontent/"+e._id}>
                                   <div className="heading-discription d-flex align-items-center">
                                       <span className="footerSocialLink1">{i+1}</span>
                                       <h4 className="m-0">{e.content}</h4>
                                   </div>
                               </Link>
                           </div>
                       </Col>
                            )) : <h1>No Topics Available for this course</h1>
                            } */}


{subtopics.length ? subtopics.map((e,i) =>(
                                <Col md={6} key={e._id} lg={6} sm={6} className="mb4">
                           
                           <div className="shadow bg-white p-3">
                               <Link className="atag" to={"/freesublearning/"+e._id}>
                                   <div className="heading-discription d-flex align-items-center">
                                       <span className="footerSocialLink1">{i+1}</span>
                                       <h4 className="m-0">{e.content}</h4>
                                   </div>
                               </Link>
                           </div>
                       </Col>
                            )) : <h1>No Topics Available for this course</h1>
                            }


                        </Row>
                    </Container>
              </div>
     




            </div>
        </>

         );
}
import React from 'react';
import './teacherfollowers.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'

export default function Teacherfollowers() {



    return (

        <>
        
            <div className='allfollow'>

                <div class="followerList border p-3">

                    <div class="notification__list card ooo">
                        <ul class="list-group ">
                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Rohit Sharma</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Followers</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Sachin</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Followers</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Hardik Pandya</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Followers</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Rohit Sharma</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Followers</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Sachin</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Followers</button>
                                </span>

                            </li>

                            <li class="list-group-item content-center">
                                <a href="#" class="notification-event content-center">
                                    <div class="author-thumb">
                                        <img src={require('../../img/deal.jpg')} alt="" />
                                    </div>
                                    <div class="notification-event-content">
                                        <h5> <span class="hnotification-friend">Hardik Pandya</span>
                                        </h5>
                                    </div>
                                </a>
                                <span id="followunfollow_1093">
                                    <button class="btn btn-primary unfollow_id_user" >Followers</button>
                                </span>

                            </li>


                        </ul>

                        </div>

                    </div>
                
            </div>

        
        </>


      )
};

import React, { Component, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import './carousel.css'
import config from "../../lib/config";
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

export default function Itscarousel({banners=[]}) {
  console.log(banners,"bannersbannersbannersbannersbanners")
    return (
        <div>

           

<Carousel>
         {banners.map(e=> <Carousel.Item>
            <img className="d-block w-100 beauty" src={config.API +"/images/user/"+e.image} />
  </Carousel.Item>)}

</Carousel>
            

        </div>
    );
}

import React, { useState, useEffect } from "react";
import './testclick.css'
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

import Teststart from './../Testonline/testonline';

export default function Testclick() {

    const history = useNavigate();

    const { id,test_id } = useParams();

    const [isOpen, setOpen] = useState(false);

    const openWindow = () => {
      setOpen(!isOpen);
    };

    return (

           <>

            <div className='launch allbody'>

           

        {isOpen && <Teststart id={id} test_id={test_id} />}
                <div className="sa_loader">
                    <div className="loader-content">
                    <div style={{ marginBottom: "10px", textAlign:"left", marginLeft:"10px" }}>
                           <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>
                        </div>
                        <p>To attempt the test click below</p>
                        {/* <input  type="button" class="toggleFullScreen" value="Click Me for start test" /> */}
                        <Button variant="primary" className='forme' onClick={openWindow} >Click me for test</Button>{' '}
                                </div>
                    <div className="loader-overlay"></div>
                            </div>
            </div>


        </>

    );
}
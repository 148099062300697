import React,{useState,useEffect} from 'react';
import './mywallet.css';
import { Container, Row, Col, Card, Button, Tab, Nav, NavItem } from 'react-bootstrap'
import { userwallet,walletcoin,studentpercentage1,checkuser } from '../../actions/users';
import { NavLink,useNavigate,useLocation,NavNavLink } from "react-router-dom";
import Moment from 'react-moment';
import Sidebar from './sidbar';
export default function Mywallet() {
    const[wallet,setwallet]=useState({})
    const[walletvalue,setwalletvalue]=useState(0)
    const[studentpercentage,setstudentpercentage]=useState(0)
    const[transactions,settransactions]=useState([])
    const history = useNavigate();


    document.addEventListener(
        "click",
        function (event) {
            var target = event.target;
            var replyForm;
            if (target.matches("[data-toggle='reply-form']")) {
                replyForm = document.getElementById(target.getAttribute("data-target"));
                replyForm.classList.toggle("d-none");
            }
        },
        false
    );

    const userData =async()=>{
        const userd = await userwallet();
        if("error" in userd){
            history("/");
         }else{
    
            setwallet(userd.userWallet)
            settransactions(userd.userTransaction)
         }

         const walletcoinvalue = await walletcoin();
         console.log(walletcoinvalue,"sms")
        
     
             setwalletvalue(walletcoinvalue.userValue)

             const studentpercentage = await studentpercentage1();
             console.log(walletcoinvalue,"vvv")
            
         
                 setstudentpercentage(studentpercentage.userValue)
            
          
        
       }

       function datealignment(startdate){
   
        var now = new Date(startdate);
    
    var startdate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear() + " " ;
    
       
          
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return startdate;
    }

    const checkadmin = async() =>{
        const user = await checkuser();
        if("error" in user){
          localStorage.removeItem("user_token");
         
          // window.location.reload();
         
        
          history("/agentdashboard");
         
        }  
        console.log(user,"useruseruseruser")
        
       }


       

       
   
       useEffect(()=>{
        checkadmin();
           userData();
           window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
       },[])
    return (

        <>
            
            <div className='allbody'>
            <div className='judge'>

                <Container>
                    <Row>

                        <div className="col-lg-4 col-md-4 col-sm-12 col_profile__sidebar">
                        <Sidebar/>
                        </div>


                        <div className="col-md-8">
                            <div className='wallet'>
                                <div className="shadow card p-4">
                                    <div className="my-balance">
                                    <h3>My Balance</h3>
                                    <h5 className='basicinfo'>Your wallet amount will be increased in {studentpercentage}% for every purchase of your course price,you may redeem your wallet balance on future course purchase</h5>
                                    {/* <p>Total Earning<span><i class="fa fa-rupee-sign"></i><span id="total_bal">{wallet.totalearnings}</span></span>
                                    </p> */}
                                    <p>Total Wallet Balance<span><i class="fa fa-rupee-sign"></i><span id="total_ern">{wallet.balance}</span></span>
                                    </p>
                                </div>
                                <div className="my-balance">
                                    <h3>My Coins</h3>
                                    <p>My Coin Balance<span><span id="coin_bal">{wallet.walletcoins}</span></span>
                                    </p>
                                    {/* <p>Total Earning Coins<span><span id="reward_pts">{wallet.totalcoinsearning}</span></span>
                                    </p> */}
                                    <div className="col-md-12 text-right">
                                    {transactions.length?     <button className="btn  float-right" data-toggle="reply-form" data-target="comment-24-reply-form">View Transaction</button>:null}
                                          
                                    </div>

                                    <form method="POST" class="comment-form inline-items reply-form d-none" id="comment-24-reply-form">
                                        <div className=" card-body">
                                            <div>
                                                <ul>


                                                    {transactions.map(trans =>(
                                                        <li className="list-group-item content-center transaction__list" style={{ width:"100%" }}>
                                                        <div className="transaction__list_cont">
                                                            <h5>{trans.comments} </h5>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> {datealignment(trans.createdAt)}</p>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> <Moment toNow>{trans.createdAt}</Moment></p>
                                                            <p id="load_rward_button"></p>
                                                        </div><div className="follow_no">{trans.points}</div>
                                                    </li>
                                                    )

                                                    )}
                                                    

                                                    {/* <li className="list-group-item content-center transaction__list">
                                                        <div className="transaction__list_cont">
                                                            <h5>COMMENT ADD </h5>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> 18 hours</p>
                                                            <p id="load_rward_button"></p>
                                                        </div><div className="follow_no">56</div>
                                                    </li>

                                                    <li className="list-group-item content-center transaction__list">
                                                        <div className="transaction__list_cont">
                                                            <h5>COMMENT ADD </h5>
                                                            <p><i className="far fa-clock" aria-hidden="true"></i> 18 hours</p>
                                                            <p id="load_rward_button"></p>
                                                        </div><div className="follow_no">-10</div>
                                                    </li> */}
                                                </ul>
                                              
                                            </div>
                                            </div>
                                        
                                         </form>

                                </div>

                                {/* <div class="collapse" id="transaction">
                                    <div class=" card-body">
                                        <div class="notification__list" id="txn_data">











                                        </div>
                                    </div>
                                </div> */}

                            </div>





                            <div class="calculation card mt-4">
                                <div class="calculation-method row text-center p-4">
                                    <div class="col-6">
                                        <p class="conversion">Conversion Calculation</p>
                                        <p class=" mb-0" id="conversion_calc">{walletvalue} Coin = <i class="fa fa-rupee-sign"></i> 1 </p>
                                    </div>
                                    <div class="col-6">
                                        <p class="conversion">Conversion Eligibility</p>
                                        <p class=" mb-0" id="conversion_eleg">On Earning {walletvalue} Coins</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>





                    </Row>

                </Container>



            </div>
</div>
        </>


    );
}